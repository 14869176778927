import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2C2.44772 2 2 2.44772 2 3V21C2 21.5523 2.44772 22 3 22H21C21.5523 22 22 21.5523 22 21V3C22 2.44772 21.5523 2 21 2H3ZM18.495 7.50501C18.5621 7.57213 18.6127 7.64948 18.6469 7.73204C18.6814 7.8156 18.6991 7.9045 18.7 7.99353L18.7 8.00081V11C18.7 11.3866 18.3866 11.7 18 11.7C17.6134 11.7 17.3 11.3866 17.3 11V9.68994L12.495 14.495L12 14.9899L11.505 14.495L8.99999 11.9899L5.49496 15.495C5.2216 15.7683 4.77838 15.7683 4.50501 15.495C4.23165 15.2216 4.23165 14.7784 4.50501 14.505L8.50501 10.505L8.99999 10.01L9.49496 10.505L12 13.01L16.31 8.69999H15C14.6134 8.69999 14.3 8.38659 14.3 7.99999C14.3 7.61339 14.6134 7.29999 15 7.29999H17.9995H18L18.0021 7.29999C18.1805 7.30052 18.3588 7.36886 18.495 7.50501Z"
        fill={color}
        className="fill"
      />
    </svg>
  );
};

export default Svg;
