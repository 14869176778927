import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.GRAY_02 }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0012 20.0088L20.5674 17.9092C21.0535 17.5115 21.0569 16.7692 20.5743 16.3671L17.9636 14.1914C17.5662 13.8603 16.982 13.8868 16.6163 14.2526L15.0012 15.8676C14.8616 16.0072 14.684 16.1081 14.4871 16.1219C12.8226 16.2385 11.2116 14.6275 10.5445 13.9604L8.06963 16.4352C11.9501 20.3157 15.239 20.647 17.1248 20.4047C17.4494 20.3631 17.748 20.216 18.0012 20.0088Z"
        fill={color}
      />
      <path
        d="M4.49607 6.50366L6.59564 3.93752C6.99342 3.45135 7.73568 3.44801 8.13782 3.93058L10.3134 6.54131C10.6445 6.93864 10.618 7.52288 10.2523 7.8886L8.63728 9.50364C8.49768 9.64324 8.39675 9.82084 8.38296 10.0178C8.26639 11.6823 9.87743 13.2933 10.5445 13.9604L8.06963 16.4352C4.18919 12.5548 3.85791 9.26589 4.10015 7.38005C4.14183 7.05553 4.28889 6.75689 4.49607 6.50366Z"
        fill={color}
      />
    </svg>
  );
};

export default Svg;
