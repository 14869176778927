import ApiService from '@/services/api';

// TYPES

export type TDeleteStaffPaths = {
  id: string | number;
};
export type TDeleteStaffParams = unknown;

export type TDeleteStaffMaterials = {
  paths?: TDeleteStaffPaths;
  params?: TDeleteStaffParams;
};

export type TDeleteStaffResponse = unknown;

// FUNCTION

export const deleteStaff = async ({ paths, params }: TDeleteStaffMaterials): Promise<TDeleteStaffResponse> => {
  const response = await ApiService.delete(`/staffs/${paths?.id}`, { params });
  return response.data;
};
