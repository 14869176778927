import { createActionCreator } from 'deox';

import { TGetProceduresMaterials, TGetProceduresResponse } from '@/services/api/procedure/get-procedures';

// CONSTANTS

export enum EGetProceduresAction {
  GET_PROCEDURES = 'GET_PROCEDURES',
  GET_PROCEDURES_REQUEST = 'GET_PROCEDURES_REQUEST',
  GET_PROCEDURES_SUCCESS = 'GET_PROCEDURES_SUCCESS',
  GET_PROCEDURES_FAILED = 'GET_PROCEDURES_FAILED',
}

// TYPES

export type TGetProceduresRequest = {
  type: EGetProceduresAction.GET_PROCEDURES_REQUEST;
  payload: {
    materials: TGetProceduresMaterials;
    successCallback?: (response: TGetProceduresResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetProceduresSuccess = {
  type: EGetProceduresAction.GET_PROCEDURES_SUCCESS;
  payload: { response: TGetProceduresResponse };
};

export type TGetProceduresFailed = { type: EGetProceduresAction.GET_PROCEDURES_FAILED };

// FUNCTION

export const getProceduresAction = {
  request: createActionCreator(
    EGetProceduresAction.GET_PROCEDURES_REQUEST,
    (resolve) =>
      (
        materials: TGetProceduresMaterials,
        successCallback?: (response: TGetProceduresResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetProceduresRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetProceduresAction.GET_PROCEDURES_SUCCESS,
    (resolve) =>
      (response: TGetProceduresResponse): TGetProceduresSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetProceduresAction.GET_PROCEDURES_FAILED,
    (resolve) =>
      (error: unknown): TGetProceduresFailed =>
        resolve({ error }),
  ),
};
