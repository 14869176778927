import { TStatisticState } from '@/redux/reducers/statistic';
import { TGetStatisticsCustomerSuccess } from '@/redux/actions/statistic';

export const getStatisticsCustomerUpdateState = (
  state: TStatisticState,
  action: TGetStatisticsCustomerSuccess,
): TStatisticState => ({
  ...state,
  getStatisticsCustomerResponse: action.payload.response,
});
