import ApiService from '@/services/api';

// TYPES

export type TUpdateCustomerPaths = {
  id: string | number;
};
export type TUpdateCustomerBody = FormData;

export type TUpdateCustomerMaterials = {
  paths?: TUpdateCustomerPaths;
  body?: TUpdateCustomerBody;
};

export type TUpdateCustomerResponse = unknown;

// FUNCTION

export const updateCustomer = async ({ paths, body }: TUpdateCustomerMaterials): Promise<TUpdateCustomerResponse> => {
  const response = await ApiService.patch(`/admin/customers/${paths?.id}`, body);
  return response.data;
};
