import { TStatisticState } from '@/redux/reducers/statistic';
import { TGetStatisticsBranchSuccess } from '@/redux/actions/statistic';

export const getStatisticsBranchUpdateState = (
  state: TStatisticState,
  action: TGetStatisticsBranchSuccess,
): TStatisticState => ({
  ...state,
  getStatisticsBranchResponse: action.payload.response,
});
