import { TScheduleState } from '@/redux/reducers/schedule';
import { TCreateSchedulePersonalSuccess } from '@/redux/actions/schedule';

export const createSchedulePersonalUpdateState = (
  state: TScheduleState,
  action: TCreateSchedulePersonalSuccess,
): TScheduleState => ({
  ...state,
  createSchedulePersonalResponse: action.payload.response,
});
