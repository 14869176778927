import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.WHITE }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="12" fill={color} />
      <path d="M16 11L16 21" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M21 16H11" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default Svg;
