import { TInquiry } from '@/common/models';
import { TPaginationResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetInquiriesParams = {
  page: number;
  pageSize: number;
  search?: string;
};

export type TGetInquiriesMaterials = {
  params?: TGetInquiriesParams;
};

export type TGetInquiriesResponse = {
  pagination: TPaginationResponse;
  data: TInquiry[];
};

// FUNCTION

export const getInquiries = async ({ params }: TGetInquiriesMaterials): Promise<TGetInquiriesResponse> => {
  const response = await ApiService.get(`/admin/inquiries`, { params });
  return response.data;
};
