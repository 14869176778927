import { TBookingToday } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetBookingScheduleTodayPaths = {
  branchid: string | number;
};
export type TGetBookingScheduleTodayParams = {
  timeToday: string;
  getNextDays?: number;
};

export type TGetBookingScheduleTodayMaterials = {
  paths?: TGetBookingScheduleTodayPaths;
  params?: TGetBookingScheduleTodayParams;
};

export type TGetBookingScheduleTodayResponse = { [key: string]: { [key: string]: TBookingToday } };

// FUNCTION

export const getBookingScheduleToday = async ({
  paths,
  params,
}: TGetBookingScheduleTodayMaterials): Promise<TGetBookingScheduleTodayResponse> => {
  const response = await ApiService.get(`/admin/managements/booking-schedule-today/${paths?.branchid}`, { params });
  return response.data;
};
