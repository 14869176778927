import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateSaleAction } from '@/redux/actions';
import { updateSale, TUpdateSaleResponse } from '@/services/api';

// FUNCTION

export function* updateSaleSaga(action: ActionType<typeof updateSaleAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateSale, materials);
    const updateSaleResponse: TUpdateSaleResponse = response as TUpdateSaleResponse;
    yield put(updateSaleAction.success(updateSaleResponse));
    successCallback?.(updateSaleResponse);
  } catch (err) {
    yield put(updateSaleAction.failure(err));
    failedCallback?.(err);
  }
}
