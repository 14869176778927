import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="24" r="2" fill={color} />
      <circle cx="24" cy="24" r="2" fill={color} />
      <circle cx="36" cy="24" r="2" fill={color} />
    </svg>
  );
};

export default Svg;
