import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3C4 2.44772 4.44772 2 5 2H19C19.5523 2 20 2.44772 20 3V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V3ZM6 4H18V8H6V4ZM8 12H6V14H8V12ZM6 17H8V19H6V17ZM12 12H10V14H12V12ZM14 12H18V19H14V12ZM12 17H10V19H12V17Z"
        fill={color}
        className="fill"
      />
    </svg>
  );
};

export default Svg;
