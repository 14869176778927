import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateStaffAction } from '@/redux/actions';
import { updateStaff, TUpdateStaffResponse } from '@/services/api';

// FUNCTION

export function* updateStaffSaga(action: ActionType<typeof updateStaffAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateStaff, materials);
    const updateStaffResponse: TUpdateStaffResponse = response as TUpdateStaffResponse;
    yield put(updateStaffAction.success(updateStaffResponse));
    successCallback?.(updateStaffResponse);
  } catch (err) {
    yield put(updateStaffAction.failure(err));
    failedCallback?.(err);
  }
}
