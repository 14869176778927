import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        fill={color}
        className="fill"
      />
      <path d="M14 2H10L9 6H15L14 2Z" fill={color} className="fill" />
      <path d="M14 22H10L9 18H15L14 22Z" fill={color} className="fill" />
      <path
        d="M21.6602 8.73198L19.6602 5.26788L15.6961 6.40186L18.6961 11.598L21.6602 8.73198Z"
        fill={color}
        className="fill"
      />
      <path
        d="M4.33984 18.732L2.33984 15.2679L5.30395 12.4019L8.30395 17.598L4.33984 18.732Z"
        fill={color}
        className="fill"
      />
      <path
        d="M19.6602 18.732L21.6602 15.2679L18.6961 12.4019L15.6961 17.598L19.6602 18.732Z"
        fill={color}
        className="fill"
      />
      <path
        d="M2.33984 8.73198L4.33984 5.26788L8.30395 6.40186L5.30394 11.598L2.33984 8.73198Z"
        fill={color}
        className="fill"
      />
    </svg>
  );
};

export default Svg;
