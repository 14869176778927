import { EScheduleStatus } from '@/common/enums';
import { TSchedule } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TUpdateStatusSchedulePaths = {
  id: string | number;
};
export type TUpdateStatusScheduleBody = {
  status: EScheduleStatus;
};

export type TUpdateStatusScheduleMaterials = {
  paths?: TUpdateStatusSchedulePaths;
  body?: TUpdateStatusScheduleBody;
};

export type TUpdateStatusScheduleResponse = TSchedule;

// FUNCTION

export const updateStatusSchedule = async ({
  paths,
  body,
}: TUpdateStatusScheduleMaterials): Promise<TUpdateStatusScheduleResponse> => {
  const response = await ApiService.patch(`/admin/managements/schedules/${paths?.id}/status`, body);
  return response.data;
};
