/* eslint-disable react/require-default-props */
import React, { lazy, Suspense } from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';

import Helpers from '@/services/helpers';
import Loading from '@/components/Loading';

const retryLoadComponent = (fn: () => Promise<unknown>, retriesLeft = 5, interval = 1000): any =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retryLoadComponent(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

const Login = lazy(() => retryLoadComponent(() => import('@/pages/Login')));

const Reservation = lazy(() => retryLoadComponent(() => import('@/pages/Reservation')));
const Customers = lazy(() => retryLoadComponent(() => import('@/pages/Customers')));
const Procedures = lazy(() => retryLoadComponent(() => import('@/pages/Procedures')));
const Sales = lazy(() => retryLoadComponent(() => import('@/pages/Sales')));
const Notice = lazy(() => retryLoadComponent(() => import('@/pages/Notice')));
const NoticeDetail = lazy(() => retryLoadComponent(() => import('@/pages/NoticeDetail')));
const NoticeManagement = lazy(() => retryLoadComponent(() => import('@/pages/NoticeManagement')));
const Statistics = lazy(() => retryLoadComponent(() => import('@/pages/Statistics')));
const Tickets = lazy(() => retryLoadComponent(() => import('@/pages/Tickets')));
const Others = lazy(() => retryLoadComponent(() => import('@/pages/Others')));
const ManagerTalk = lazy(() => retryLoadComponent(() => import('@/pages/ManagerTalk')));
const RedirectReservationBranch = lazy(() => retryLoadComponent(() => import('@/pages/RedirectReservationBranch')));
const KakaoCallbackLogin = lazy(() => retryLoadComponent(() => import('@/pages/KakaoCallbackLogin')));

export const LayoutPaths = {
  Guest: '/guest',
  Auth: '/auth',
  Admin: '/',
};

export const ModulePaths = {};

export const Paths = {
  Login: '/login',

  Reservation: (id?: string): string => `/reservation/${id || ':id'}`,
  Customers: '/customers',
  Procedures: '/procedures',
  Sales: '/sales',
  Notice: '/notices',
  NoticeDetail: (id?: string): string => `/notice/${id || ':id'}`,
  NoticeManagement: '/notices-management',
  Statistics: '/statistics',
  StatisticsBranch: '/statistics/branch',
  Tickets: '/tickets',
  Others: '/others',
  ManagerTalk: '/manager-talk',
  RedirectReservationBranch: '/redirect-branch',
  KakaoCallbackLogin: '/kakao-callback',

  Rest: '*',
};

export const Pages = {
  Login,

  Reservation,
  Customers,
  Procedures,
  Sales,
  Notice,
  NoticeDetail,
  NoticeManagement,
  Statistics,
  Tickets,
  Others,
  ManagerTalk,
  RedirectReservationBranch,
  KakaoCallbackLogin,
};

type TRouteProps = RouteComponentProps & {
  component: React.FC;
  branch?: boolean;
};

export const AuthRoute: React.FC<TRouteProps> = ({ component: Component, ...rest }) => {
  const loggedIn: string | any = Helpers.getAccessToken();

  return loggedIn ? (
    <Redirect noThrow from={Paths.Rest} to={LayoutPaths.Admin} />
  ) : (
    <Suspense
      fallback={
        <div style={{ padding: '100px 0' }}>
          <Loading />
        </div>
      }
    >
      <Component {...rest} />
    </Suspense>
  );
};

export const ProtectedRoute: React.FC<TRouteProps> = ({ component: Component, ...rest }) => {
  const loggedIn: string | any = Helpers.getAccessToken();

  return loggedIn ? (
    <Suspense
      fallback={
        <div style={{ padding: '100px 0' }}>
          <Loading />
        </div>
      }
    >
      <Component {...rest} />
    </Suspense>
  ) : (
    <Redirect noThrow from={Paths.Rest} to={LayoutPaths.Auth} />
  );
};

export const PublicRoute: React.FC<TRouteProps> = ({ component: Component, ...rest }) => (
  <Suspense
    fallback={
      <div style={{ padding: '100px 0' }}>
        <Loading />
      </div>
    }
  >
    <Component {...rest} />
  </Suspense>
);
