import ApiService from '@/services/api';

// TYPES

export type TDeleteSchedulePaths = {
  id: string | number;
};
export type TDeleteScheduleParams = unknown;

export type TDeleteScheduleMaterials = {
  paths?: TDeleteSchedulePaths;
  params?: TDeleteScheduleParams;
};

export type TDeleteScheduleResponse = unknown;

// FUNCTION

export const deleteSchedule = async ({ paths, params }: TDeleteScheduleMaterials): Promise<TDeleteScheduleResponse> => {
  const response = await ApiService.delete(`/admin/managements/schedules/${paths?.id}`, { params });
  return response.data;
};
