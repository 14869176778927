import { TRefund, TSale } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetCustomerUsagePaths = {
  id: string | number;
};
export type TGetCustomerUsageParams = unknown;

export type TGetCustomerUsageMaterials = {
  paths?: TGetCustomerUsagePaths;
  params?: TGetCustomerUsageParams;
};

export type TGetCustomerUsageResponse = {
  histories: TSale[];
  refundHistories: TRefund[];
};

// FUNCTION

export const getCustomerUsage = async ({
  paths,
  params,
}: TGetCustomerUsageMaterials): Promise<TGetCustomerUsageResponse> => {
  const response = await ApiService.get(`/admin/customers/${paths?.id}/usage-info`, { params });
  return response.data;
};
