import ApiService from '@/services/api';

// TYPES

export type TSetKakaoChannelParams = unknown;
export type TSetKakaoChannelBody = {
  code: string;
  branch: string;
};

export type TSetKakaoChannelMaterials = {
  params?: TSetKakaoChannelParams;
  body?: TSetKakaoChannelBody;
};

export type TSetKakaoChannelResponse = unknown;

// FUNCTION

export const setKakaoChannel = async ({
  params,
  body,
}: TSetKakaoChannelMaterials): Promise<TSetKakaoChannelResponse> => {
  const response = await ApiService.post(`/admin/talks/set-kakao-channel`, body, { params });
  return response.data;
};
