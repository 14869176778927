import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { cancelSalePaymentAction } from '@/redux/actions';
import { cancelSalePayment, TCancelSalePaymentResponse } from '@/services/api';

// FUNCTION

export function* cancelSalePaymentSaga(action: ActionType<typeof cancelSalePaymentAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(cancelSalePayment, materials);
    const cancelSalePaymentResponse: TCancelSalePaymentResponse = response as TCancelSalePaymentResponse;
    yield put(cancelSalePaymentAction.success(cancelSalePaymentResponse));
    successCallback?.(cancelSalePaymentResponse);
  } catch (err) {
    yield put(cancelSalePaymentAction.failure(err));
    failedCallback?.(err);
  }
}
