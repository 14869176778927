import { createReducer } from 'deox';

import {
  TCreateProcedureResponse,
  TDeleteProcedureResponse,
  TGetProceduresResponse,
  TUpdateProcedureResponse,
} from '@/services/api/procedure';
import {
  createProcedureAction,
  deleteProcedureAction,
  getProceduresAction,
  updateProcedureAction,
} from '@/redux/actions';
import { createProcedureUpdateState } from './create-procedure';
import { deleteProcedureUpdateState } from './delete-procedure';
import { getProceduresUpdateState } from './get-procedures';
import { updateProcedureUpdateState } from './update-procedure';

export type TProcedureState = {
  createProcedureResponse?: TCreateProcedureResponse;
  deleteProcedureResponse?: TDeleteProcedureResponse;
  getProceduresResponse?: TGetProceduresResponse;
  updateProcedureResponse?: TUpdateProcedureResponse;
};

const initialState: TProcedureState = {
  createProcedureResponse: undefined,
  deleteProcedureResponse: undefined,
  getProceduresResponse: undefined,
  updateProcedureResponse: undefined,
};

const ProcedureReducer = createReducer(initialState, (handleAction) => [
  handleAction(createProcedureAction.success, createProcedureUpdateState),
  handleAction(deleteProcedureAction.success, deleteProcedureUpdateState),
  handleAction(getProceduresAction.success, getProceduresUpdateState),
  handleAction(updateProcedureAction.success, updateProcedureUpdateState),
]);

export default ProcedureReducer;
