import { TScheduleState } from '@/redux/reducers/schedule';
import { TUpdateSchedulePersonalSuccess } from '@/redux/actions/schedule';

export const updateSchedulePersonalUpdateState = (
  state: TScheduleState,
  action: TUpdateSchedulePersonalSuccess,
): TScheduleState => ({
  ...state,
  updateSchedulePersonalResponse: action.payload.response,
});
