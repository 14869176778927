import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getNoticesStaffAction } from '@/redux/actions';
import { getNoticesStaff, TGetNoticesStaffResponse } from '@/services/api';

// FUNCTION

export function* getNoticesStaffSaga(action: ActionType<typeof getNoticesStaffAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getNoticesStaff, materials);
    const getNoticesStaffResponse: TGetNoticesStaffResponse = response as TGetNoticesStaffResponse;
    yield put(getNoticesStaffAction.success(getNoticesStaffResponse));
    successCallback?.(getNoticesStaffResponse);
  } catch (err) {
    yield put(getNoticesStaffAction.failure(err));
    failedCallback?.(err);
  }
}
