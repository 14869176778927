import { createActionCreator } from 'deox';
import { Socket } from 'socket.io-client';

import { EUIAction } from './constants';
import { TInitialSocket, TResetActionStatus, TSetDevice, TSetScroll, TSetVisibleModalCustomerDetail } from './types';

export const uiActions = {
  setDevice: createActionCreator(
    EUIAction.SET_DEVICE,
    (resolve) =>
      (deviceWidth: number): TSetDevice =>
        resolve({ deviceWidth }),
  ),
  setVisibleModalCustomerDetail: createActionCreator(
    EUIAction.SET_VISIBLE_MODAL_CUSTOMER_DETAIL,
    (resolve) =>
      (visible: boolean): TSetVisibleModalCustomerDetail =>
        resolve({ visible }),
  ),
  setScroll: createActionCreator(
    EUIAction.SET_SCROLL,
    (resolve) =>
      (data: { x: number; y: number }): TSetScroll =>
        resolve({ data }),
  ),
  initialSocket: createActionCreator(
    EUIAction.INITIAL_SOCKET,
    (resolve) =>
      (socket?: Socket): TInitialSocket =>
        resolve({ socket }),
  ),
  resetActionStatus: createActionCreator(
    EUIAction.RESET_ACTION_STATUS,
    (resolve) =>
      (actionName: string): TResetActionStatus =>
        resolve({ actionName: actionName.replace('_REQUEST', '') }),
  ),
};
