import { all, takeLatest } from 'redux-saga/effects';

import { createStaffAction, deleteStaffAction, getStaffsAction, updateStaffAction } from '@/redux/actions';

import { createStaffSaga } from './create-staff';
import { deleteStaffSaga } from './delete-staff';
import { getStaffsSaga } from './get-staffs';
import { updateStaffSaga } from './update-staff';

export default function* root(): Generator {
  yield all([
    takeLatest(createStaffAction.request.type, createStaffSaga),
    takeLatest(deleteStaffAction.request.type, deleteStaffSaga),
    takeLatest(getStaffsAction.request.type, getStaffsSaga),
    takeLatest(updateStaffAction.request.type, updateStaffSaga),
  ]);
}
