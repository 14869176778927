import { createReducer } from 'deox';

import {
  TChangeMonthlyTargetSalesResponse,
  TGetStatisticsBranchResponse,
  TGetStatisticsBranchesResponse,
  TGetStatisticsCustomerResponse,
  TGetStatisticsEmployeesResponse,
  TGetStatisticsResponse,
} from '@/services/api/statistic';
import {
  changeMonthlyTargetSalesAction,
  getStatisticsBranchAction,
  getStatisticsBranchesAction,
  getStatisticsCustomerAction,
  getStatisticsEmployeesAction,
  getStatisticsAction,
} from '@/redux/actions';
import { changeMonthlyTargetSalesUpdateState } from './change-monthly-target-sales';
import { getStatisticsBranchUpdateState } from './get-statistics-branch';
import { getStatisticsBranchesUpdateState } from './get-statistics-branches';
import { getStatisticsCustomerUpdateState } from './get-statistics-customer';
import { getStatisticsEmployeesUpdateState } from './get-statistics-employees';
import { getStatisticsUpdateState } from './get-statistics';

export type TStatisticState = {
  changeMonthlyTargetSalesResponse?: TChangeMonthlyTargetSalesResponse;
  getStatisticsBranchResponse?: TGetStatisticsBranchResponse;
  getStatisticsBranchesResponse?: TGetStatisticsBranchesResponse;
  getStatisticsCustomerResponse?: TGetStatisticsCustomerResponse;
  getStatisticsEmployeesResponse?: TGetStatisticsEmployeesResponse;
  getStatisticsResponse?: TGetStatisticsResponse;
};

const initialState: TStatisticState = {
  changeMonthlyTargetSalesResponse: undefined,
  getStatisticsBranchResponse: undefined,
  getStatisticsBranchesResponse: undefined,
  getStatisticsCustomerResponse: undefined,
  getStatisticsEmployeesResponse: undefined,
  getStatisticsResponse: undefined,
};

const StatisticReducer = createReducer(initialState, (handleAction) => [
  handleAction(changeMonthlyTargetSalesAction.success, changeMonthlyTargetSalesUpdateState),
  handleAction(getStatisticsBranchAction.success, getStatisticsBranchUpdateState),
  handleAction(getStatisticsBranchesAction.success, getStatisticsBranchesUpdateState),
  handleAction(getStatisticsCustomerAction.success, getStatisticsCustomerUpdateState),
  handleAction(getStatisticsEmployeesAction.success, getStatisticsEmployeesUpdateState),
  handleAction(getStatisticsAction.success, getStatisticsUpdateState),
]);

export default StatisticReducer;
