import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteScheduleAction } from '@/redux/actions';
import { deleteSchedule, TDeleteScheduleResponse } from '@/services/api';

// FUNCTION

export function* deleteScheduleSaga(action: ActionType<typeof deleteScheduleAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteSchedule, materials);
    const deleteScheduleResponse: TDeleteScheduleResponse = response as TDeleteScheduleResponse;
    yield put(deleteScheduleAction.success(deleteScheduleResponse));
    successCallback?.(deleteScheduleResponse);
  } catch (err) {
    yield put(deleteScheduleAction.failure(err));
    failedCallback?.(err);
  }
}
