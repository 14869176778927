import { TNotice } from '@/common/models';
import ApiService from '@/services/api';

// TYPES
export type TGetNoticePaths = {
  id: string | number;
};

export type TGetNoticeParams = unknown;

export type TGetNoticeMaterials = {
  isSuperAdmin?: boolean;
  paths?: TGetNoticePaths;
  params?: TGetNoticeParams;
};

export type TGetNoticeResponse = {
  data: TNotice;
  nextNotice?: TNotice;
  previousNotice?: TNotice;
};

// FUNCTION

export const getNotice = async ({ paths, params, isSuperAdmin }: TGetNoticeMaterials): Promise<TGetNoticeResponse> => {
  const response = await ApiService.get(`/${isSuperAdmin ? 'management' : 'admin'}/notices/${paths?.id}`, { params });
  return response.data;
};
