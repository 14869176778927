import ApiService from '@/services/api';

// TYPES

export type TUpdateTicketPaths = {
  id: string | number;
};

export type TUpdateTicketBody = {
  name?: string;
  memo?: string;
  dateUse?: number;
  price?: number;
  chargeBalance?: number;
};

export type TUpdateTicketMaterials = {
  paths?: TUpdateTicketPaths;
  body?: TUpdateTicketBody;
};

export type TUpdateTicketResponse = unknown;

// FUNCTION

export const updateTicket = async ({ paths, body }: TUpdateTicketMaterials): Promise<TUpdateTicketResponse> => {
  const response = await ApiService.patch(`/admin/tickets/${paths?.id}`, body);
  return response.data;
};
