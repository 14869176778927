import { createActionCreator } from 'deox';

import { TCreateStaffMaterials, TCreateStaffResponse } from '@/services/api/staff/create-staff';

// CONSTANTS

export enum ECreateStaffAction {
  CREATE_STAFF = 'CREATE_STAFF',
  CREATE_STAFF_REQUEST = 'CREATE_STAFF_REQUEST',
  CREATE_STAFF_SUCCESS = 'CREATE_STAFF_SUCCESS',
  CREATE_STAFF_FAILED = 'CREATE_STAFF_FAILED',
}

// TYPES

export type TCreateStaffRequest = {
  type: ECreateStaffAction.CREATE_STAFF_REQUEST;
  payload: {
    materials: TCreateStaffMaterials;
    successCallback?: (response: TCreateStaffResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateStaffSuccess = {
  type: ECreateStaffAction.CREATE_STAFF_SUCCESS;
  payload: { response: TCreateStaffResponse };
};

export type TCreateStaffFailed = { type: ECreateStaffAction.CREATE_STAFF_FAILED };

// FUNCTION

export const createStaffAction = {
  request: createActionCreator(
    ECreateStaffAction.CREATE_STAFF_REQUEST,
    (resolve) =>
      (
        materials: TCreateStaffMaterials,
        successCallback?: (response: TCreateStaffResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateStaffRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateStaffAction.CREATE_STAFF_SUCCESS,
    (resolve) =>
      (response: TCreateStaffResponse): TCreateStaffSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateStaffAction.CREATE_STAFF_FAILED,
    (resolve) =>
      (error: unknown): TCreateStaffFailed =>
        resolve({ error }),
  ),
};
