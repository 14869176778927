import { createActionCreator } from 'deox';

import { TGetCustomerDetailMaterials, TGetCustomerDetailResponse } from '@/services/api/customer/get-customer-detail';

// CONSTANTS

export enum EGetCustomerDetailAction {
  GET_CUSTOMER_DETAIL = 'GET_CUSTOMER_DETAIL',
  GET_CUSTOMER_DETAIL_REQUEST = 'GET_CUSTOMER_DETAIL_REQUEST',
  GET_CUSTOMER_DETAIL_SUCCESS = 'GET_CUSTOMER_DETAIL_SUCCESS',
  GET_CUSTOMER_DETAIL_FAILED = 'GET_CUSTOMER_DETAIL_FAILED',
}

// TYPES

export type TGetCustomerDetailRequest = {
  type: EGetCustomerDetailAction.GET_CUSTOMER_DETAIL_REQUEST;
  payload: {
    materials: TGetCustomerDetailMaterials;
    successCallback?: (response: TGetCustomerDetailResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetCustomerDetailSuccess = {
  type: EGetCustomerDetailAction.GET_CUSTOMER_DETAIL_SUCCESS;
  payload: { response: TGetCustomerDetailResponse };
};

export type TGetCustomerDetailFailed = { type: EGetCustomerDetailAction.GET_CUSTOMER_DETAIL_FAILED };

// FUNCTION

export const getCustomerDetailAction = {
  request: createActionCreator(
    EGetCustomerDetailAction.GET_CUSTOMER_DETAIL_REQUEST,
    (resolve) =>
      (
        materials: TGetCustomerDetailMaterials,
        successCallback?: (response: TGetCustomerDetailResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetCustomerDetailRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetCustomerDetailAction.GET_CUSTOMER_DETAIL_SUCCESS,
    (resolve) =>
      (response: TGetCustomerDetailResponse): TGetCustomerDetailSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetCustomerDetailAction.GET_CUSTOMER_DETAIL_FAILED,
    (resolve) =>
      (error: unknown): TGetCustomerDetailFailed =>
        resolve({ error }),
  ),
};
