import ApiService from '@/services/api';

// TYPES

export type TDeleteCustomerPaths = {
  id: string | number;
};
export type TDeleteCustomerParams = unknown;

export type TDeleteCustomerMaterials = {
  paths?: TDeleteCustomerPaths;
  params?: TDeleteCustomerParams;
};

export type TDeleteCustomerResponse = unknown;

// FUNCTION

export const deleteCustomer = async ({ paths, params }: TDeleteCustomerMaterials): Promise<TDeleteCustomerResponse> => {
  const response = await ApiService.delete(`/admin/customers/${paths?.id}`, { params });
  return response.data;
};
