import { TTalksState } from '@/redux/reducers/talks';
import { TSaveManagerTalkSettingSuccess } from '@/redux/actions/talks';

export const saveManagerTalkSettingUpdateState = (
  state: TTalksState,
  action: TSaveManagerTalkSettingSuccess,
): TTalksState => ({
  ...state,
  saveManagerTalkSettingResponse: action.payload.response,
});
