import { createActionCreator } from 'deox';

import { TGetStaffsMaterials, TGetStaffsResponse } from '@/services/api/staff/get-staffs';

// CONSTANTS

export enum EGetStaffsAction {
  GET_STAFFS = 'GET_STAFFS',
  GET_STAFFS_REQUEST = 'GET_STAFFS_REQUEST',
  GET_STAFFS_SUCCESS = 'GET_STAFFS_SUCCESS',
  GET_STAFFS_FAILED = 'GET_STAFFS_FAILED',
}

// TYPES

export type TGetStaffsRequest = {
  type: EGetStaffsAction.GET_STAFFS_REQUEST;
  payload: {
    materials: TGetStaffsMaterials;
    successCallback?: (response: TGetStaffsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetStaffsSuccess = {
  type: EGetStaffsAction.GET_STAFFS_SUCCESS;
  payload: { response: TGetStaffsResponse };
};

export type TGetStaffsFailed = { type: EGetStaffsAction.GET_STAFFS_FAILED };

// FUNCTION

export const getStaffsAction = {
  request: createActionCreator(
    EGetStaffsAction.GET_STAFFS_REQUEST,
    (resolve) =>
      (
        materials: TGetStaffsMaterials,
        successCallback?: (response: TGetStaffsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetStaffsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetStaffsAction.GET_STAFFS_SUCCESS,
    (resolve) =>
      (response: TGetStaffsResponse): TGetStaffsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetStaffsAction.GET_STAFFS_FAILED,
    (resolve) =>
      (error: unknown): TGetStaffsFailed =>
        resolve({ error }),
  ),
};
