import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createProcedureAction } from '@/redux/actions';
import { createProcedure, TCreateProcedureResponse } from '@/services/api';

// FUNCTION

export function* createProcedureSaga(action: ActionType<typeof createProcedureAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createProcedure, materials);
    const createProcedureResponse: TCreateProcedureResponse = response as TCreateProcedureResponse;
    yield put(createProcedureAction.success(createProcedureResponse));
    successCallback?.(createProcedureResponse);
  } catch (err) {
    yield put(createProcedureAction.failure(err));
    failedCallback?.(err);
  }
}
