import { createActionCreator } from 'deox';

import { TUpdateStaffMaterials, TUpdateStaffResponse } from '@/services/api/staff/update-staff';

// CONSTANTS

export enum EUpdateStaffAction {
  UPDATE_STAFF = 'UPDATE_STAFF',
  UPDATE_STAFF_REQUEST = 'UPDATE_STAFF_REQUEST',
  UPDATE_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS',
  UPDATE_STAFF_FAILED = 'UPDATE_STAFF_FAILED',
}

// TYPES

export type TUpdateStaffRequest = {
  type: EUpdateStaffAction.UPDATE_STAFF_REQUEST;
  payload: {
    materials: TUpdateStaffMaterials;
    successCallback?: (response: TUpdateStaffResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateStaffSuccess = {
  type: EUpdateStaffAction.UPDATE_STAFF_SUCCESS;
  payload: { response: TUpdateStaffResponse };
};

export type TUpdateStaffFailed = { type: EUpdateStaffAction.UPDATE_STAFF_FAILED };

// FUNCTION

export const updateStaffAction = {
  request: createActionCreator(
    EUpdateStaffAction.UPDATE_STAFF_REQUEST,
    (resolve) =>
      (
        materials: TUpdateStaffMaterials,
        successCallback?: (response: TUpdateStaffResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateStaffRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateStaffAction.UPDATE_STAFF_SUCCESS,
    (resolve) =>
      (response: TUpdateStaffResponse): TUpdateStaffSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateStaffAction.UPDATE_STAFF_FAILED,
    (resolve) =>
      (error: unknown): TUpdateStaffFailed =>
        resolve({ error }),
  ),
};
