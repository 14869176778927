import { createActionCreator } from 'deox';

import { TDeleteScheduleMaterials, TDeleteScheduleResponse } from '@/services/api/schedule/delete-schedule';

// CONSTANTS

export enum EDeleteScheduleAction {
  DELETE_SCHEDULE = 'DELETE_SCHEDULE',
  DELETE_SCHEDULE_REQUEST = 'DELETE_SCHEDULE_REQUEST',
  DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS',
  DELETE_SCHEDULE_FAILED = 'DELETE_SCHEDULE_FAILED',
}

// TYPES

export type TDeleteScheduleRequest = {
  type: EDeleteScheduleAction.DELETE_SCHEDULE_REQUEST;
  payload: {
    materials: TDeleteScheduleMaterials;
    successCallback?: (response: TDeleteScheduleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteScheduleSuccess = {
  type: EDeleteScheduleAction.DELETE_SCHEDULE_SUCCESS;
  payload: { response: TDeleteScheduleResponse };
};

export type TDeleteScheduleFailed = { type: EDeleteScheduleAction.DELETE_SCHEDULE_FAILED };

// FUNCTION

export const deleteScheduleAction = {
  request: createActionCreator(
    EDeleteScheduleAction.DELETE_SCHEDULE_REQUEST,
    (resolve) =>
      (
        materials: TDeleteScheduleMaterials,
        successCallback?: (response: TDeleteScheduleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteScheduleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteScheduleAction.DELETE_SCHEDULE_SUCCESS,
    (resolve) =>
      (response: TDeleteScheduleResponse): TDeleteScheduleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteScheduleAction.DELETE_SCHEDULE_FAILED,
    (resolve) =>
      (error: unknown): TDeleteScheduleFailed =>
        resolve({ error }),
  ),
};
