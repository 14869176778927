import { createActionCreator } from 'deox';

import { TDeleteTicketMaterials, TDeleteTicketResponse } from '@/services/api/ticket/delete-ticket';

// CONSTANTS

export enum EDeleteTicketAction {
  DELETE_TICKET = 'DELETE_TICKET',
  DELETE_TICKET_REQUEST = 'DELETE_TICKET_REQUEST',
  DELETE_TICKET_SUCCESS = 'DELETE_TICKET_SUCCESS',
  DELETE_TICKET_FAILED = 'DELETE_TICKET_FAILED',
}

// TYPES

export type TDeleteTicketRequest = {
  type: EDeleteTicketAction.DELETE_TICKET_REQUEST;
  payload: {
    materials: TDeleteTicketMaterials;
    successCallback?: (response: TDeleteTicketResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteTicketSuccess = {
  type: EDeleteTicketAction.DELETE_TICKET_SUCCESS;
  payload: { response: TDeleteTicketResponse };
};

export type TDeleteTicketFailed = { type: EDeleteTicketAction.DELETE_TICKET_FAILED };

// FUNCTION

export const deleteTicketAction = {
  request: createActionCreator(
    EDeleteTicketAction.DELETE_TICKET_REQUEST,
    (resolve) =>
      (
        materials: TDeleteTicketMaterials,
        successCallback?: (response: TDeleteTicketResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteTicketRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteTicketAction.DELETE_TICKET_SUCCESS,
    (resolve) =>
      (response: TDeleteTicketResponse): TDeleteTicketSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteTicketAction.DELETE_TICKET_FAILED,
    (resolve) =>
      (error: unknown): TDeleteTicketFailed =>
        resolve({ error }),
  ),
};
