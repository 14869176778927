import { createActionCreator } from 'deox';

import { TDeleteInquiryMaterials, TDeleteInquiryResponse } from '@/services/api/inquiry/delete-inquiry';

// CONSTANTS

export enum EDeleteInquiryAction {
  DELETE_INQUIRY = 'DELETE_INQUIRY',
  DELETE_INQUIRY_REQUEST = 'DELETE_INQUIRY_REQUEST',
  DELETE_INQUIRY_SUCCESS = 'DELETE_INQUIRY_SUCCESS',
  DELETE_INQUIRY_FAILED = 'DELETE_INQUIRY_FAILED',
}

// TYPES

export type TDeleteInquiryRequest = {
  type: EDeleteInquiryAction.DELETE_INQUIRY_REQUEST;
  payload: {
    materials: TDeleteInquiryMaterials;
    successCallback?: (response: TDeleteInquiryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteInquirySuccess = {
  type: EDeleteInquiryAction.DELETE_INQUIRY_SUCCESS;
  payload: { response: TDeleteInquiryResponse };
};

export type TDeleteInquiryFailed = { type: EDeleteInquiryAction.DELETE_INQUIRY_FAILED };

// FUNCTION

export const deleteInquiryAction = {
  request: createActionCreator(
    EDeleteInquiryAction.DELETE_INQUIRY_REQUEST,
    (resolve) =>
      (
        materials: TDeleteInquiryMaterials,
        successCallback?: (response: TDeleteInquiryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteInquiryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteInquiryAction.DELETE_INQUIRY_SUCCESS,
    (resolve) =>
      (response: TDeleteInquiryResponse): TDeleteInquirySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteInquiryAction.DELETE_INQUIRY_FAILED,
    (resolve) =>
      (error: unknown): TDeleteInquiryFailed =>
        resolve({ error }),
  ),
};
