import ApiService from '@/services/api';

// TYPES

export type TDeleteProcedurePaths = {
  id: string | number;
};
export type TDeleteProcedureParams = unknown;

export type TDeleteProcedureMaterials = {
  paths?: TDeleteProcedurePaths;
  params?: TDeleteProcedureParams;
};

export type TDeleteProcedureResponse = unknown;

// FUNCTION

export const deleteProcedure = async ({
  paths,
  params,
}: TDeleteProcedureMaterials): Promise<TDeleteProcedureResponse> => {
  const response = await ApiService.delete(`/admin/procedures/${paths?.id}`, { params });
  return response.data;
};
