import ApiService from '@/services/api';

// TYPES

export type TDisconnectKakaoBody = {
  branch: string;
};

export type TDisconnectKakaoMaterials = {
  body?: TDisconnectKakaoBody;
};

export type TDisconnectKakaoResponse = unknown;

// FUNCTION

export const disconnectKakao = async ({ body }: TDisconnectKakaoMaterials): Promise<TDisconnectKakaoResponse> => {
  const response = await ApiService.patch(`/admin/talks/disconnect-kakao-channel`, body);
  return response.data;
};
