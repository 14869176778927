import { createActionCreator } from 'deox';

import { TGetCustomerUsageMaterials, TGetCustomerUsageResponse } from '@/services/api/customer/get-customer-usage';

// CONSTANTS

export enum EGetCustomerUsageAction {
  GET_CUSTOMER_USAGE = 'GET_CUSTOMER_USAGE',
  GET_CUSTOMER_USAGE_REQUEST = 'GET_CUSTOMER_USAGE_REQUEST',
  GET_CUSTOMER_USAGE_SUCCESS = 'GET_CUSTOMER_USAGE_SUCCESS',
  GET_CUSTOMER_USAGE_FAILED = 'GET_CUSTOMER_USAGE_FAILED',
}

// TYPES

export type TGetCustomerUsageRequest = {
  type: EGetCustomerUsageAction.GET_CUSTOMER_USAGE_REQUEST;
  payload: {
    materials: TGetCustomerUsageMaterials;
    successCallback?: (response: TGetCustomerUsageResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetCustomerUsageSuccess = {
  type: EGetCustomerUsageAction.GET_CUSTOMER_USAGE_SUCCESS;
  payload: { response: TGetCustomerUsageResponse };
};

export type TGetCustomerUsageFailed = { type: EGetCustomerUsageAction.GET_CUSTOMER_USAGE_FAILED };

// FUNCTION

export const getCustomerUsageAction = {
  request: createActionCreator(
    EGetCustomerUsageAction.GET_CUSTOMER_USAGE_REQUEST,
    (resolve) =>
      (
        materials: TGetCustomerUsageMaterials,
        successCallback?: (response: TGetCustomerUsageResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetCustomerUsageRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetCustomerUsageAction.GET_CUSTOMER_USAGE_SUCCESS,
    (resolve) =>
      (response: TGetCustomerUsageResponse): TGetCustomerUsageSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetCustomerUsageAction.GET_CUSTOMER_USAGE_FAILED,
    (resolve) =>
      (error: unknown): TGetCustomerUsageFailed =>
        resolve({ error }),
  ),
};
