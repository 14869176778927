import { createReducer } from 'deox';

import {
  TCancelSalePaymentResponse,
  TCreateSaleResponse,
  TGetSaleResponse,
  TGetSalesResponse,
  TUpdateSaleNoteResponse,
  TUpdateSaleResponse,
} from '@/services/api/sale';
import {
  cancelSalePaymentAction,
  createSaleAction,
  getSaleAction,
  getSalesAction,
  updateSaleNoteAction,
  updateSaleAction,
} from '@/redux/actions';
import { cancelSalePaymentUpdateState } from './cancel-sale-payment';
import { createSaleUpdateState } from './create-sale';
import { getSaleUpdateState } from './get-sale';
import { getSalesUpdateState } from './get-sales';
import { updateSaleNoteUpdateState } from './update-sale-note';
import { updateSaleUpdateState } from './update-sale';

export type TSaleState = {
  cancelSalePaymentResponse?: TCancelSalePaymentResponse;
  createSaleResponse?: TCreateSaleResponse;
  getSaleResponse?: TGetSaleResponse;
  getSalesResponse?: TGetSalesResponse;
  updateSaleNoteResponse?: TUpdateSaleNoteResponse;
  updateSaleResponse?: TUpdateSaleResponse;
};

const initialState: TSaleState = {
  cancelSalePaymentResponse: undefined,
  createSaleResponse: undefined,
  getSaleResponse: undefined,
  getSalesResponse: undefined,
  updateSaleNoteResponse: undefined,
  updateSaleResponse: undefined,
};

const SaleReducer = createReducer(initialState, (handleAction) => [
  handleAction(cancelSalePaymentAction.success, cancelSalePaymentUpdateState),
  handleAction(createSaleAction.success, createSaleUpdateState),
  handleAction(getSaleAction.success, getSaleUpdateState),
  handleAction(getSalesAction.success, getSalesUpdateState),
  handleAction(updateSaleNoteAction.success, updateSaleNoteUpdateState),
  handleAction(updateSaleAction.success, updateSaleUpdateState),
]);

export default SaleReducer;
