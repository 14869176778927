import { TStatisticState } from '@/redux/reducers/statistic';
import { TGetStatisticsEmployeesSuccess } from '@/redux/actions/statistic';

export const getStatisticsEmployeesUpdateState = (
  state: TStatisticState,
  action: TGetStatisticsEmployeesSuccess,
): TStatisticState => ({
  ...state,
  getStatisticsEmployeesResponse: action.payload.response,
});
