import { all, takeLatest } from 'redux-saga/effects';

import {
  createScheduleCustomerAction,
  createSchedulePersonalAction,
  deleteScheduleAction,
  getBookingScheduleTodayAction,
  getScheduleAction,
  getSchedulesAction,
  updateCustomerNoteAction,
  updateScheduleCustomerAction,
  updateSchedulePersonalAction,
  updateStatusScheduleAction,
} from '@/redux/actions';

import { createScheduleCustomerSaga } from './create-schedule-customer';
import { createSchedulePersonalSaga } from './create-schedule-personal';
import { deleteScheduleSaga } from './delete-schedule';
import { getBookingScheduleTodaySaga } from './get-booking-schedule-today';
import { getScheduleSaga } from './get-schedule';
import { getSchedulesSaga } from './get-schedules';
import { updateCustomerNoteSaga } from './update-customer-note';
import { updateScheduleCustomerSaga } from './update-schedule-customer';
import { updateSchedulePersonalSaga } from './update-schedule-personal';
import { updateStatusScheduleSaga } from './update-status-schedule';

export default function* root(): Generator {
  yield all([
    takeLatest(createScheduleCustomerAction.request.type, createScheduleCustomerSaga),
    takeLatest(createSchedulePersonalAction.request.type, createSchedulePersonalSaga),
    takeLatest(deleteScheduleAction.request.type, deleteScheduleSaga),
    takeLatest(getBookingScheduleTodayAction.request.type, getBookingScheduleTodaySaga),
    takeLatest(getScheduleAction.request.type, getScheduleSaga),
    takeLatest(getSchedulesAction.request.type, getSchedulesSaga),
    takeLatest(updateCustomerNoteAction.request.type, updateCustomerNoteSaga),
    takeLatest(updateScheduleCustomerAction.request.type, updateScheduleCustomerSaga),
    takeLatest(updateSchedulePersonalAction.request.type, updateSchedulePersonalSaga),
    takeLatest(updateStatusScheduleAction.request.type, updateStatusScheduleSaga),
  ]);
}
