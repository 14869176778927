import { createActionCreator } from 'deox';

import {
  TGetManagerTalksHistoriesMaterials,
  TGetManagerTalksHistoriesResponse,
} from '@/services/api/talks/get-manager-talks-histories';

// CONSTANTS

export enum EGetManagerTalksHistoriesAction {
  GET_MANAGER_TALKS_HISTORIES = 'GET_MANAGER_TALKS_HISTORIES',
  GET_MANAGER_TALKS_HISTORIES_REQUEST = 'GET_MANAGER_TALKS_HISTORIES_REQUEST',
  GET_MANAGER_TALKS_HISTORIES_SUCCESS = 'GET_MANAGER_TALKS_HISTORIES_SUCCESS',
  GET_MANAGER_TALKS_HISTORIES_FAILED = 'GET_MANAGER_TALKS_HISTORIES_FAILED',
}

// TYPES

export type TGetManagerTalksHistoriesRequest = {
  type: EGetManagerTalksHistoriesAction.GET_MANAGER_TALKS_HISTORIES_REQUEST;
  payload: {
    materials: TGetManagerTalksHistoriesMaterials;
    successCallback?: (response: TGetManagerTalksHistoriesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetManagerTalksHistoriesSuccess = {
  type: EGetManagerTalksHistoriesAction.GET_MANAGER_TALKS_HISTORIES_SUCCESS;
  payload: { response: TGetManagerTalksHistoriesResponse };
};

export type TGetManagerTalksHistoriesFailed = {
  type: EGetManagerTalksHistoriesAction.GET_MANAGER_TALKS_HISTORIES_FAILED;
};

// FUNCTION

export const getManagerTalksHistoriesAction = {
  request: createActionCreator(
    EGetManagerTalksHistoriesAction.GET_MANAGER_TALKS_HISTORIES_REQUEST,
    (resolve) =>
      (
        materials: TGetManagerTalksHistoriesMaterials,
        successCallback?: (response: TGetManagerTalksHistoriesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetManagerTalksHistoriesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetManagerTalksHistoriesAction.GET_MANAGER_TALKS_HISTORIES_SUCCESS,
    (resolve) =>
      (response: TGetManagerTalksHistoriesResponse): TGetManagerTalksHistoriesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetManagerTalksHistoriesAction.GET_MANAGER_TALKS_HISTORIES_FAILED,
    (resolve) =>
      (error: unknown): TGetManagerTalksHistoriesFailed =>
        resolve({ error }),
  ),
};
