import { createActionCreator } from 'deox';

import { TCreateCustomerMaterials, TCreateCustomerResponse } from '@/services/api/customer/create-customer';

// CONSTANTS

export enum ECreateCustomerAction {
  CREATE_CUSTOMER = 'CREATE_CUSTOMER',
  CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST',
  CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_FAILED = 'CREATE_CUSTOMER_FAILED',
}

// TYPES

export type TCreateCustomerRequest = {
  type: ECreateCustomerAction.CREATE_CUSTOMER_REQUEST;
  payload: {
    materials: TCreateCustomerMaterials;
    successCallback?: (response: TCreateCustomerResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateCustomerSuccess = {
  type: ECreateCustomerAction.CREATE_CUSTOMER_SUCCESS;
  payload: { response: TCreateCustomerResponse };
};

export type TCreateCustomerFailed = { type: ECreateCustomerAction.CREATE_CUSTOMER_FAILED };

// FUNCTION

export const createCustomerAction = {
  request: createActionCreator(
    ECreateCustomerAction.CREATE_CUSTOMER_REQUEST,
    (resolve) =>
      (
        materials: TCreateCustomerMaterials,
        successCallback?: (response: TCreateCustomerResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateCustomerRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateCustomerAction.CREATE_CUSTOMER_SUCCESS,
    (resolve) =>
      (response: TCreateCustomerResponse): TCreateCustomerSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateCustomerAction.CREATE_CUSTOMER_FAILED,
    (resolve) =>
      (error: unknown): TCreateCustomerFailed =>
        resolve({ error }),
  ),
};
