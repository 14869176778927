import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { saveManagerTalkSettingAction } from '@/redux/actions';
import { saveManagerTalkSetting, TSaveManagerTalkSettingResponse } from '@/services/api';

// FUNCTION

export function* saveManagerTalkSettingSaga(
  action: ActionType<typeof saveManagerTalkSettingAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(saveManagerTalkSetting, materials);
    const saveManagerTalkSettingResponse: TSaveManagerTalkSettingResponse = response as TSaveManagerTalkSettingResponse;
    yield put(saveManagerTalkSettingAction.success(saveManagerTalkSettingResponse));
    successCallback?.(saveManagerTalkSettingResponse);
  } catch (err) {
    yield put(saveManagerTalkSettingAction.failure(err));
    failedCallback?.(err);
  }
}
