import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteStaffAction } from '@/redux/actions';
import { deleteStaff, TDeleteStaffResponse } from '@/services/api';

// FUNCTION

export function* deleteStaffSaga(action: ActionType<typeof deleteStaffAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteStaff, materials);
    const deleteStaffResponse: TDeleteStaffResponse = response as TDeleteStaffResponse;
    yield put(deleteStaffAction.success(deleteStaffResponse));
    successCallback?.(deleteStaffResponse);
  } catch (err) {
    yield put(deleteStaffAction.failure(err));
    failedCallback?.(err);
  }
}
