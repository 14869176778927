import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateStatusScheduleAction } from '@/redux/actions';
import { updateStatusSchedule, TUpdateStatusScheduleResponse } from '@/services/api';

// FUNCTION

export function* updateStatusScheduleSaga(action: ActionType<typeof updateStatusScheduleAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateStatusSchedule, materials);
    const updateStatusScheduleResponse: TUpdateStatusScheduleResponse = response as TUpdateStatusScheduleResponse;
    yield put(updateStatusScheduleAction.success(updateStatusScheduleResponse));
    successCallback?.(updateStatusScheduleResponse);
  } catch (err) {
    yield put(updateStatusScheduleAction.failure(err));
    failedCallback?.(err);
  }
}
