import { TStatisticCustomer } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetStatisticsCustomerParams = {
  fromDate?: string;
  toDate?: string;
  branch?: string;
};

export type TGetStatisticsCustomerMaterials = {
  params?: TGetStatisticsCustomerParams;
  isSuperAdmin?: boolean;
};

export type TGetStatisticsCustomerResponse = TStatisticCustomer[];

// FUNCTION

export const getStatisticsCustomer = async ({
  params,
  isSuperAdmin,
}: TGetStatisticsCustomerMaterials): Promise<TGetStatisticsCustomerResponse> => {
  const response = await ApiService.get(`${isSuperAdmin ? '/management' : ''}/statistics/customers`, { params });
  return response.data;
};
