import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateNoticeAction } from '@/redux/actions';
import { updateNotice, TUpdateNoticeResponse } from '@/services/api';

// FUNCTION

export function* updateNoticeSaga(action: ActionType<typeof updateNoticeAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateNotice, materials);
    const updateNoticeResponse: TUpdateNoticeResponse = response as TUpdateNoticeResponse;
    yield put(updateNoticeAction.success(updateNoticeResponse));
    successCallback?.(updateNoticeResponse);
  } catch (err) {
    yield put(updateNoticeAction.failure(err));
    failedCallback?.(err);
  }
}
