import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 18C2 14.6863 4.68629 12 8 12H16C19.3137 12 22 14.6863 22 18V21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21V18Z"
        fill={color}
        className="fill"
      />
      <circle cx="12" cy="6" r="4" fill={color} className="fill" />
    </svg>
  );
};

export default Svg;
