import { createActionCreator } from 'deox';

import { TCreateProcedureMaterials, TCreateProcedureResponse } from '@/services/api/procedure/create-procedure';

// CONSTANTS

export enum ECreateProcedureAction {
  CREATE_PROCEDURE = 'CREATE_PROCEDURE',
  CREATE_PROCEDURE_REQUEST = 'CREATE_PROCEDURE_REQUEST',
  CREATE_PROCEDURE_SUCCESS = 'CREATE_PROCEDURE_SUCCESS',
  CREATE_PROCEDURE_FAILED = 'CREATE_PROCEDURE_FAILED',
}

// TYPES

export type TCreateProcedureRequest = {
  type: ECreateProcedureAction.CREATE_PROCEDURE_REQUEST;
  payload: {
    materials: TCreateProcedureMaterials;
    successCallback?: (response: TCreateProcedureResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateProcedureSuccess = {
  type: ECreateProcedureAction.CREATE_PROCEDURE_SUCCESS;
  payload: { response: TCreateProcedureResponse };
};

export type TCreateProcedureFailed = { type: ECreateProcedureAction.CREATE_PROCEDURE_FAILED };

// FUNCTION

export const createProcedureAction = {
  request: createActionCreator(
    ECreateProcedureAction.CREATE_PROCEDURE_REQUEST,
    (resolve) =>
      (
        materials: TCreateProcedureMaterials,
        successCallback?: (response: TCreateProcedureResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateProcedureRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateProcedureAction.CREATE_PROCEDURE_SUCCESS,
    (resolve) =>
      (response: TCreateProcedureResponse): TCreateProcedureSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateProcedureAction.CREATE_PROCEDURE_FAILED,
    (resolve) =>
      (error: unknown): TCreateProcedureFailed =>
        resolve({ error }),
  ),
};
