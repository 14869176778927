import ApiService from '@/services/api';

// TYPES

export type TDeleteTicketPaths = {
  id: string | number;
};
export type TDeleteTicketParams = unknown;

export type TDeleteTicketMaterials = {
  paths?: TDeleteTicketPaths;
  params?: TDeleteTicketParams;
};

export type TDeleteTicketResponse = unknown;

// FUNCTION

export const deleteTicket = async ({ paths, params }: TDeleteTicketMaterials): Promise<TDeleteTicketResponse> => {
  const response = await ApiService.delete(`/admin/tickets/${paths?.id}`, { params });
  return response.data;
};
