import { createActionCreator } from 'deox';

import { TDeleteNoticeMaterials, TDeleteNoticeResponse } from '@/services/api/notice/delete-notice';

// CONSTANTS

export enum EDeleteNoticeAction {
  DELETE_NOTICE = 'DELETE_NOTICE',
  DELETE_NOTICE_REQUEST = 'DELETE_NOTICE_REQUEST',
  DELETE_NOTICE_SUCCESS = 'DELETE_NOTICE_SUCCESS',
  DELETE_NOTICE_FAILED = 'DELETE_NOTICE_FAILED',
}

// TYPES

export type TDeleteNoticeRequest = {
  type: EDeleteNoticeAction.DELETE_NOTICE_REQUEST;
  payload: {
    materials: TDeleteNoticeMaterials;
    successCallback?: (response: TDeleteNoticeResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteNoticeSuccess = {
  type: EDeleteNoticeAction.DELETE_NOTICE_SUCCESS;
  payload: { response: TDeleteNoticeResponse };
};

export type TDeleteNoticeFailed = { type: EDeleteNoticeAction.DELETE_NOTICE_FAILED };

// FUNCTION

export const deleteNoticeAction = {
  request: createActionCreator(
    EDeleteNoticeAction.DELETE_NOTICE_REQUEST,
    (resolve) =>
      (
        materials: TDeleteNoticeMaterials,
        successCallback?: (response: TDeleteNoticeResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteNoticeRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteNoticeAction.DELETE_NOTICE_SUCCESS,
    (resolve) =>
      (response: TDeleteNoticeResponse): TDeleteNoticeSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteNoticeAction.DELETE_NOTICE_FAILED,
    (resolve) =>
      (error: unknown): TDeleteNoticeFailed =>
        resolve({ error }),
  ),
};
