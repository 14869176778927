import { createActionCreator } from 'deox';

import {
  TUpdateSalesSettingMaterials,
  TUpdateSalesSettingResponse,
} from '@/services/api/sales-setting/update-sales-setting';

// CONSTANTS

export enum EUpdateSalesSettingAction {
  UPDATE_SALES_SETTING = 'UPDATE_SALES_SETTING',
  UPDATE_SALES_SETTING_REQUEST = 'UPDATE_SALES_SETTING_REQUEST',
  UPDATE_SALES_SETTING_SUCCESS = 'UPDATE_SALES_SETTING_SUCCESS',
  UPDATE_SALES_SETTING_FAILED = 'UPDATE_SALES_SETTING_FAILED',
}

// TYPES

export type TUpdateSalesSettingRequest = {
  type: EUpdateSalesSettingAction.UPDATE_SALES_SETTING_REQUEST;
  payload: {
    materials: TUpdateSalesSettingMaterials;
    successCallback?: (response: TUpdateSalesSettingResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateSalesSettingSuccess = {
  type: EUpdateSalesSettingAction.UPDATE_SALES_SETTING_SUCCESS;
  payload: { response: TUpdateSalesSettingResponse };
};

export type TUpdateSalesSettingFailed = { type: EUpdateSalesSettingAction.UPDATE_SALES_SETTING_FAILED };

// FUNCTION

export const updateSalesSettingAction = {
  request: createActionCreator(
    EUpdateSalesSettingAction.UPDATE_SALES_SETTING_REQUEST,
    (resolve) =>
      (
        materials: TUpdateSalesSettingMaterials,
        successCallback?: (response: TUpdateSalesSettingResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateSalesSettingRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateSalesSettingAction.UPDATE_SALES_SETTING_SUCCESS,
    (resolve) =>
      (response: TUpdateSalesSettingResponse): TUpdateSalesSettingSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateSalesSettingAction.UPDATE_SALES_SETTING_FAILED,
    (resolve) =>
      (error: unknown): TUpdateSalesSettingFailed =>
        resolve({ error }),
  ),
};
