import ApiService from '@/services/api';

// TYPES

export type TSaveManagerTalkSettingParams = unknown;
export type TSaveManagerTalkSettingBody = {
  reservationConfirmationNotification?: {
    sendNotifications: boolean;
    procedureMenu: boolean;
    timeItTakes: boolean;
    showAddress: boolean;
    parkingGuidance: boolean;
    parkingGuidanceDetail: string;
  };
  reservationChangeNotification?: {
    sendNotifications: boolean;
    scheduleChange: boolean;
    changeReservationMenu: boolean;
    procedureMenu: boolean;
    timeItTakes: boolean;
    showAddress: boolean;
    parkingGuidance: boolean;
    parkingGuidanceDetail: string;
  };
  reservationCancellationNotification?: {
    sendNotifications: boolean;
    procedureMenu: boolean;
    timeItTakes: boolean;
    showAddress: boolean;
    parkingGuidance: boolean;
    parkingGuidanceDetail: string;
  };
  notificationOnTheDayOfYourVisit?: {
    sendNotifications: boolean;
    sendTime: string;
    procedureMenu: boolean;
    timeItTakes: boolean;
    showAddress: boolean;
    parkingGuidance: boolean;
    parkingGuidanceDetail: string;
  };
  notificationOfUpcomingVisit?: {
    sendNotifications: boolean;
    shippingDate: number;
    sendTime: string;
    procedureMenu: boolean;
    timeItTakes: boolean;
    showAddress: boolean;
    parkingGuidance: boolean;
    parkingGuidanceDetail: string;
  };
  remainingPrepaidTicketNotification?: {
    sendNotifications: boolean;
    procedureMenu: boolean;
    timeItTakes: boolean;
    showAddress: boolean;
    parkingGuidance: boolean;
    parkingGuidanceDetail: string;
  };
  sendTest?: boolean;
};

export type TSaveManagerTalkSettingMaterials = {
  params?: TSaveManagerTalkSettingParams;
  body?: TSaveManagerTalkSettingBody;
};

export type TSaveManagerTalkSettingResponse = unknown;

// FUNCTION

export const saveManagerTalkSetting = async ({
  params,
  body,
}: TSaveManagerTalkSettingMaterials): Promise<TSaveManagerTalkSettingResponse> => {
  const response = await ApiService.post(`/admin/talks/save-setting`, body, { params });
  return response.data;
};
