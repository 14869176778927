import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 15H10V21H5C4.44772 21 4 20.5523 4 20V15Z" fill={color} />
      <rect x="4" y="13" width="16" height="2" fill={color} />
      <path d="M14 15H20V20C20 20.5523 19.5523 21 19 21H14V15Z" fill={color} />
      <path
        d="M21.5858 13H2.41421C1.52331 13 1.07714 11.9229 1.70711 11.2929L11.2929 1.70711C11.6834 1.31658 12.3166 1.31658 12.7071 1.70711L22.2929 11.2929C22.9229 11.9229 22.4767 13 21.5858 13Z"
        fill={color}
      />
    </svg>
  );
};

export default Svg;
