import { createActionCreator } from 'deox';

import { TCreateTicketMaterials, TCreateTicketResponse } from '@/services/api/ticket/create-ticket';

// CONSTANTS

export enum ECreateTicketAction {
  CREATE_TICKET = 'CREATE_TICKET',
  CREATE_TICKET_REQUEST = 'CREATE_TICKET_REQUEST',
  CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS',
  CREATE_TICKET_FAILED = 'CREATE_TICKET_FAILED',
}

// TYPES

export type TCreateTicketRequest = {
  type: ECreateTicketAction.CREATE_TICKET_REQUEST;
  payload: {
    materials: TCreateTicketMaterials;
    successCallback?: (response: TCreateTicketResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateTicketSuccess = {
  type: ECreateTicketAction.CREATE_TICKET_SUCCESS;
  payload: { response: TCreateTicketResponse };
};

export type TCreateTicketFailed = { type: ECreateTicketAction.CREATE_TICKET_FAILED };

// FUNCTION

export const createTicketAction = {
  request: createActionCreator(
    ECreateTicketAction.CREATE_TICKET_REQUEST,
    (resolve) =>
      (
        materials: TCreateTicketMaterials,
        successCallback?: (response: TCreateTicketResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateTicketRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateTicketAction.CREATE_TICKET_SUCCESS,
    (resolve) =>
      (response: TCreateTicketResponse): TCreateTicketSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateTicketAction.CREATE_TICKET_FAILED,
    (resolve) =>
      (error: unknown): TCreateTicketFailed =>
        resolve({ error }),
  ),
};
