import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateSalesSettingAction } from '@/redux/actions';
import { updateSalesSetting, TUpdateSalesSettingResponse } from '@/services/api';

// FUNCTION

export function* updateSalesSettingSaga(action: ActionType<typeof updateSalesSettingAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateSalesSetting, materials);
    const updateSalesSettingResponse: TUpdateSalesSettingResponse = response as TUpdateSalesSettingResponse;
    yield put(updateSalesSettingAction.success(updateSalesSettingResponse));
    successCallback?.(updateSalesSettingResponse);
  } catch (err) {
    yield put(updateSalesSettingAction.failure(err));
    failedCallback?.(err);
  }
}
