import { createReducer } from 'deox';

import { TGetTreatmentsResponse } from '@/services/api/treatment';
import { getTreatmentsAction } from '@/redux/actions';
import { getTreatmentsUpdateState } from './get-treatments';

export type TTreatmentState = {
  getTreatmentsResponse?: TGetTreatmentsResponse;
};

const initialState: TTreatmentState = {
  getTreatmentsResponse: undefined,
};

const TreatmentReducer = createReducer(initialState, (handleAction) => [
  handleAction(getTreatmentsAction.success, getTreatmentsUpdateState),
]);

export default TreatmentReducer;
