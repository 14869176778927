import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 6L7 6" className="stroke" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M12 6L19 6" className="stroke" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <circle cx="10" cy="6" r="2" className="stroke" stroke={color} strokeWidth="2" />
      <path d="M5 18L8 18" className="stroke" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M12 18L19 18" className="stroke" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <circle cx="10" cy="18" r="2" className="stroke" stroke={color} strokeWidth="2" />
      <path d="M19 12H16" className="stroke" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M12 12H5" className="stroke" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <circle r="2" transform="matrix(-1 0 0 1 14 12)" className="stroke" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default Svg;
