import { createActionCreator } from 'deox';

import { TAdminLoginMaterials, TAdminLoginResponse } from '@/services/api/auth/admin-login';

// CONSTANTS

export enum EAdminLoginAction {
  ADMIN_LOGIN = 'ADMIN_LOGIN',
  ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST',
  ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS',
  ADMIN_LOGIN_FAILED = 'ADMIN_LOGIN_FAILED',
}

// TYPES

export type TAdminLoginRequest = {
  type: EAdminLoginAction.ADMIN_LOGIN_REQUEST;
  payload: {
    materials: TAdminLoginMaterials;
    successCallback?: (response: TAdminLoginResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TAdminLoginSuccess = {
  type: EAdminLoginAction.ADMIN_LOGIN_SUCCESS;
  payload: { response: TAdminLoginResponse };
};

export type TAdminLoginFailed = { type: EAdminLoginAction.ADMIN_LOGIN_FAILED };

// FUNCTION

export const adminLoginAction = {
  request: createActionCreator(
    EAdminLoginAction.ADMIN_LOGIN_REQUEST,
    (resolve) =>
      (
        materials: TAdminLoginMaterials,
        successCallback?: (response: TAdminLoginResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TAdminLoginRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EAdminLoginAction.ADMIN_LOGIN_SUCCESS,
    (resolve) =>
      (response: TAdminLoginResponse): TAdminLoginSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EAdminLoginAction.ADMIN_LOGIN_FAILED,
    (resolve) =>
      (error: unknown): TAdminLoginFailed =>
        resolve({ error }),
  ),
};
