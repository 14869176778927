import ApiService from '@/services/api';

// TYPES

export type TAddPointsCustomerPaths = {
  id: string | number;
};
export type TAddPointsCustomerParams = unknown;
export type TAddPointsCustomerBody = {
  title: string;
  memo: string;
  point: number;
};

export type TAddPointsCustomerMaterials = {
  paths?: TAddPointsCustomerPaths;
  params?: TAddPointsCustomerParams;
  body?: TAddPointsCustomerBody;
};

export type TAddPointsCustomerResponse = unknown;

// FUNCTION

export const addPointsCustomer = async ({
  paths,
  params,
  body,
}: TAddPointsCustomerMaterials): Promise<TAddPointsCustomerResponse> => {
  const response = await ApiService.post(`/admin/points/customer/${paths?.id}`, body, { params });
  return response.data;
};
