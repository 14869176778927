import { createActionCreator } from 'deox';

import { TLoginKakaoMaterials, TLoginKakaoResponse } from '@/services/api/talks/login-kakao';

// CONSTANTS

export enum ELoginKakaoAction {
  LOGIN_KAKAO = 'LOGIN_KAKAO',
  LOGIN_KAKAO_REQUEST = 'LOGIN_KAKAO_REQUEST',
  LOGIN_KAKAO_SUCCESS = 'LOGIN_KAKAO_SUCCESS',
  LOGIN_KAKAO_FAILED = 'LOGIN_KAKAO_FAILED',
}

// TYPES

export type TLoginKakaoRequest = {
  type: ELoginKakaoAction.LOGIN_KAKAO_REQUEST;
  payload: {
    materials: TLoginKakaoMaterials;
    successCallback?: (response: TLoginKakaoResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TLoginKakaoSuccess = {
  type: ELoginKakaoAction.LOGIN_KAKAO_SUCCESS;
  payload: { response: TLoginKakaoResponse };
};

export type TLoginKakaoFailed = { type: ELoginKakaoAction.LOGIN_KAKAO_FAILED };

// FUNCTION

export const loginKakaoAction = {
  request: createActionCreator(
    ELoginKakaoAction.LOGIN_KAKAO_REQUEST,
    (resolve) =>
      (
        materials: TLoginKakaoMaterials,
        successCallback?: (response: TLoginKakaoResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TLoginKakaoRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ELoginKakaoAction.LOGIN_KAKAO_SUCCESS,
    (resolve) =>
      (response: TLoginKakaoResponse): TLoginKakaoSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ELoginKakaoAction.LOGIN_KAKAO_FAILED,
    (resolve) =>
      (error: unknown): TLoginKakaoFailed =>
        resolve({ error }),
  ),
};
