import { createActionCreator } from 'deox';

import { TUpdateInquiryMaterials, TUpdateInquiryResponse } from '@/services/api/inquiry/update-inquiry';

// CONSTANTS

export enum EUpdateInquiryAction {
  UPDATE_INQUIRY = 'UPDATE_INQUIRY',
  UPDATE_INQUIRY_REQUEST = 'UPDATE_INQUIRY_REQUEST',
  UPDATE_INQUIRY_SUCCESS = 'UPDATE_INQUIRY_SUCCESS',
  UPDATE_INQUIRY_FAILED = 'UPDATE_INQUIRY_FAILED',
}

// TYPES

export type TUpdateInquiryRequest = {
  type: EUpdateInquiryAction.UPDATE_INQUIRY_REQUEST;
  payload: {
    materials: TUpdateInquiryMaterials;
    successCallback?: (response: TUpdateInquiryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateInquirySuccess = {
  type: EUpdateInquiryAction.UPDATE_INQUIRY_SUCCESS;
  payload: { response: TUpdateInquiryResponse };
};

export type TUpdateInquiryFailed = { type: EUpdateInquiryAction.UPDATE_INQUIRY_FAILED };

// FUNCTION

export const updateInquiryAction = {
  request: createActionCreator(
    EUpdateInquiryAction.UPDATE_INQUIRY_REQUEST,
    (resolve) =>
      (
        materials: TUpdateInquiryMaterials,
        successCallback?: (response: TUpdateInquiryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateInquiryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateInquiryAction.UPDATE_INQUIRY_SUCCESS,
    (resolve) =>
      (response: TUpdateInquiryResponse): TUpdateInquirySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateInquiryAction.UPDATE_INQUIRY_FAILED,
    (resolve) =>
      (error: unknown): TUpdateInquiryFailed =>
        resolve({ error }),
  ),
};
