import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getProceduresAction } from '@/redux/actions';
import { getProcedures, TGetProceduresResponse } from '@/services/api';

// FUNCTION

export function* getProceduresSaga(action: ActionType<typeof getProceduresAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getProcedures, materials);
    const getProceduresResponse: TGetProceduresResponse = response as TGetProceduresResponse;
    yield put(getProceduresAction.success(getProceduresResponse));
    successCallback?.(getProceduresResponse);
  } catch (err) {
    yield put(getProceduresAction.failure(err));
    failedCallback?.(err);
  }
}
