import { createActionCreator } from 'deox';

import { TSetKakaoChannelMaterials, TSetKakaoChannelResponse } from '@/services/api/talks/set-kakao-channel';

// CONSTANTS

export enum ESetKakaoChannelAction {
  SET_KAKAO_CHANNEL = 'SET_KAKAO_CHANNEL',
  SET_KAKAO_CHANNEL_REQUEST = 'SET_KAKAO_CHANNEL_REQUEST',
  SET_KAKAO_CHANNEL_SUCCESS = 'SET_KAKAO_CHANNEL_SUCCESS',
  SET_KAKAO_CHANNEL_FAILED = 'SET_KAKAO_CHANNEL_FAILED',
}

// TYPES

export type TSetKakaoChannelRequest = {
  type: ESetKakaoChannelAction.SET_KAKAO_CHANNEL_REQUEST;
  payload: {
    materials: TSetKakaoChannelMaterials;
    successCallback?: (response: TSetKakaoChannelResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TSetKakaoChannelSuccess = {
  type: ESetKakaoChannelAction.SET_KAKAO_CHANNEL_SUCCESS;
  payload: { response: TSetKakaoChannelResponse };
};

export type TSetKakaoChannelFailed = { type: ESetKakaoChannelAction.SET_KAKAO_CHANNEL_FAILED };

// FUNCTION

export const setKakaoChannelAction = {
  request: createActionCreator(
    ESetKakaoChannelAction.SET_KAKAO_CHANNEL_REQUEST,
    (resolve) =>
      (
        materials: TSetKakaoChannelMaterials,
        successCallback?: (response: TSetKakaoChannelResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TSetKakaoChannelRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ESetKakaoChannelAction.SET_KAKAO_CHANNEL_SUCCESS,
    (resolve) =>
      (response: TSetKakaoChannelResponse): TSetKakaoChannelSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ESetKakaoChannelAction.SET_KAKAO_CHANNEL_FAILED,
    (resolve) =>
      (error: unknown): TSetKakaoChannelFailed =>
        resolve({ error }),
  ),
};
