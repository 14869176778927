import { EIconName } from '@/components/Icon';
import { TNavbarItem } from '@/containers/Navbar/Navbar.types';
import { Paths } from '@/pages/routers';

export const dataNavbarMenu = (id?: string, branchesOptions?: TNavbarItem[]): TNavbarItem[] => [
  {
    activePaths: [Paths.Reservation(id)],
    link: Paths.Reservation(id),
    title: '예약 관리',
    icon: EIconName.Reservation,
    disabled: false,
    children: branchesOptions,
  },
  {
    activePaths: [Paths.Customers],
    link: Paths.Customers,
    title: '고객 관리',
    icon: EIconName.User,
    disabled: false,
  },
  {
    activePaths: [Paths.Sales],
    link: Paths.Sales,
    title: '매출 관리',
    icon: EIconName.Caculator,
    disabled: false,
  },
  {
    activePaths: [Paths.Procedures],
    link: Paths.Procedures,
    title: '시술 관리',
    icon: EIconName.Document,
    disabled: false,
  },
  {
    activePaths: [Paths.Tickets],
    link: Paths.Tickets,
    title: '티켓팅 관리',
    icon: EIconName.TicketDollar,
    disabled: false,
  },
  {
    activePaths: [Paths.Statistics],
    link: Paths.Statistics,
    title: '통계',
    icon: EIconName.Chart,
    disabled: false,
  },
  {
    activePaths: [Paths.ManagerTalk],
    link: Paths.ManagerTalk,
    title: '매니저톡',
    icon: EIconName.Chat,
    disabled: false,
  },
  {
    activePaths: [Paths.Others],
    link: Paths.Others,
    title: '기타 관리',
    icon: EIconName.Setting,
    disabled: false,
  },
  {
    activePaths: [Paths.Notice, Paths.NoticeDetail(id)],
    link: Paths.Notice,
    title: '직원 공지사항',
    icon: EIconName.Blink,
    disabled: false,
  },
];

export const dataSuperAdminNavbarMenu = (): TNavbarItem[] => [
  {
    activePaths: [Paths.Statistics],
    link: Paths.Statistics,
    title: '전지점 통계',
    disabled: false,
  },
  {
    activePaths: [Paths.StatisticsBranch],
    link: Paths.StatisticsBranch,
    title: '지점별 통계',
    disabled: false,
  },
  {
    activePaths: [Paths.NoticeManagement],
    link: Paths.NoticeManagement,
    title: '직원 공지사항',
    disabled: false,
  },
];
