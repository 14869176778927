import { TScheduleState } from '@/redux/reducers/schedule';
import { TGetBookingScheduleTodaySuccess } from '@/redux/actions/schedule';

export const getBookingScheduleTodayUpdateState = (
  state: TScheduleState,
  action: TGetBookingScheduleTodaySuccess,
): TScheduleState => ({
  ...state,
  getBookingScheduleTodayResponse: action.payload.response,
});
