import { createActionCreator } from 'deox';

import {
  TGetBookingScheduleTodayMaterials,
  TGetBookingScheduleTodayResponse,
} from '@/services/api/schedule/get-booking-schedule-today';

// CONSTANTS

export enum EGetBookingScheduleTodayAction {
  GET_BOOKING_SCHEDULE_TODAY = 'GET_BOOKING_SCHEDULE_TODAY',
  GET_BOOKING_SCHEDULE_TODAY_REQUEST = 'GET_BOOKING_SCHEDULE_TODAY_REQUEST',
  GET_BOOKING_SCHEDULE_TODAY_SUCCESS = 'GET_BOOKING_SCHEDULE_TODAY_SUCCESS',
  GET_BOOKING_SCHEDULE_TODAY_FAILED = 'GET_BOOKING_SCHEDULE_TODAY_FAILED',
}

// TYPES

export type TGetBookingScheduleTodayRequest = {
  type: EGetBookingScheduleTodayAction.GET_BOOKING_SCHEDULE_TODAY_REQUEST;
  payload: {
    materials: TGetBookingScheduleTodayMaterials;
    successCallback?: (response: TGetBookingScheduleTodayResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetBookingScheduleTodaySuccess = {
  type: EGetBookingScheduleTodayAction.GET_BOOKING_SCHEDULE_TODAY_SUCCESS;
  payload: { response: TGetBookingScheduleTodayResponse };
};

export type TGetBookingScheduleTodayFailed = { type: EGetBookingScheduleTodayAction.GET_BOOKING_SCHEDULE_TODAY_FAILED };

// FUNCTION

export const getBookingScheduleTodayAction = {
  request: createActionCreator(
    EGetBookingScheduleTodayAction.GET_BOOKING_SCHEDULE_TODAY_REQUEST,
    (resolve) =>
      (
        materials: TGetBookingScheduleTodayMaterials,
        successCallback?: (response: TGetBookingScheduleTodayResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetBookingScheduleTodayRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetBookingScheduleTodayAction.GET_BOOKING_SCHEDULE_TODAY_SUCCESS,
    (resolve) =>
      (response: TGetBookingScheduleTodayResponse): TGetBookingScheduleTodaySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetBookingScheduleTodayAction.GET_BOOKING_SCHEDULE_TODAY_FAILED,
    (resolve) =>
      (error: unknown): TGetBookingScheduleTodayFailed =>
        resolve({ error }),
  ),
};
