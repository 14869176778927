import { createReducer } from 'deox';

import {
  TCreateScheduleCustomerResponse,
  TCreateSchedulePersonalResponse,
  TDeleteScheduleResponse,
  TGetBookingScheduleTodayResponse,
  TGetScheduleResponse,
  TGetSchedulesResponse,
  TUpdateCustomerNoteResponse,
  TUpdateScheduleCustomerResponse,
  TUpdateSchedulePersonalResponse,
  TUpdateStatusScheduleResponse,
} from '@/services/api/schedule';
import {
  createScheduleCustomerAction,
  createSchedulePersonalAction,
  deleteScheduleAction,
  getBookingScheduleTodayAction,
  getScheduleAction,
  getSchedulesAction,
  updateCustomerNoteAction,
  updateScheduleCustomerAction,
  updateSchedulePersonalAction,
  updateStatusScheduleAction,
} from '@/redux/actions';
import { createScheduleCustomerUpdateState } from './create-schedule-customer';
import { createSchedulePersonalUpdateState } from './create-schedule-personal';
import { deleteScheduleUpdateState } from './delete-schedule';
import { getBookingScheduleTodayUpdateState } from './get-booking-schedule-today';
import { getScheduleUpdateState } from './get-schedule';
import { getSchedulesUpdateState } from './get-schedules';
import { updateCustomerNoteUpdateState } from './update-customer-note';
import { updateScheduleCustomerUpdateState } from './update-schedule-customer';
import { updateSchedulePersonalUpdateState } from './update-schedule-personal';
import { updateStatusScheduleUpdateState } from './update-status-schedule';

export type TScheduleState = {
  createScheduleCustomerResponse?: TCreateScheduleCustomerResponse;
  createSchedulePersonalResponse?: TCreateSchedulePersonalResponse;
  deleteScheduleResponse?: TDeleteScheduleResponse;
  getBookingScheduleTodayResponse?: TGetBookingScheduleTodayResponse;
  getScheduleResponse?: TGetScheduleResponse;
  getSchedulesResponse?: TGetSchedulesResponse;
  updateCustomerNoteResponse?: TUpdateCustomerNoteResponse;
  updateScheduleCustomerResponse?: TUpdateScheduleCustomerResponse;
  updateSchedulePersonalResponse?: TUpdateSchedulePersonalResponse;
  updateStatusScheduleResponse?: TUpdateStatusScheduleResponse;
};

const initialState: TScheduleState = {
  createScheduleCustomerResponse: undefined,
  createSchedulePersonalResponse: undefined,
  deleteScheduleResponse: undefined,
  getBookingScheduleTodayResponse: undefined,
  getScheduleResponse: undefined,
  getSchedulesResponse: undefined,
  updateCustomerNoteResponse: undefined,
  updateScheduleCustomerResponse: undefined,
  updateSchedulePersonalResponse: undefined,
  updateStatusScheduleResponse: undefined,
};

const ScheduleReducer = createReducer(initialState, (handleAction) => [
  handleAction(createScheduleCustomerAction.success, createScheduleCustomerUpdateState),
  handleAction(createSchedulePersonalAction.success, createSchedulePersonalUpdateState),
  handleAction(deleteScheduleAction.success, deleteScheduleUpdateState),
  handleAction(getBookingScheduleTodayAction.success, getBookingScheduleTodayUpdateState),
  handleAction(getScheduleAction.success, getScheduleUpdateState),
  handleAction(getSchedulesAction.success, getSchedulesUpdateState),
  handleAction(updateCustomerNoteAction.success, updateCustomerNoteUpdateState),
  handleAction(updateScheduleCustomerAction.success, updateScheduleCustomerUpdateState),
  handleAction(updateSchedulePersonalAction.success, updateSchedulePersonalUpdateState),
  handleAction(updateStatusScheduleAction.success, updateStatusScheduleUpdateState),
]);

export default ScheduleReducer;
