import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getStatisticsAction } from '@/redux/actions';
import { getStatistics, TGetStatisticsResponse } from '@/services/api';

// FUNCTION

export function* getStatisticsSaga(action: ActionType<typeof getStatisticsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getStatistics, materials);
    const getStatisticsResponse: TGetStatisticsResponse = response as TGetStatisticsResponse;
    yield put(getStatisticsAction.success(getStatisticsResponse));
    successCallback?.(getStatisticsResponse);
  } catch (err) {
    yield put(getStatisticsAction.failure(err));
    failedCallback?.(err);
  }
}
