import { TCustomerState } from '@/redux/reducers/customer';
import { TGetCustomerUsageSuccess } from '@/redux/actions/customer';

export const getCustomerUsageUpdateState = (
  state: TCustomerState,
  action: TGetCustomerUsageSuccess,
): TCustomerState => ({
  ...state,
  getCustomerUsageResponse: action.payload.response,
});
