import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateManagerTalksNotificationAction } from '@/redux/actions';
import { updateManagerTalksNotification, TUpdateManagerTalksNotificationResponse } from '@/services/api';

// FUNCTION

export function* updateManagerTalksNotificationSaga(
  action: ActionType<typeof updateManagerTalksNotificationAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateManagerTalksNotification, materials);
    const updateManagerTalksNotificationResponse: TUpdateManagerTalksNotificationResponse =
      response as TUpdateManagerTalksNotificationResponse;
    yield put(updateManagerTalksNotificationAction.success(updateManagerTalksNotificationResponse));
    successCallback?.(updateManagerTalksNotificationResponse);
  } catch (err) {
    yield put(updateManagerTalksNotificationAction.failure(err));
    failedCallback?.(err);
  }
}
