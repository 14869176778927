import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteCustomerAction } from '@/redux/actions';
import { deleteCustomer, TDeleteCustomerResponse } from '@/services/api';

// FUNCTION

export function* deleteCustomerSaga(action: ActionType<typeof deleteCustomerAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteCustomer, materials);
    const deleteCustomerResponse: TDeleteCustomerResponse = response as TDeleteCustomerResponse;
    yield put(deleteCustomerAction.success(deleteCustomerResponse));
    successCallback?.(deleteCustomerResponse);
  } catch (err) {
    yield put(deleteCustomerAction.failure(err));
    failedCallback?.(err);
  }
}
