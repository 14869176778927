import { createReducer } from 'deox';

import {
  TCreateStaffResponse,
  TDeleteStaffResponse,
  TGetStaffsResponse,
  TUpdateStaffResponse,
} from '@/services/api/staff';
import { createStaffAction, deleteStaffAction, getStaffsAction, updateStaffAction } from '@/redux/actions';
import { createStaffUpdateState } from './create-staff';
import { deleteStaffUpdateState } from './delete-staff';
import { getStaffsUpdateState } from './get-staffs';
import { updateStaffUpdateState } from './update-staff';

export type TStaffState = {
  createStaffResponse?: TCreateStaffResponse;
  deleteStaffResponse?: TDeleteStaffResponse;
  getStaffsResponse?: TGetStaffsResponse;
  updateStaffResponse?: TUpdateStaffResponse;
};

const initialState: TStaffState = {
  createStaffResponse: undefined,
  deleteStaffResponse: undefined,
  getStaffsResponse: undefined,
  updateStaffResponse: undefined,
};

const StaffReducer = createReducer(initialState, (handleAction) => [
  handleAction(createStaffAction.success, createStaffUpdateState),
  handleAction(deleteStaffAction.success, deleteStaffUpdateState),
  handleAction(getStaffsAction.success, getStaffsUpdateState),
  handleAction(updateStaffAction.success, updateStaffUpdateState),
]);

export default StaffReducer;
