import { TSchedule, TTicket } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetCustomerDetailPaths = {
  id: string | number;
};
export type TGetCustomerDetailParams = unknown;

export type TGetCustomerDetailMaterials = {
  paths?: TGetCustomerDetailPaths;
  params?: TGetCustomerDetailParams;
};

export type TGetCustomerDetailResponse = {
  iTickets: TTicket[];
  previousVisitSchedule?: TSchedule;
  totalPayComplete: number;
  totalSchedules: number;
  totalSchedulesCancel: number;
  totalSchedulesNoShow: number;
};

// FUNCTION

export const getCustomerDetail = async ({
  paths,
  params,
}: TGetCustomerDetailMaterials): Promise<TGetCustomerDetailResponse> => {
  const response = await ApiService.get(`/admin/customers/${paths?.id}/detail`, { params });
  return response.data;
};
