import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateSaleNoteAction } from '@/redux/actions';
import { updateSaleNote, TUpdateSaleNoteResponse } from '@/services/api';

// FUNCTION

export function* updateSaleNoteSaga(action: ActionType<typeof updateSaleNoteAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateSaleNote, materials);
    const updateSaleNoteResponse: TUpdateSaleNoteResponse = response as TUpdateSaleNoteResponse;
    yield put(updateSaleNoteAction.success(updateSaleNoteResponse));
    successCallback?.(updateSaleNoteResponse);
  } catch (err) {
    yield put(updateSaleNoteAction.failure(err));
    failedCallback?.(err);
  }
}
