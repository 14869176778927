import { createActionCreator } from 'deox';

import { TCreateNoticeMaterials, TCreateNoticeResponse } from '@/services/api/notice/create-notice';

// CONSTANTS

export enum ECreateNoticeAction {
  CREATE_NOTICE = 'CREATE_NOTICE',
  CREATE_NOTICE_REQUEST = 'CREATE_NOTICE_REQUEST',
  CREATE_NOTICE_SUCCESS = 'CREATE_NOTICE_SUCCESS',
  CREATE_NOTICE_FAILED = 'CREATE_NOTICE_FAILED',
}

// TYPES

export type TCreateNoticeRequest = {
  type: ECreateNoticeAction.CREATE_NOTICE_REQUEST;
  payload: {
    materials: TCreateNoticeMaterials;
    successCallback?: (response: TCreateNoticeResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateNoticeSuccess = {
  type: ECreateNoticeAction.CREATE_NOTICE_SUCCESS;
  payload: { response: TCreateNoticeResponse };
};

export type TCreateNoticeFailed = { type: ECreateNoticeAction.CREATE_NOTICE_FAILED };

// FUNCTION

export const createNoticeAction = {
  request: createActionCreator(
    ECreateNoticeAction.CREATE_NOTICE_REQUEST,
    (resolve) =>
      (
        materials: TCreateNoticeMaterials,
        successCallback?: (response: TCreateNoticeResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateNoticeRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateNoticeAction.CREATE_NOTICE_SUCCESS,
    (resolve) =>
      (response: TCreateNoticeResponse): TCreateNoticeSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateNoticeAction.CREATE_NOTICE_FAILED,
    (resolve) =>
      (error: unknown): TCreateNoticeFailed =>
        resolve({ error }),
  ),
};
