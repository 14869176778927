import { createActionCreator } from 'deox';

import {
  TGetStatisticsBranchesMaterials,
  TGetStatisticsBranchesResponse,
} from '@/services/api/statistic/get-statistics-branches';

// CONSTANTS

export enum EGetStatisticsBranchesAction {
  GET_STATISTICS_BRANCHES = 'GET_STATISTICS_BRANCHES',
  GET_STATISTICS_BRANCHES_REQUEST = 'GET_STATISTICS_BRANCHES_REQUEST',
  GET_STATISTICS_BRANCHES_SUCCESS = 'GET_STATISTICS_BRANCHES_SUCCESS',
  GET_STATISTICS_BRANCHES_FAILED = 'GET_STATISTICS_BRANCHES_FAILED',
}

// TYPES

export type TGetStatisticsBranchesRequest = {
  type: EGetStatisticsBranchesAction.GET_STATISTICS_BRANCHES_REQUEST;
  payload: {
    materials: TGetStatisticsBranchesMaterials;
    successCallback?: (response: TGetStatisticsBranchesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetStatisticsBranchesSuccess = {
  type: EGetStatisticsBranchesAction.GET_STATISTICS_BRANCHES_SUCCESS;
  payload: { response: TGetStatisticsBranchesResponse };
};

export type TGetStatisticsBranchesFailed = { type: EGetStatisticsBranchesAction.GET_STATISTICS_BRANCHES_FAILED };

// FUNCTION

export const getStatisticsBranchesAction = {
  request: createActionCreator(
    EGetStatisticsBranchesAction.GET_STATISTICS_BRANCHES_REQUEST,
    (resolve) =>
      (
        materials: TGetStatisticsBranchesMaterials,
        successCallback?: (response: TGetStatisticsBranchesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetStatisticsBranchesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetStatisticsBranchesAction.GET_STATISTICS_BRANCHES_SUCCESS,
    (resolve) =>
      (response: TGetStatisticsBranchesResponse): TGetStatisticsBranchesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetStatisticsBranchesAction.GET_STATISTICS_BRANCHES_FAILED,
    (resolve) =>
      (error: unknown): TGetStatisticsBranchesFailed =>
        resolve({ error }),
  ),
};
