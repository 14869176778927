import ApiService from '@/services/api';

// TYPES

export type TUpdateProcedurePaths = {
  id: string | number;
};
export type TUpdateProcedureBody = {
  treatment?: string;
  name?: string;
  price?: number;
  procedureTime?: number;
  nextBookingTerm?: number;
};

export type TUpdateProcedureMaterials = {
  paths?: TUpdateProcedurePaths;
  body?: TUpdateProcedureBody;
};

export type TUpdateProcedureResponse = unknown;

// FUNCTION

export const updateProcedure = async ({
  paths,
  body,
}: TUpdateProcedureMaterials): Promise<TUpdateProcedureResponse> => {
  const response = await ApiService.patch(`/admin/procedures/${paths?.id}`, body);
  return response.data;
};
