import { createActionCreator } from 'deox';

import {
  TUpdateStatusScheduleMaterials,
  TUpdateStatusScheduleResponse,
} from '@/services/api/schedule/update-status-schedule';

// CONSTANTS

export enum EUpdateStatusScheduleAction {
  UPDATE_STATUS_SCHEDULE = 'UPDATE_STATUS_SCHEDULE',
  UPDATE_STATUS_SCHEDULE_REQUEST = 'UPDATE_STATUS_SCHEDULE_REQUEST',
  UPDATE_STATUS_SCHEDULE_SUCCESS = 'UPDATE_STATUS_SCHEDULE_SUCCESS',
  UPDATE_STATUS_SCHEDULE_FAILED = 'UPDATE_STATUS_SCHEDULE_FAILED',
}

// TYPES

export type TUpdateStatusScheduleRequest = {
  type: EUpdateStatusScheduleAction.UPDATE_STATUS_SCHEDULE_REQUEST;
  payload: {
    materials: TUpdateStatusScheduleMaterials;
    successCallback?: (response: TUpdateStatusScheduleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateStatusScheduleSuccess = {
  type: EUpdateStatusScheduleAction.UPDATE_STATUS_SCHEDULE_SUCCESS;
  payload: { response?: TUpdateStatusScheduleResponse };
};

export type TUpdateStatusScheduleFailed = { type: EUpdateStatusScheduleAction.UPDATE_STATUS_SCHEDULE_FAILED };

// FUNCTION

export const updateStatusScheduleAction = {
  request: createActionCreator(
    EUpdateStatusScheduleAction.UPDATE_STATUS_SCHEDULE_REQUEST,
    (resolve) =>
      (
        materials: TUpdateStatusScheduleMaterials,
        successCallback?: (response: TUpdateStatusScheduleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateStatusScheduleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateStatusScheduleAction.UPDATE_STATUS_SCHEDULE_SUCCESS,
    (resolve) =>
      (response?: TUpdateStatusScheduleResponse): TUpdateStatusScheduleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateStatusScheduleAction.UPDATE_STATUS_SCHEDULE_FAILED,
    (resolve) =>
      (error: unknown): TUpdateStatusScheduleFailed =>
        resolve({ error }),
  ),
};
