import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { setKakaoChannelAction } from '@/redux/actions';
import { setKakaoChannel, TSetKakaoChannelResponse } from '@/services/api';

// FUNCTION

export function* setKakaoChannelSaga(action: ActionType<typeof setKakaoChannelAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(setKakaoChannel, materials);
    const setKakaoChannelResponse: TSetKakaoChannelResponse = response as TSetKakaoChannelResponse;
    yield put(setKakaoChannelAction.success(setKakaoChannelResponse));
    successCallback?.(setKakaoChannelResponse);
  } catch (err) {
    yield put(setKakaoChannelAction.failure(err));
    failedCallback?.(err);
  }
}
