import { combineReducers } from 'redux';

import { loadingReducer, errorReducer, successReducer } from './status';
import authReducer from './auth';
import branchReducer from './branch';
import customerReducer from './customer';
import employeeReducer from './employee';
import inquiryReducer from './inquiry';
import noticeReducer from './notice';
import pointReducer from './point';
import procedureReducer from './procedure';
import saleReducer from './sale';
import salesSettingReducer from './sales-setting';
import scheduleReducer from './schedule';
import staffReducer from './staff';
import statisticReducer from './statistic';
import talksReducer from './talks';
import ticketReducer from './ticket';
import treatmentReducer from './treatment';
import uiReducer from './ui';

const rootReducer = combineReducers({
  loadingReducer,
  errorReducer,
  successReducer,
  authReducer,
  branchReducer,
  customerReducer,
  employeeReducer,
  inquiryReducer,
  noticeReducer,
  pointReducer,
  procedureReducer,
  saleReducer,
  salesSettingReducer,
  scheduleReducer,
  staffReducer,
  statisticReducer,
  talksReducer,
  ticketReducer,
  treatmentReducer,
  uiReducer,
});

export default rootReducer;

export type TRootState = ReturnType<typeof rootReducer>;
