import { TTalksState } from '@/redux/reducers/talks';
import { TGetManagerTalksSettingsSuccess } from '@/redux/actions/talks';

export const getManagerTalksSettingsUpdateState = (
  state: TTalksState,
  action: TGetManagerTalksSettingsSuccess,
): TTalksState => ({
  ...state,
  getManagerTalksSettingsResponse: action.payload.response,
});
