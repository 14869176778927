import { TStatisticEmployee } from '@/common/models';
import { TPaginationResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetStatisticsEmployeesParams = {
  fromDate?: string;
  toDate?: string;
  page: number;
  pageSize: number;
  search?: string;
};

export type TGetStatisticsEmployeesMaterials = {
  params?: TGetStatisticsEmployeesParams;
  isSuperAdmin?: boolean;
};

export type TGetStatisticsEmployeesResponse = {
  pagination: TPaginationResponse;
  data: TStatisticEmployee[];
};

// FUNCTION

export const getStatisticsEmployees = async ({
  params,
  isSuperAdmin,
}: TGetStatisticsEmployeesMaterials): Promise<TGetStatisticsEmployeesResponse> => {
  const response = await ApiService.get(`${isSuperAdmin ? '/management' : ''}/statistics/employees`, { params });
  return response.data;
};
