import { createActionCreator } from 'deox';

import {
  TSaveManagerTalkSettingMaterials,
  TSaveManagerTalkSettingResponse,
} from '@/services/api/talks/save-manager-talk-setting';

// CONSTANTS

export enum ESaveManagerTalkSettingAction {
  SAVE_MANAGER_TALK_SETTING = 'SAVE_MANAGER_TALK_SETTING',
  SAVE_MANAGER_TALK_SETTING_REQUEST = 'SAVE_MANAGER_TALK_SETTING_REQUEST',
  SAVE_MANAGER_TALK_SETTING_SUCCESS = 'SAVE_MANAGER_TALK_SETTING_SUCCESS',
  SAVE_MANAGER_TALK_SETTING_FAILED = 'SAVE_MANAGER_TALK_SETTING_FAILED',
}

// TYPES

export type TSaveManagerTalkSettingRequest = {
  type: ESaveManagerTalkSettingAction.SAVE_MANAGER_TALK_SETTING_REQUEST;
  payload: {
    materials: TSaveManagerTalkSettingMaterials;
    successCallback?: (response: TSaveManagerTalkSettingResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TSaveManagerTalkSettingSuccess = {
  type: ESaveManagerTalkSettingAction.SAVE_MANAGER_TALK_SETTING_SUCCESS;
  payload: { response: TSaveManagerTalkSettingResponse };
};

export type TSaveManagerTalkSettingFailed = { type: ESaveManagerTalkSettingAction.SAVE_MANAGER_TALK_SETTING_FAILED };

// FUNCTION

export const saveManagerTalkSettingAction = {
  request: createActionCreator(
    ESaveManagerTalkSettingAction.SAVE_MANAGER_TALK_SETTING_REQUEST,
    (resolve) =>
      (
        materials: TSaveManagerTalkSettingMaterials,
        successCallback?: (response: TSaveManagerTalkSettingResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TSaveManagerTalkSettingRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ESaveManagerTalkSettingAction.SAVE_MANAGER_TALK_SETTING_SUCCESS,
    (resolve) =>
      (response: TSaveManagerTalkSettingResponse): TSaveManagerTalkSettingSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ESaveManagerTalkSettingAction.SAVE_MANAGER_TALK_SETTING_FAILED,
    (resolve) =>
      (error: unknown): TSaveManagerTalkSettingFailed =>
        resolve({ error }),
  ),
};
