import { createReducer } from 'deox';

import { TGetAdminBranchResponse, TGetBranchesResponse } from '@/services/api/branch';
import { getAdminBranchAction, getBranchesAction } from '@/redux/actions';
import { getAdminBranchUpdateState } from './get-admin-branch';
import { getBranchesUpdateState } from './get-branches';

export type TBranchState = {
  getAdminBranchResponse?: TGetAdminBranchResponse;
  getBranchesResponse?: TGetBranchesResponse;
};

const initialState: TBranchState = {
  getAdminBranchResponse: undefined,
  getBranchesResponse: undefined,
};

const BranchReducer = createReducer(initialState, (handleAction) => [
  handleAction(getAdminBranchAction.success, getAdminBranchUpdateState),
  handleAction(getBranchesAction.success, getBranchesUpdateState),
]);

export default BranchReducer;
