import { createActionCreator } from 'deox';

import { TGetNoticesMaterials, TGetNoticesResponse } from '@/services/api/notice/get-notices';

// CONSTANTS

export enum EGetNoticesAction {
  GET_NOTICES = 'GET_NOTICES',
  GET_NOTICES_REQUEST = 'GET_NOTICES_REQUEST',
  GET_NOTICES_SUCCESS = 'GET_NOTICES_SUCCESS',
  GET_NOTICES_FAILED = 'GET_NOTICES_FAILED',
}

// TYPES

export type TGetNoticesRequest = {
  type: EGetNoticesAction.GET_NOTICES_REQUEST;
  payload: {
    materials: TGetNoticesMaterials;
    successCallback?: (response: TGetNoticesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetNoticesSuccess = {
  type: EGetNoticesAction.GET_NOTICES_SUCCESS;
  payload: { response: TGetNoticesResponse };
};

export type TGetNoticesFailed = { type: EGetNoticesAction.GET_NOTICES_FAILED };

// FUNCTION

export const getNoticesAction = {
  request: createActionCreator(
    EGetNoticesAction.GET_NOTICES_REQUEST,
    (resolve) =>
      (
        materials: TGetNoticesMaterials,
        successCallback?: (response: TGetNoticesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetNoticesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetNoticesAction.GET_NOTICES_SUCCESS,
    (resolve) =>
      (response: TGetNoticesResponse): TGetNoticesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetNoticesAction.GET_NOTICES_FAILED,
    (resolve) =>
      (error: unknown): TGetNoticesFailed =>
        resolve({ error }),
  ),
};
