import { TScheduleState } from '@/redux/reducers/schedule';
import { TCreateScheduleCustomerSuccess } from '@/redux/actions/schedule';

export const createScheduleCustomerUpdateState = (
  state: TScheduleState,
  action: TCreateScheduleCustomerSuccess,
): TScheduleState => ({
  ...state,
  createScheduleCustomerResponse: action.payload.response,
});
