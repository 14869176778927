import { EProcedureShortHandType } from '@/common/enums';
import { TProcedure } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetProceduresParams = {
  typeFilter: EProcedureShortHandType;
};

export type TGetProceduresMaterials = {
  params?: TGetProceduresParams;
};

export type TGetProceduresResponse = TProcedure[];

// FUNCTION

export const getProcedures = async ({ params }: TGetProceduresMaterials): Promise<TGetProceduresResponse> => {
  const response = await ApiService.get(`/admin/procedures`, { params });
  return response.data;
};
