import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="4" width="7" height="16" rx="2" fill={color} className="fill" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4C9.89543 4 9 4.89543 9 6V18C9 19.1046 9.89543 20 11 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4H11ZM15.9001 7H15.1692V7.90892C14.6975 7.94299 14.2649 8.03553 13.8713 8.18653C13.363 8.37785 12.964 8.65379 12.6742 9.01435C12.3885 9.37123 12.2457 9.78146 12.2457 10.245C12.2415 10.8006 12.4494 11.2531 12.8695 11.6026C13.2895 11.9522 13.8902 12.2171 14.6715 12.3974L15.1692 12.5083V14.8695C14.9576 14.8486 14.7601 14.8082 14.577 14.7483C14.2997 14.6564 14.075 14.5166 13.9028 14.3289C13.7306 14.1413 13.634 13.9113 13.613 13.6391H12C12.0252 14.1615 12.1827 14.6085 12.4725 14.9801C12.7666 15.3481 13.174 15.6313 13.6949 15.83C14.1237 15.9906 14.6151 16.0838 15.1692 16.1096V17H15.9001V16.1083C16.4689 16.0804 16.9688 15.9858 17.3996 15.8245C17.9205 15.6258 18.3174 15.3481 18.5905 14.9912C18.8635 14.6343 19 14.2204 19 13.7494C18.9958 13.1056 18.748 12.6034 18.2565 12.2428C17.7693 11.8823 17.1224 11.6192 16.3159 11.4536L15.9001 11.3619V9.12961C16.0944 9.14897 16.2729 9.18597 16.4356 9.24062C16.6877 9.32156 16.8851 9.44481 17.0279 9.61038C17.1749 9.77594 17.261 9.97093 17.2862 10.1954H18.874C18.8656 9.75018 18.7228 9.35283 18.4455 9.00331C18.1683 8.65379 17.7819 8.38153 17.2862 8.18653C16.8725 8.02376 16.4104 7.92892 15.9001 7.90203V7ZM15.1692 9.13616C14.9915 9.15755 14.8298 9.19421 14.6841 9.24614C14.4362 9.33444 14.243 9.45953 14.1044 9.62141C13.97 9.77962 13.9028 9.9599 13.9028 10.1623C13.9028 10.4639 14.0456 10.6994 14.3312 10.8687C14.557 10.9976 14.8364 11.1065 15.1692 11.1953V9.13616ZM15.9001 12.6734V14.8673C16.1103 14.8451 16.3035 14.8036 16.4797 14.7428C16.757 14.6472 16.9712 14.5129 17.1224 14.34C17.2736 14.1634 17.3513 13.9628 17.3555 13.7384C17.3513 13.5397 17.2841 13.3705 17.1539 13.2307C17.0279 13.0909 16.8452 12.9768 16.6058 12.8885C16.4129 12.8131 16.1777 12.7414 15.9001 12.6734Z"
        fill={color}
        className="fill"
      />
    </svg>
  );
};

export default Svg;
