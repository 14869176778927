import ApiService from '@/services/api';

// TYPES

export type TUpdateInquiryPaths = {
  id: string | number;
};
export type TUpdateInquiryBody = {
  replyMessage: string;
};

export type TUpdateInquiryMaterials = {
  paths?: TUpdateInquiryPaths;
  body?: TUpdateInquiryBody;
};

export type TUpdateInquiryResponse = unknown;

// FUNCTION

export const updateInquiry = async ({ paths, body }: TUpdateInquiryMaterials): Promise<TUpdateInquiryResponse> => {
  const response = await ApiService.patch(`/admin/inquiries/${paths?.id}`, body);
  return response.data;
};
