import ApiService from '@/services/api';

// TYPES

export type TUpdateSalePaths = {
  id: string | number;
};
export type TUpdateSaleBody = {
  branch?: string;
  customer?: string;
  procedure?: {
    quantity?: number;
    procedure?: string;
  }[];
  employee?: string[];
  ticket?: string[];
  totalAmount?: number;
  discountPrice?: number;
  total?: number;
  amountPoint?: number;
  amountCard?: number;
  amountCash?: number;
  type?: string;
  createdAt?: string;
  note?: string;
};

export type TUpdateSaleMaterials = {
  paths?: TUpdateSalePaths;
  body?: TUpdateSaleBody;
};

export type TUpdateSaleResponse = unknown;

// FUNCTION

export const updateSale = async ({ paths, body }: TUpdateSaleMaterials): Promise<TUpdateSaleResponse> => {
  const response = await ApiService.patch(`/admin/sales/${paths?.id}`, body);
  return response.data;
};
