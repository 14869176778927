import { ERole } from '@/common/enums';
import { TBranch } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export enum EPermissionsBranches {
  ONE = 'ONE',
  ALL = 'ALL',
}

export type TGetProfileParams = unknown;

export type TGetProfileMaterials = {
  params?: TGetProfileParams;
};

export type TGetProfileResponse = {
  accountId: string;
  id: string;
  name: string;
  branch: TBranch;
  role: ERole;
  permissionsBranches: EPermissionsBranches;
};

// FUNCTION

export const getProfile = async ({ params }: TGetProfileMaterials): Promise<TGetProfileResponse> => {
  const response = await ApiService.get(`/admin/auth/profile`, { params });
  return response.data;
};
