import { TOpenHour } from '@/common/models';
import ApiService, { TSettingTime } from '@/services/api';

// TYPES

export type TGetSalesSettingPaths = {
  branchid: string | number;
};
export type TGetSalesSettingParams = {
  getNextDays?: number;
  date?: string;
};

export type TGetSalesSettingMaterials = {
  paths?: TGetSalesSettingPaths;
  params?: TGetSalesSettingParams;
};

export type TGetSalesSettingResponse = {
  settingTime: TSettingTime[];
  reNewSetTime: { [key: string]: { [key: string]: TSettingTime } };
  openHour: TOpenHour[];
};

// FUNCTION

export const getSalesSetting = async ({
  paths,
  params,
}: TGetSalesSettingMaterials): Promise<TGetSalesSettingResponse> => {
  const response = await ApiService.get(`/admin/sales/setting/${paths?.branchid}`, { params });
  return response.data;
};
