import ApiService from '@/services/api';

// TYPES

export type TCreateProcedureParams = unknown;
export type TCreateProcedureBody = {
  treatment: string;
  name: string;
  price: number;
  procedureTime: number;
  nextBookingTerm: number;
};

export type TCreateProcedureMaterials = {
  params?: TCreateProcedureParams;
  body?: TCreateProcedureBody;
};

export type TCreateProcedureResponse = unknown;

// FUNCTION

export const createProcedure = async ({
  params,
  body,
}: TCreateProcedureMaterials): Promise<TCreateProcedureResponse> => {
  const response = await ApiService.post(`/admin/procedures`, body, { params });
  return response.data;
};
