import { createActionCreator } from 'deox';

import { TUpdateSaleMaterials, TUpdateSaleResponse } from '@/services/api/sale/update-sale';

// CONSTANTS

export enum EUpdateSaleAction {
  UPDATE_SALE = 'UPDATE_SALE',
  UPDATE_SALE_REQUEST = 'UPDATE_SALE_REQUEST',
  UPDATE_SALE_SUCCESS = 'UPDATE_SALE_SUCCESS',
  UPDATE_SALE_FAILED = 'UPDATE_SALE_FAILED',
}

// TYPES

export type TUpdateSaleRequest = {
  type: EUpdateSaleAction.UPDATE_SALE_REQUEST;
  payload: {
    materials: TUpdateSaleMaterials;
    successCallback?: (response: TUpdateSaleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateSaleSuccess = {
  type: EUpdateSaleAction.UPDATE_SALE_SUCCESS;
  payload: { response: TUpdateSaleResponse };
};

export type TUpdateSaleFailed = { type: EUpdateSaleAction.UPDATE_SALE_FAILED };

// FUNCTION

export const updateSaleAction = {
  request: createActionCreator(
    EUpdateSaleAction.UPDATE_SALE_REQUEST,
    (resolve) =>
      (
        materials: TUpdateSaleMaterials,
        successCallback?: (response: TUpdateSaleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateSaleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateSaleAction.UPDATE_SALE_SUCCESS,
    (resolve) =>
      (response: TUpdateSaleResponse): TUpdateSaleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateSaleAction.UPDATE_SALE_FAILED,
    (resolve) =>
      (error: unknown): TUpdateSaleFailed =>
        resolve({ error }),
  ),
};
