import { createActionCreator } from 'deox';

import { TGetNoticeStaffMaterials, TGetNoticeStaffResponse } from '@/services/api/notice/get-notice-staff';

// CONSTANTS

export enum EGetNoticeStaffAction {
  GET_NOTICE_STAFF = 'GET_NOTICE_STAFF',
  GET_NOTICE_STAFF_REQUEST = 'GET_NOTICE_STAFF_REQUEST',
  GET_NOTICE_STAFF_SUCCESS = 'GET_NOTICE_STAFF_SUCCESS',
  GET_NOTICE_STAFF_FAILED = 'GET_NOTICE_STAFF_FAILED',
}

// TYPES

export type TGetNoticeStaffRequest = {
  type: EGetNoticeStaffAction.GET_NOTICE_STAFF_REQUEST;
  payload: {
    materials: TGetNoticeStaffMaterials;
    successCallback?: (response: TGetNoticeStaffResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetNoticeStaffSuccess = {
  type: EGetNoticeStaffAction.GET_NOTICE_STAFF_SUCCESS;
  payload: { response: TGetNoticeStaffResponse };
};

export type TGetNoticeStaffFailed = { type: EGetNoticeStaffAction.GET_NOTICE_STAFF_FAILED };

// FUNCTION

export const getNoticeStaffAction = {
  request: createActionCreator(
    EGetNoticeStaffAction.GET_NOTICE_STAFF_REQUEST,
    (resolve) =>
      (
        materials: TGetNoticeStaffMaterials,
        successCallback?: (response: TGetNoticeStaffResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetNoticeStaffRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetNoticeStaffAction.GET_NOTICE_STAFF_SUCCESS,
    (resolve) =>
      (response: TGetNoticeStaffResponse): TGetNoticeStaffSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetNoticeStaffAction.GET_NOTICE_STAFF_FAILED,
    (resolve) =>
      (error: unknown): TGetNoticeStaffFailed =>
        resolve({ error }),
  ),
};
