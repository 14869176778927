import ApiService from '@/services/api';

// TYPES

export type TGetStatisticsBranchPaths = {
  id: string | number;
};
export type TGetStatisticsBranchParams = unknown;

export type TGetStatisticsBranchMaterials = {
  paths?: TGetStatisticsBranchPaths;
  params?: TGetStatisticsBranchParams;
};

export type TGetStatisticsBranchResponse = {
  date: string;
  totalDailySales: string;
}[];

// FUNCTION

export const getStatisticsBranch = async ({
  paths,
  params,
}: TGetStatisticsBranchMaterials): Promise<TGetStatisticsBranchResponse> => {
  const response = await ApiService.get(`/management/statistics/branch/${paths?.id}/detail`, { params });
  return response.data;
};
