import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getSalesAction } from '@/redux/actions';
import { getSales, TGetSalesResponse } from '@/services/api';

// FUNCTION

export function* getSalesSaga(action: ActionType<typeof getSalesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getSales, materials);
    const getSalesResponse: TGetSalesResponse = response as TGetSalesResponse;
    yield put(getSalesAction.success(getSalesResponse));
    successCallback?.(getSalesResponse);
  } catch (err) {
    yield put(getSalesAction.failure(err));
    failedCallback?.(err);
  }
}
