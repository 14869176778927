import { createActionCreator } from 'deox';

import { TDeleteStaffMaterials, TDeleteStaffResponse } from '@/services/api/staff/delete-staff';

// CONSTANTS

export enum EDeleteStaffAction {
  DELETE_STAFF = 'DELETE_STAFF',
  DELETE_STAFF_REQUEST = 'DELETE_STAFF_REQUEST',
  DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS',
  DELETE_STAFF_FAILED = 'DELETE_STAFF_FAILED',
}

// TYPES

export type TDeleteStaffRequest = {
  type: EDeleteStaffAction.DELETE_STAFF_REQUEST;
  payload: {
    materials: TDeleteStaffMaterials;
    successCallback?: (response: TDeleteStaffResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteStaffSuccess = {
  type: EDeleteStaffAction.DELETE_STAFF_SUCCESS;
  payload: { response: TDeleteStaffResponse };
};

export type TDeleteStaffFailed = { type: EDeleteStaffAction.DELETE_STAFF_FAILED };

// FUNCTION

export const deleteStaffAction = {
  request: createActionCreator(
    EDeleteStaffAction.DELETE_STAFF_REQUEST,
    (resolve) =>
      (
        materials: TDeleteStaffMaterials,
        successCallback?: (response: TDeleteStaffResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteStaffRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteStaffAction.DELETE_STAFF_SUCCESS,
    (resolve) =>
      (response: TDeleteStaffResponse): TDeleteStaffSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteStaffAction.DELETE_STAFF_FAILED,
    (resolve) =>
      (error: unknown): TDeleteStaffFailed =>
        resolve({ error }),
  ),
};
