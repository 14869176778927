import { EDayOfWeek } from '@/common/enums';

/* eslint-disable no-useless-escape */
export const regex = {
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
  url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
  domain: /^[a-zA-Z0-9](?:[a-zA-Z0-9-.]*[a-zA-Z0-9])?$/i,
  alphabetic: /^[a-z\s]+$/i,
  alphanumerial: /^[a-z0-9\s]+$/i,
  numeric: /^\d+$/i,
  onlySpecialKey: /[$&+,:;=?@#|'<>.^*()%`~_!\-"\]\[\\}{'/]/g,
};

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;

export const dataDayOfWeek = {
  'SUNDAY': EDayOfWeek.SUNDAY,
  'MONDAY': EDayOfWeek.MONDAY,
  'TUESDAY': EDayOfWeek.TUESDAY,
  'WEDNESDAY': EDayOfWeek.WEDNESDAY,
  'THURSDAY': EDayOfWeek.THURSDAY,
  'FRIDAY': EDayOfWeek.FRIDAY,
  'SATURDAY': EDayOfWeek.SATURDAY,
};
