import { TScheduleState } from '@/redux/reducers/schedule';
import { TUpdateCustomerNoteSuccess } from '@/redux/actions/schedule';

export const updateCustomerNoteUpdateState = (
  state: TScheduleState,
  action: TUpdateCustomerNoteSuccess,
): TScheduleState => ({
  ...state,
  updateCustomerNoteResponse: action.payload.response,
});
