import { createActionCreator } from 'deox';

import { TGetSalesSettingMaterials, TGetSalesSettingResponse } from '@/services/api/sales-setting/get-sales-setting';

// CONSTANTS

export enum EGetSalesSettingAction {
  GET_SALES_SETTING = 'GET_SALES_SETTING',
  GET_SALES_SETTING_REQUEST = 'GET_SALES_SETTING_REQUEST',
  GET_SALES_SETTING_SUCCESS = 'GET_SALES_SETTING_SUCCESS',
  GET_SALES_SETTING_FAILED = 'GET_SALES_SETTING_FAILED',
}

// TYPES

export type TGetSalesSettingRequest = {
  type: EGetSalesSettingAction.GET_SALES_SETTING_REQUEST;
  payload: {
    materials: TGetSalesSettingMaterials;
    successCallback?: (response: TGetSalesSettingResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetSalesSettingSuccess = {
  type: EGetSalesSettingAction.GET_SALES_SETTING_SUCCESS;
  payload: { response?: TGetSalesSettingResponse };
};

export type TGetSalesSettingFailed = { type: EGetSalesSettingAction.GET_SALES_SETTING_FAILED };

// FUNCTION

export const getSalesSettingAction = {
  request: createActionCreator(
    EGetSalesSettingAction.GET_SALES_SETTING_REQUEST,
    (resolve) =>
      (
        materials: TGetSalesSettingMaterials,
        successCallback?: (response: TGetSalesSettingResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetSalesSettingRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetSalesSettingAction.GET_SALES_SETTING_SUCCESS,
    (resolve) =>
      (response?: TGetSalesSettingResponse): TGetSalesSettingSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetSalesSettingAction.GET_SALES_SETTING_FAILED,
    (resolve) =>
      (error: unknown): TGetSalesSettingFailed =>
        resolve({ error }),
  ),
};
