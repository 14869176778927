import env from '@/env';
import AuthorizedInstance from '@/services/authorized-api';

const ApiService = AuthorizedInstance(env.api.baseUrl.service);

export default ApiService;
export * from './auth';
export * from './customer';
export * from './notice';
export * from './schedule';
export * from './procedure';
export * from './employee';
export * from './ticket';
export * from './treatment';
export * from './sales-setting';
export * from './inquiry';
export * from './staff';
export * from './sale';
export * from './statistic';
export * from './talks';
export * from './branch';
export * from './point';
