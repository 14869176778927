import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3C4.68629 3 2 5.68629 2 9V13C2 16.3137 4.68629 19 8 19H16C19.3137 19 22 16.3137 22 13V9C22 5.68629 19.3137 3 16 3H8ZM7 12C7.55228 12 8 11.5523 8 11C8 10.4477 7.55228 10 7 10C6.44772 10 6 10.4477 6 11C6 11.5523 6.44772 12 7 12ZM13 11C13 11.5523 12.5523 12 12 12C11.4477 12 11 11.5523 11 11C11 10.4477 11.4477 10 12 10C12.5523 10 13 10.4477 13 11ZM17 12C17.5523 12 18 11.5523 18 11C18 10.4477 17.5523 10 17 10C16.4477 10 16 10.4477 16 11C16 11.5523 16.4477 12 17 12Z"
        fill={color}
        className="fill"
      />
      <path
        d="M2 13C2 19.2391 7.91194 21.6829 10.5145 21.9707C10.6922 21.9903 10.7875 21.7758 10.6804 21.6326C9.875 20.556 9.875 19.2222 9.875 19.2222V18.6667L2 13Z"
        fill={color}
        className="fill"
      />
    </svg>
  );
};

export default Svg;
