import { createActionCreator } from 'deox';

import {
  TSetManagerTalksKakaoChannelMaterials,
  TSetManagerTalksKakaoChannelResponse,
} from '@/services/api/talks/set-manager-talks-kakao-channel';

// CONSTANTS

export enum ESetManagerTalksKakaoChannelAction {
  SET_MANAGER_TALKS_KAKAO_CHANNEL = 'SET_MANAGER_TALKS_KAKAO_CHANNEL',
  SET_MANAGER_TALKS_KAKAO_CHANNEL_REQUEST = 'SET_MANAGER_TALKS_KAKAO_CHANNEL_REQUEST',
  SET_MANAGER_TALKS_KAKAO_CHANNEL_SUCCESS = 'SET_MANAGER_TALKS_KAKAO_CHANNEL_SUCCESS',
  SET_MANAGER_TALKS_KAKAO_CHANNEL_FAILED = 'SET_MANAGER_TALKS_KAKAO_CHANNEL_FAILED',
}

// TYPES

export type TSetManagerTalksKakaoChannelRequest = {
  type: ESetManagerTalksKakaoChannelAction.SET_MANAGER_TALKS_KAKAO_CHANNEL_REQUEST;
  payload: {
    materials: TSetManagerTalksKakaoChannelMaterials;
    successCallback?: (response: TSetManagerTalksKakaoChannelResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TSetManagerTalksKakaoChannelSuccess = {
  type: ESetManagerTalksKakaoChannelAction.SET_MANAGER_TALKS_KAKAO_CHANNEL_SUCCESS;
  payload: { response: TSetManagerTalksKakaoChannelResponse };
};

export type TSetManagerTalksKakaoChannelFailed = {
  type: ESetManagerTalksKakaoChannelAction.SET_MANAGER_TALKS_KAKAO_CHANNEL_FAILED;
};

// FUNCTION

export const setManagerTalksKakaoChannelAction = {
  request: createActionCreator(
    ESetManagerTalksKakaoChannelAction.SET_MANAGER_TALKS_KAKAO_CHANNEL_REQUEST,
    (resolve) =>
      (
        materials: TSetManagerTalksKakaoChannelMaterials,
        successCallback?: (response: TSetManagerTalksKakaoChannelResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TSetManagerTalksKakaoChannelRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ESetManagerTalksKakaoChannelAction.SET_MANAGER_TALKS_KAKAO_CHANNEL_SUCCESS,
    (resolve) =>
      (response: TSetManagerTalksKakaoChannelResponse): TSetManagerTalksKakaoChannelSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ESetManagerTalksKakaoChannelAction.SET_MANAGER_TALKS_KAKAO_CHANNEL_FAILED,
    (resolve) =>
      (error: unknown): TSetManagerTalksKakaoChannelFailed =>
        resolve({ error }),
  ),
};
