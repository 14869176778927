import { createActionCreator } from 'deox';

import { TDisconnectKakaoMaterials, TDisconnectKakaoResponse } from '@/services/api/talks/disconnect-kakao';

// CONSTANTS

export enum EDisconnectKakaoAction {
  DISCONNECT_KAKAO = 'DISCONNECT_KAKAO',
  DISCONNECT_KAKAO_REQUEST = 'DISCONNECT_KAKAO_REQUEST',
  DISCONNECT_KAKAO_SUCCESS = 'DISCONNECT_KAKAO_SUCCESS',
  DISCONNECT_KAKAO_FAILED = 'DISCONNECT_KAKAO_FAILED',
}

// TYPES

export type TDisconnectKakaoRequest = {
  type: EDisconnectKakaoAction.DISCONNECT_KAKAO_REQUEST;
  payload: {
    materials: TDisconnectKakaoMaterials;
    successCallback?: (response: TDisconnectKakaoResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDisconnectKakaoSuccess = {
  type: EDisconnectKakaoAction.DISCONNECT_KAKAO_SUCCESS;
  payload: { response: TDisconnectKakaoResponse };
};

export type TDisconnectKakaoFailed = { type: EDisconnectKakaoAction.DISCONNECT_KAKAO_FAILED };

// FUNCTION

export const disconnectKakaoAction = {
  request: createActionCreator(
    EDisconnectKakaoAction.DISCONNECT_KAKAO_REQUEST,
    (resolve) =>
      (
        materials: TDisconnectKakaoMaterials,
        successCallback?: (response: TDisconnectKakaoResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDisconnectKakaoRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDisconnectKakaoAction.DISCONNECT_KAKAO_SUCCESS,
    (resolve) =>
      (response: TDisconnectKakaoResponse): TDisconnectKakaoSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDisconnectKakaoAction.DISCONNECT_KAKAO_FAILED,
    (resolve) =>
      (error: unknown): TDisconnectKakaoFailed =>
        resolve({ error }),
  ),
};
