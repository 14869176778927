import { all, takeLatest } from 'redux-saga/effects';

import { getAdminBranchAction, getBranchesAction } from '@/redux/actions';

import { getAdminBranchSaga } from './get-admin-branch';
import { getBranchesSaga } from './get-branches';

export default function* root(): Generator {
  yield all([
    takeLatest(getAdminBranchAction.request.type, getAdminBranchSaga),
    takeLatest(getBranchesAction.request.type, getBranchesSaga),
  ]);
}
