import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getStatisticsBranchesAction } from '@/redux/actions';
import { getStatisticsBranches, TGetStatisticsBranchesResponse } from '@/services/api';

// FUNCTION

export function* getStatisticsBranchesSaga(action: ActionType<typeof getStatisticsBranchesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getStatisticsBranches, materials);
    const getStatisticsBranchesResponse: TGetStatisticsBranchesResponse = response as TGetStatisticsBranchesResponse;
    yield put(getStatisticsBranchesAction.success(getStatisticsBranchesResponse));
    successCallback?.(getStatisticsBranchesResponse);
  } catch (err) {
    yield put(getStatisticsBranchesAction.failure(err));
    failedCallback?.(err);
  }
}
