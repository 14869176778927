import { all, takeLatest } from 'redux-saga/effects';

import {
  cancelSalePaymentAction,
  createSaleAction,
  getSaleAction,
  getSalesAction,
  updateSaleNoteAction,
  updateSaleAction,
} from '@/redux/actions';

import { cancelSalePaymentSaga } from './cancel-sale-payment';
import { createSaleSaga } from './create-sale';
import { getSaleSaga } from './get-sale';
import { getSalesSaga } from './get-sales';
import { updateSaleNoteSaga } from './update-sale-note';
import { updateSaleSaga } from './update-sale';

export default function* root(): Generator {
  yield all([
    takeLatest(cancelSalePaymentAction.request.type, cancelSalePaymentSaga),
    takeLatest(createSaleAction.request.type, createSaleSaga),
    takeLatest(getSaleAction.request.type, getSaleSaga),
    takeLatest(getSalesAction.request.type, getSalesSaga),
    takeLatest(updateSaleNoteAction.request.type, updateSaleNoteSaga),
    takeLatest(updateSaleAction.request.type, updateSaleSaga),
  ]);
}
