import { ERole } from '@/common/enums';
import ApiService from '@/services/api';

// TYPES

export type TAdminLoginParams = {
  accountId: string;
  password: string;
};
export type TAdminLoginBody = unknown;

export type TAdminLoginMaterials = {
  params?: TAdminLoginParams;
  body?: TAdminLoginBody;
};

export type TAdminLoginResponse = {
  jwtAccessToken: string;
  employees: {
    id: string;
    accountId: string;
    name: string;
    role: ERole;
    password: string;
    salt: string;
    createdAt: string;
    updatedAt: string;
  };
};

// FUNCTION

export const adminLogin = async ({ params, body }: TAdminLoginMaterials): Promise<TAdminLoginResponse> => {
  const response = await ApiService.post(`/admin/auth/signin`, body, { params });
  return response.data;
};
