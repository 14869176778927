export enum EIconName {
  Reservation = 'Reservation',
  User = 'User',
  Blink = 'Blink',
  Caculator = 'Caculator',
  Chart = 'Chart',
  Chat = 'Chat',
  Document = 'Document',
  Setting = 'Setting',
  TicketDollar = 'TicketDollar',
  Home = 'Home',
  DoubleAngleLeft = 'DoubleAngleLeft',
  Filter = 'Filter',
  Search = 'Search',
  Plus = 'Plus',
  X = 'X',
  Reload = 'Reload',
  AngleDown = 'AngleDown',
  AngleLeft = 'AngleLeft',
  AngleRight = 'AngleRight',
  ThreeDots = 'ThreeDots',
  Trash = 'Trash',
  Edit = 'Edit',
  Phone = 'Phone',
  Note = 'Note',
  AddCircle = 'AddCircle',
  CheckCircle = 'CheckCircle',
  CircleX = 'CircleX',
  Info = 'Info',
  Minus = 'Minus',
}

export enum EIconColor {
  BLACK = '#000',
  WHITE = '#fff',
  JON = '#3C1E1E',

  BACKGROUND = '#F9F9F9',

  SUCCESS = '#1BD693',
  ERROR = '#FF4F4F',

  GRAY_09 = '#1A1A1A',
  GRAY_08 = '#333333',
  GRAY_07 = '#4C4C4C',
  GRAY_06 = '#666666',
  GRAY_05 = '#808080',
  GRAY_04 = '#999999',
  GRAY_03 = '#b3b3b3',
  GRAY_02 = '#CCCCCC',
  GRAY_01 = '#E5E5E5',

  RED_07 = '#D61438',
  RED_06 = '#ED3658',
  RED_05 = '#FF5F7C',

  BLUE_07 = '#6B7AFF',
  BLUE_04 = '#DADEFF',
  BLUE_03 = '#E9EBFF',

  YELLOW_07 = '#FDC025',
  YELLOW_02 = '#FFF8E8',
}
