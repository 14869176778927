import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateInquiryAction } from '@/redux/actions';
import { updateInquiry, TUpdateInquiryResponse } from '@/services/api';

// FUNCTION

export function* updateInquirySaga(action: ActionType<typeof updateInquiryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateInquiry, materials);
    const updateInquiryResponse: TUpdateInquiryResponse = response as TUpdateInquiryResponse;
    yield put(updateInquiryAction.success(updateInquiryResponse));
    successCallback?.(updateInquiryResponse);
  } catch (err) {
    yield put(updateInquiryAction.failure(err));
    failedCallback?.(err);
  }
}
