import { all, takeLatest } from 'redux-saga/effects';

import {
  createNoticeAction,
  deleteNoticeAction,
  getNoticeStaffAction,
  getNoticeAction,
  getNoticesStaffAction,
  getNoticesAction,
  updateNoticeAction,
} from '@/redux/actions';

import { createNoticeSaga } from './create-notice';
import { deleteNoticeSaga } from './delete-notice';
import { getNoticeStaffSaga } from './get-notice-staff';
import { getNoticeSaga } from './get-notice';
import { getNoticesStaffSaga } from './get-notices-staff';
import { getNoticesSaga } from './get-notices';
import { updateNoticeSaga } from './update-notice';

export default function* root(): Generator {
  yield all([
    takeLatest(createNoticeAction.request.type, createNoticeSaga),
    takeLatest(deleteNoticeAction.request.type, deleteNoticeSaga),
    takeLatest(getNoticeStaffAction.request.type, getNoticeStaffSaga),
    takeLatest(getNoticeAction.request.type, getNoticeSaga),
    takeLatest(getNoticesStaffAction.request.type, getNoticesStaffSaga),
    takeLatest(getNoticesAction.request.type, getNoticesSaga),
    takeLatest(updateNoticeAction.request.type, updateNoticeSaga),
  ]);
}
