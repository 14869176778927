import ApiService from '@/services/api';

// TYPES

export type TUpdateSaleNotePaths = {
  id: string | number;
};
export type TUpdateSaleNoteBody = {
  note: string;
};

export type TUpdateSaleNoteMaterials = {
  paths?: TUpdateSaleNotePaths;
  body?: TUpdateSaleNoteBody;
};

export type TUpdateSaleNoteResponse = unknown;

// FUNCTION

export const updateSaleNote = async ({ paths, body }: TUpdateSaleNoteMaterials): Promise<TUpdateSaleNoteResponse> => {
  const response = await ApiService.patch(`/admin/sales/${paths?.id}/note`, body);
  return response.data;
};
