import { all, fork } from 'redux-saga/effects';

import authSaga from './auth';
import branchSaga from './branch';
import customerSaga from './customer';
import employeeSaga from './employee';
import inquirySaga from './inquiry';
import noticeSaga from './notice';
import pointSaga from './point';
import procedureSaga from './procedure';
import saleSaga from './sale';
import salesSettingSaga from './sales-setting';
import scheduleSaga from './schedule';
import staffSaga from './staff';
import statisticSaga from './statistic';
import talksSaga from './talks';
import ticketSaga from './ticket';
import treatmentSaga from './treatment';

const rootSaga = function* root(): Generator {
  yield all([
    fork(authSaga),
    fork(branchSaga),
    fork(customerSaga),
    fork(employeeSaga),
    fork(inquirySaga),
    fork(noticeSaga),
    fork(pointSaga),
    fork(procedureSaga),
    fork(saleSaga),
    fork(salesSettingSaga),
    fork(scheduleSaga),
    fork(staffSaga),
    fork(statisticSaga),
    fork(talksSaga),
    fork(ticketSaga),
    fork(treatmentSaga),
  ]);
};

export default rootSaga;
