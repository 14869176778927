import { createActionCreator } from 'deox';

import { TUpdateCustomerMaterials, TUpdateCustomerResponse } from '@/services/api/customer/update-customer';

// CONSTANTS

export enum EUpdateCustomerAction {
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
  UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST',
  UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_FAILED = 'UPDATE_CUSTOMER_FAILED',
}

// TYPES

export type TUpdateCustomerRequest = {
  type: EUpdateCustomerAction.UPDATE_CUSTOMER_REQUEST;
  payload: {
    materials: TUpdateCustomerMaterials;
    successCallback?: (response: TUpdateCustomerResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateCustomerSuccess = {
  type: EUpdateCustomerAction.UPDATE_CUSTOMER_SUCCESS;
  payload: { response: TUpdateCustomerResponse };
};

export type TUpdateCustomerFailed = { type: EUpdateCustomerAction.UPDATE_CUSTOMER_FAILED };

// FUNCTION

export const updateCustomerAction = {
  request: createActionCreator(
    EUpdateCustomerAction.UPDATE_CUSTOMER_REQUEST,
    (resolve) =>
      (
        materials: TUpdateCustomerMaterials,
        successCallback?: (response: TUpdateCustomerResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateCustomerRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateCustomerAction.UPDATE_CUSTOMER_SUCCESS,
    (resolve) =>
      (response: TUpdateCustomerResponse): TUpdateCustomerSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateCustomerAction.UPDATE_CUSTOMER_FAILED,
    (resolve) =>
      (error: unknown): TUpdateCustomerFailed =>
        resolve({ error }),
  ),
};
