import { TSalesSettingState } from '@/redux/reducers/sales-setting';
import { TUpdateSalesSettingSuccess } from '@/redux/actions/sales-setting';

export const updateSalesSettingUpdateState = (
  state: TSalesSettingState,
  action: TUpdateSalesSettingSuccess,
): TSalesSettingState => ({
  ...state,
  updateSalesSettingResponse: action.payload.response,
});
