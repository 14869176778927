import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getSaleAction } from '@/redux/actions';
import { getSale, TGetSaleResponse } from '@/services/api';

// FUNCTION

export function* getSaleSaga(action: ActionType<typeof getSaleAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getSale, materials);
    const getSaleResponse: TGetSaleResponse = response as TGetSaleResponse;
    yield put(getSaleAction.success(getSaleResponse));
    successCallback?.(getSaleResponse);
  } catch (err) {
    yield put(getSaleAction.failure(err));
    failedCallback?.(err);
  }
}
