import { TTreatment } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetTreatmentsParams = {
  page: number;
  pageSize: number;
};

export type TGetTreatmentsMaterials = {
  params?: TGetTreatmentsParams;
};

export type TGetTreatmentsResponse = TTreatment[];

// FUNCTION

export const getTreatments = async ({ params }: TGetTreatmentsMaterials): Promise<TGetTreatmentsResponse> => {
  const response = await ApiService.get(`/admin/treatment`, { params });
  return response.data;
};
