import { createActionCreator } from 'deox';

import { TUpdateNoticeMaterials, TUpdateNoticeResponse } from '@/services/api/notice/update-notice';

// CONSTANTS

export enum EUpdateNoticeAction {
  UPDATE_NOTICE = 'UPDATE_NOTICE',
  UPDATE_NOTICE_REQUEST = 'UPDATE_NOTICE_REQUEST',
  UPDATE_NOTICE_SUCCESS = 'UPDATE_NOTICE_SUCCESS',
  UPDATE_NOTICE_FAILED = 'UPDATE_NOTICE_FAILED',
}

// TYPES

export type TUpdateNoticeRequest = {
  type: EUpdateNoticeAction.UPDATE_NOTICE_REQUEST;
  payload: {
    materials: TUpdateNoticeMaterials;
    successCallback?: (response: TUpdateNoticeResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateNoticeSuccess = {
  type: EUpdateNoticeAction.UPDATE_NOTICE_SUCCESS;
  payload: { response: TUpdateNoticeResponse };
};

export type TUpdateNoticeFailed = { type: EUpdateNoticeAction.UPDATE_NOTICE_FAILED };

// FUNCTION

export const updateNoticeAction = {
  request: createActionCreator(
    EUpdateNoticeAction.UPDATE_NOTICE_REQUEST,
    (resolve) =>
      (
        materials: TUpdateNoticeMaterials,
        successCallback?: (response: TUpdateNoticeResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateNoticeRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateNoticeAction.UPDATE_NOTICE_SUCCESS,
    (resolve) =>
      (response: TUpdateNoticeResponse): TUpdateNoticeSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateNoticeAction.UPDATE_NOTICE_FAILED,
    (resolve) =>
      (error: unknown): TUpdateNoticeFailed =>
        resolve({ error }),
  ),
};
