import { createActionCreator } from 'deox';

import {
  TGetManagerTalksSettingsMaterials,
  TGetManagerTalksSettingsResponse,
} from '@/services/api/talks/get-manager-talks-settings';

// CONSTANTS

export enum EGetManagerTalksSettingsAction {
  GET_MANAGER_TALKS_SETTINGS = 'GET_MANAGER_TALKS_SETTINGS',
  GET_MANAGER_TALKS_SETTINGS_REQUEST = 'GET_MANAGER_TALKS_SETTINGS_REQUEST',
  GET_MANAGER_TALKS_SETTINGS_SUCCESS = 'GET_MANAGER_TALKS_SETTINGS_SUCCESS',
  GET_MANAGER_TALKS_SETTINGS_FAILED = 'GET_MANAGER_TALKS_SETTINGS_FAILED',
}

// TYPES

export type TGetManagerTalksSettingsRequest = {
  type: EGetManagerTalksSettingsAction.GET_MANAGER_TALKS_SETTINGS_REQUEST;
  payload: {
    materials: TGetManagerTalksSettingsMaterials;
    successCallback?: (response: TGetManagerTalksSettingsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetManagerTalksSettingsSuccess = {
  type: EGetManagerTalksSettingsAction.GET_MANAGER_TALKS_SETTINGS_SUCCESS;
  payload: { response: TGetManagerTalksSettingsResponse };
};

export type TGetManagerTalksSettingsFailed = { type: EGetManagerTalksSettingsAction.GET_MANAGER_TALKS_SETTINGS_FAILED };

// FUNCTION

export const getManagerTalksSettingsAction = {
  request: createActionCreator(
    EGetManagerTalksSettingsAction.GET_MANAGER_TALKS_SETTINGS_REQUEST,
    (resolve) =>
      (
        materials: TGetManagerTalksSettingsMaterials,
        successCallback?: (response: TGetManagerTalksSettingsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetManagerTalksSettingsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetManagerTalksSettingsAction.GET_MANAGER_TALKS_SETTINGS_SUCCESS,
    (resolve) =>
      (response: TGetManagerTalksSettingsResponse): TGetManagerTalksSettingsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetManagerTalksSettingsAction.GET_MANAGER_TALKS_SETTINGS_FAILED,
    (resolve) =>
      (error: unknown): TGetManagerTalksSettingsFailed =>
        resolve({ error }),
  ),
};
