import { createActionCreator } from 'deox';

import {
  TUpdateScheduleCustomerMaterials,
  TUpdateScheduleCustomerResponse,
} from '@/services/api/schedule/update-schedule-customer';

// CONSTANTS

export enum EUpdateScheduleCustomerAction {
  UPDATE_SCHEDULE_CUSTOMER = 'UPDATE_SCHEDULE_CUSTOMER',
  UPDATE_SCHEDULE_CUSTOMER_REQUEST = 'UPDATE_SCHEDULE_CUSTOMER_REQUEST',
  UPDATE_SCHEDULE_CUSTOMER_SUCCESS = 'UPDATE_SCHEDULE_CUSTOMER_SUCCESS',
  UPDATE_SCHEDULE_CUSTOMER_FAILED = 'UPDATE_SCHEDULE_CUSTOMER_FAILED',
}

// TYPES

export type TUpdateScheduleCustomerRequest = {
  type: EUpdateScheduleCustomerAction.UPDATE_SCHEDULE_CUSTOMER_REQUEST;
  payload: {
    materials: TUpdateScheduleCustomerMaterials;
    successCallback?: (response: TUpdateScheduleCustomerResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateScheduleCustomerSuccess = {
  type: EUpdateScheduleCustomerAction.UPDATE_SCHEDULE_CUSTOMER_SUCCESS;
  payload: { response: TUpdateScheduleCustomerResponse };
};

export type TUpdateScheduleCustomerFailed = { type: EUpdateScheduleCustomerAction.UPDATE_SCHEDULE_CUSTOMER_FAILED };

// FUNCTION

export const updateScheduleCustomerAction = {
  request: createActionCreator(
    EUpdateScheduleCustomerAction.UPDATE_SCHEDULE_CUSTOMER_REQUEST,
    (resolve) =>
      (
        materials: TUpdateScheduleCustomerMaterials,
        successCallback?: (response: TUpdateScheduleCustomerResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateScheduleCustomerRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateScheduleCustomerAction.UPDATE_SCHEDULE_CUSTOMER_SUCCESS,
    (resolve) =>
      (response: TUpdateScheduleCustomerResponse): TUpdateScheduleCustomerSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateScheduleCustomerAction.UPDATE_SCHEDULE_CUSTOMER_FAILED,
    (resolve) =>
      (error: unknown): TUpdateScheduleCustomerFailed =>
        resolve({ error }),
  ),
};
