import { createActionCreator } from 'deox';

import { TGetTreatmentsMaterials, TGetTreatmentsResponse } from '@/services/api/treatment/get-treatments';

// CONSTANTS

export enum EGetTreatmentsAction {
  GET_TREATMENTS = 'GET_TREATMENTS',
  GET_TREATMENTS_REQUEST = 'GET_TREATMENTS_REQUEST',
  GET_TREATMENTS_SUCCESS = 'GET_TREATMENTS_SUCCESS',
  GET_TREATMENTS_FAILED = 'GET_TREATMENTS_FAILED',
}

// TYPES

export type TGetTreatmentsRequest = {
  type: EGetTreatmentsAction.GET_TREATMENTS_REQUEST;
  payload: {
    materials: TGetTreatmentsMaterials;
    successCallback?: (response: TGetTreatmentsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTreatmentsSuccess = {
  type: EGetTreatmentsAction.GET_TREATMENTS_SUCCESS;
  payload: { response: TGetTreatmentsResponse };
};

export type TGetTreatmentsFailed = { type: EGetTreatmentsAction.GET_TREATMENTS_FAILED };

// FUNCTION

export const getTreatmentsAction = {
  request: createActionCreator(
    EGetTreatmentsAction.GET_TREATMENTS_REQUEST,
    (resolve) =>
      (
        materials: TGetTreatmentsMaterials,
        successCallback?: (response: TGetTreatmentsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTreatmentsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetTreatmentsAction.GET_TREATMENTS_SUCCESS,
    (resolve) =>
      (response: TGetTreatmentsResponse): TGetTreatmentsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetTreatmentsAction.GET_TREATMENTS_FAILED,
    (resolve) =>
      (error: unknown): TGetTreatmentsFailed =>
        resolve({ error }),
  ),
};
