import ApiService from '@/services/api';

// TYPES

export type TDeleteInquiryPaths = {
  id: string | number;
};
export type TDeleteInquiryParams = unknown;

export type TDeleteInquiryMaterials = {
  paths?: TDeleteInquiryPaths;
  params?: TDeleteInquiryParams;
};

export type TDeleteInquiryResponse = unknown;

// FUNCTION

export const deleteInquiry = async ({ paths, params }: TDeleteInquiryMaterials): Promise<TDeleteInquiryResponse> => {
  const response = await ApiService.delete(`/admin/inquiries/${paths?.id}`, { params });
  return response.data;
};
