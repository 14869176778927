import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createCustomerAction } from '@/redux/actions';
import { createCustomer, TCreateCustomerResponse } from '@/services/api';

// FUNCTION

export function* createCustomerSaga(action: ActionType<typeof createCustomerAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createCustomer, materials);
    const createCustomerResponse: TCreateCustomerResponse = response as TCreateCustomerResponse;
    yield put(createCustomerAction.success(createCustomerResponse));
    successCallback?.(createCustomerResponse);
  } catch (err) {
    yield put(createCustomerAction.failure(err));
    failedCallback?.(err);
  }
}
