import { createReducer } from 'deox';

import { TAddPointsCustomerResponse } from '@/services/api/point';
import { addPointsCustomerAction } from '@/redux/actions';
import { addPointsCustomerUpdateState } from './add-points-customer';

export type TPointState = {
  addPointsCustomerResponse?: TAddPointsCustomerResponse;
};

const initialState: TPointState = {
  addPointsCustomerResponse: undefined,
};

const PointReducer = createReducer(initialState, (handleAction) => [
  handleAction(addPointsCustomerAction.success, addPointsCustomerUpdateState),
]);

export default PointReducer;
