import { createReducer } from 'deox';

import { TCreateTicketResponse, TDeleteTicketResponse, TGetTicketsResponse } from '@/services/api/ticket';
import { createTicketAction, deleteTicketAction, getTicketsAction } from '@/redux/actions';
import { createTicketUpdateState } from './create-ticket';
import { deleteTicketUpdateState } from './delete-ticket';
import { getTicketsUpdateState } from './get-tickets';

export type TTicketState = {
  createTicketResponse?: TCreateTicketResponse;
  deleteTicketResponse?: TDeleteTicketResponse;
  getTicketsResponse?: TGetTicketsResponse;
};

const initialState: TTicketState = {
  createTicketResponse: undefined,
  deleteTicketResponse: undefined,
  getTicketsResponse: undefined,
};

const TicketReducer = createReducer(initialState, (handleAction) => [
  handleAction(createTicketAction.success, createTicketUpdateState),
  handleAction(deleteTicketAction.success, deleteTicketUpdateState),
  handleAction(getTicketsAction.success, getTicketsUpdateState),
]);

export default TicketReducer;
