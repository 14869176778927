import { TProcedureState } from '@/redux/reducers/procedure';
import { TDeleteProcedureSuccess } from '@/redux/actions/procedure';

export const deleteProcedureUpdateState = (
  state: TProcedureState,
  action: TDeleteProcedureSuccess,
): TProcedureState => ({
  ...state,
  deleteProcedureResponse: action.payload.response,
});
