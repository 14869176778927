import { TCustomer, TPaginationResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetCustomersParams = {
  page: number;
  pageSize: number;
  search?: string;
  sortBy?: string;
  startLastVisitDate?: string;
  endLastVisitDate?: string;
  startBirthdayDate?: string;
  endBirthdayDate?: string;
  numberOfVisits?: number;
  startSale?: number;
  endSale?: number;
  isBuyer?: boolean;
  gender?: string;
  startAge?: number;
  endAge?: number;
};

export type TGetCustomersMaterials = {
  params?: TGetCustomersParams;
};

export type TGetCustomersResponse = {
  data: TCustomer[];
  pagination: TPaginationResponse;
};

// FUNCTION

export const getCustomers = async ({ params }: TGetCustomersMaterials): Promise<TGetCustomersResponse> => {
  const response = await ApiService.get(`/admin/customers`, { params });
  return response.data;
};
