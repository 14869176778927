import { TTalksNotification } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetManagerTalksNotificationPaths = {
  id: string | number;
};
export type TGetManagerTalksNotificationParams = unknown;

export type TGetManagerTalksNotificationMaterials = {
  paths?: TGetManagerTalksNotificationPaths;
  params?: TGetManagerTalksNotificationParams;
};

export type TGetManagerTalksNotificationResponse = {
  notification: TTalksNotification;
  previousProcedure: TTalksNotification;
};

// FUNCTION

export const getManagerTalksNotification = async ({
  paths,
  params,
}: TGetManagerTalksNotificationMaterials): Promise<TGetManagerTalksNotificationResponse> => {
  const response = await ApiService.get(`/admin/talks/schedules/noti/${paths?.id}`, { params });
  return response.data;
};
