import { createActionCreator } from 'deox';

import { TGetSaleMaterials, TGetSaleResponse } from '@/services/api/sale/get-sale';

// CONSTANTS

export enum EGetSaleAction {
  GET_SALE = 'GET_SALE',
  GET_SALE_REQUEST = 'GET_SALE_REQUEST',
  GET_SALE_SUCCESS = 'GET_SALE_SUCCESS',
  GET_SALE_FAILED = 'GET_SALE_FAILED',
}

// TYPES

export type TGetSaleRequest = {
  type: EGetSaleAction.GET_SALE_REQUEST;
  payload: {
    materials: TGetSaleMaterials;
    successCallback?: (response: TGetSaleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetSaleSuccess = {
  type: EGetSaleAction.GET_SALE_SUCCESS;
  payload: { response?: TGetSaleResponse };
};

export type TGetSaleFailed = { type: EGetSaleAction.GET_SALE_FAILED };

// FUNCTION

export const getSaleAction = {
  request: createActionCreator(
    EGetSaleAction.GET_SALE_REQUEST,
    (resolve) =>
      (
        materials: TGetSaleMaterials,
        successCallback?: (response: TGetSaleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetSaleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetSaleAction.GET_SALE_SUCCESS,
    (resolve) =>
      (response?: TGetSaleResponse): TGetSaleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetSaleAction.GET_SALE_FAILED,
    (resolve) =>
      (error: unknown): TGetSaleFailed =>
        resolve({ error }),
  ),
};
