import { TNotice } from '@/common/models';
import { TPaginationResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetNoticesStaffParams = {
  search?: string;
  page: number;
  pageSize: number;
};

export type TGetNoticesStaffMaterials = {
  params?: TGetNoticesStaffParams;
};

export type TGetNoticesStaffResponse = {
  data: TNotice[];
  pagination: TPaginationResponse;
};

// FUNCTION

export const getNoticesStaff = async ({ params }: TGetNoticesStaffMaterials): Promise<TGetNoticesStaffResponse> => {
  const response = await ApiService.get(`/admin/notices/staffs`, { params });
  return response.data;
};
