import { TSchedule } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetSchedulePaths = {
  id: string | number;
};
export type TGetScheduleParams = unknown;

export type TGetScheduleMaterials = {
  paths?: TGetSchedulePaths;
  params?: TGetScheduleParams;
};

export type TGetScheduleResponse = TSchedule;

// FUNCTION

export const getSchedule = async ({ paths, params }: TGetScheduleMaterials): Promise<TGetScheduleResponse> => {
  const response = await ApiService.get(`/admin/managements/schedules/${paths?.id}`, { params });
  return response.data;
};
