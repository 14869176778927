import { TStaff } from '@/common/models';
import { TPaginationResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetStaffsParams = {
  page: number;
  pageSize: number;
  search?: string;
};

export type TGetStaffsMaterials = {
  params?: TGetStaffsParams;
};

export type TGetStaffsResponse = {
  data: TStaff[];
  pagination: TPaginationResponse;
};

// FUNCTION

export const getStaffs = async ({ params }: TGetStaffsMaterials): Promise<TGetStaffsResponse> => {
  const response = await ApiService.get(`/staffs`, { params });
  return response.data;
};
