import { all, takeLatest } from 'redux-saga/effects';

import { adminLoginAction, getProfileAction } from '@/redux/actions';

import { adminLoginSaga } from './admin-login';
import { getProfileSaga } from './get-profile';

export default function* root(): Generator {
  yield all([
    takeLatest(adminLoginAction.request.type, adminLoginSaga),
    takeLatest(getProfileAction.request.type, getProfileSaga),
  ]);
}
