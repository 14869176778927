import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateCustomerNoteAction } from '@/redux/actions';
import { updateCustomerNote, TUpdateCustomerNoteResponse } from '@/services/api';

// FUNCTION

export function* updateCustomerNoteSaga(action: ActionType<typeof updateCustomerNoteAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateCustomerNote, materials);
    const updateCustomerNoteResponse: TUpdateCustomerNoteResponse = response as TUpdateCustomerNoteResponse;
    yield put(updateCustomerNoteAction.success(updateCustomerNoteResponse));
    successCallback?.(updateCustomerNoteResponse);
  } catch (err) {
    yield put(updateCustomerNoteAction.failure(err));
    failedCallback?.(err);
  }
}
