import ApiService from '@/services/api';

// TYPES

export type TCreateCustomerParams = unknown;
export type TCreateCustomerBody = FormData;

export type TCreateCustomerMaterials = {
  params?: TCreateCustomerParams;
  body?: TCreateCustomerBody;
};

export type TCreateCustomerResponse = unknown;

// FUNCTION

export const createCustomer = async ({ params, body }: TCreateCustomerMaterials): Promise<TCreateCustomerResponse> => {
  const response = await ApiService.post(`/admin/customers`, body, { params });
  return response.data;
};
