import { TStatistic } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetStatisticsParams = {
  fromDate: string;
  toDate: string;
  branch?: string;
};

export type TGetStatisticsMaterials = {
  params?: TGetStatisticsParams;
  isSuperAdmin?: boolean;
};

export type TGetStatisticsResponse = TStatistic;

// FUNCTION

export const getStatistics = async ({
  params,
  isSuperAdmin,
}: TGetStatisticsMaterials): Promise<TGetStatisticsResponse> => {
  const response = await ApiService.get(`${isSuperAdmin ? '/management' : ''}/statistics`, { params });
  return response.data;
};
