import ApiService from '@/services/api';

// TYPES

export type TCreateNoticeParams = unknown;
export type TCreateNoticeBody = FormData;

export type TCreateNoticeMaterials = {
  isSuperAdmin?: boolean;
  params?: TCreateNoticeParams;
  body?: TCreateNoticeBody;
};

export type TCreateNoticeResponse = unknown;

// FUNCTION

export const createNotice = async ({
  params,
  body,
  isSuperAdmin,
}: TCreateNoticeMaterials): Promise<TCreateNoticeResponse> => {
  const response = await ApiService.post(`/${isSuperAdmin ? 'management' : 'admin'}/notices`, body, { params });
  return response.data;
};
