export enum EResponseCode {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  MULTI_STATUS = 207,
  ALREADY_REPORTED = 208,
  I_AM_USED = 226,

  MULTIPLE_CHOICE = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,

  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  PAYLOAD_TOO_LARGE = 413,
  URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  I_AM_A_TEAPOT = 418,
  MISDIRECTED_REQUEST = 421,
  UNPROCESSABLE_ENTITY = 422,
  LOCKED = 423,
  FAILED_DEPENDENCY = 424,
  TOO_EARLY = 425,
  UPGRADE_REQUIRED = 426,
  PRECONDITION_REQUIRED = 428,
  TOO_MANY_REQUESTS = 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
  UNAVAILABLE_FOR_LEGAL_REASONS = 451,

  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
  VARIANT_ALSO_NEGOTIATES = 506,
  INSUFFICIENT_STORAGE = 507,
  LOOP_DETECTED = 508,
  NOT_EXTENDED = 510,
  NETWORK_AUTHENTICATION_REQUIRED = 511,
}

export enum ETypeNotification {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export enum ETimeoutDebounce {
  SEARCH = 200,
}

export enum EEmpty {
  STRIKE_THROUGH = '-',
  TEXT = '정보없음',
}

export enum EFormat {
  DATE_DDD = 'YYYY.MM.DD.(ddd)',
  DATE_DOT_WITHOUT_ROUND_BRACKET = 'YYYY.MM.DD.ddd',
  DATE_D = 'YYYY년 M월 D일 (dd)',
  DATE = 'YYYY.MM.DD',
  DATE_WITH_TEXT = 'YYYY년 M월 D일',
  YEAR = 'YYYY',
  DATE_DASH = 'YYYY-MM-DD',
  DATE_SLASH = 'YYYY/MM/DD',
  YEAR_MONTH = 'YYYY/MM',
  MONTH_DAY_SLASH = 'M/D',
  YEAR_MONTH_TEXT = 'YYYY년 M월',
  FULL_DATE_TIME = 'YYYY년 MM월 DD일 A h:mm',
  FULL = 'YYYY년 M월 D일 ddd A h:mm',
  FULL_TIME = 'HH:mm:ss',
  DATE_TIME = 'YYYY/MM/DD - HH:mm',
  DATE_TIME_DOT = 'YYYY.MM.DD. A hh:mm',
  DATE_DOT = 'YYYY.MM.DD.(dd)',
  DATE_DAY_OF_WEEK = 'M월 D일 (ddd)',
  TIME = 'A h시 mm분',
  TIME_COLON = 'HH:mm',
  TIME_COLON_HALF = 'hh:mm',
  PREFIX_TIME_COLON = 'A h:mm',
  PREFIX_FULL_TIME_COLON = 'A hh:mm',
  MINUTE = 'mm분',
  HOUR_MINUTE = 'HH시 mm분',
  MONTH_DAY = 'M월 D일',
  DAY_OF_WEEK = 'ddd',
  PREFIX = 'A',
  YEAR_MONTH_ROUND_BRACKET = 'YYYY (M건)',
}

export enum ERole {
  BRANCH_ADMIN = 'BRANCH_ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum EProcedureType {
  DAILY_CARE = 'DAILY_CARE',
  SPECIAL_CARE = 'SPECIAL_CARE',
  FIRST_VISIT = 'FIRST_VISIT',
  OTHER = 'OTHER',
}

export enum EProcedureShortHandType {
  DAILY = 'DAILY',
  SPECIAL = 'SPECIAL',
  OTHER = 'OTHER',
}

export enum EGender {
  MALE = 'Male',
  FEMALE = 'Female',
  NONE = 'None',
}

export enum EScheduleUserType {
  SCHEDULES_PERSONAL = 'SCHEDULES_PERSONAL',
  SCHEDULES_CUSTOMER = 'SCHEDULES_CUSTOMER',
}

export enum EScheduleType {
  NO_REPEAT = 'NO_REPEAT',
  REPEAT_DAILY = 'REPEAT_DAILY',
  REPEAT_WEEKLY = 'REPEAT_WEEKLY',
}

export enum EScheduleStatus {
  NO_SHOW = 'NO_SHOW',
  PROCESS = 'PROCESS',
  RESERVATION = 'RESERVATION',
  COMPLETE = 'COMPLETE',
  CANCEL = 'CANCEL',
}

export enum EStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum EDayOfWeek {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum EDayOfWeekString {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export enum ESalesType {
  PREPAY_TICKET_SALES = 'Prepay ticket sales',
  OTHER_SALES = 'Other sales',
  RESERVATION = 'Reservation',
}

export enum EPaymentMethod {
  POINT = 'POINT',
  CARD = 'CARD',
  CASH = 'CASH',
}

export enum ESaleStatus {
  RESERVATION = 'RESERVATION',
  WAITING_PAY = 'WAITING_PAY',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
}
