import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getBookingScheduleTodayAction } from '@/redux/actions';
import { getBookingScheduleToday, TGetBookingScheduleTodayResponse } from '@/services/api';

// FUNCTION

export function* getBookingScheduleTodaySaga(
  action: ActionType<typeof getBookingScheduleTodayAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getBookingScheduleToday, materials);
    const getBookingScheduleTodayResponse: TGetBookingScheduleTodayResponse =
      response as TGetBookingScheduleTodayResponse;
    yield put(getBookingScheduleTodayAction.success(getBookingScheduleTodayResponse));
    successCallback?.(getBookingScheduleTodayResponse);
  } catch (err) {
    yield put(getBookingScheduleTodayAction.failure(err));
    failedCallback?.(err);
  }
}
