import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 8.5H18.5V5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M18.9287 13C18.6861 14.6808 17.8407 16.2161 16.55 17.3199C15.2594 18.4237 13.6115 19.0206 11.9134 18.9994C10.2152 18.9783 8.5827 18.3405 7.31992 17.205C6.05714 16.0695 5.25023 14.5136 5.04954 12.8272C4.84884 11.1409 5.26805 9.43904 6.22905 8.03884C7.19006 6.63865 8.62731 5.63556 10.273 5.21647C11.9188 4.79738 13.6608 4.99087 15.1744 5.76089C16.2123 6.28888 17.0943 7.06325 17.7494 8.00626"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Svg;
