import { createActionCreator } from 'deox';

import { TGetEmployeesMaterials, TGetEmployeesResponse } from '@/services/api/employee/get-employees';

// CONSTANTS

export enum EGetEmployeesAction {
  GET_EMPLOYEES = 'GET_EMPLOYEES',
  GET_EMPLOYEES_REQUEST = 'GET_EMPLOYEES_REQUEST',
  GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS',
  GET_EMPLOYEES_FAILED = 'GET_EMPLOYEES_FAILED',
}

// TYPES

export type TGetEmployeesRequest = {
  type: EGetEmployeesAction.GET_EMPLOYEES_REQUEST;
  payload: {
    materials: TGetEmployeesMaterials;
    successCallback?: (response: TGetEmployeesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetEmployeesSuccess = {
  type: EGetEmployeesAction.GET_EMPLOYEES_SUCCESS;
  payload: { response: TGetEmployeesResponse };
};

export type TGetEmployeesFailed = { type: EGetEmployeesAction.GET_EMPLOYEES_FAILED };

// FUNCTION

export const getEmployeesAction = {
  request: createActionCreator(
    EGetEmployeesAction.GET_EMPLOYEES_REQUEST,
    (resolve) =>
      (
        materials: TGetEmployeesMaterials,
        successCallback?: (response: TGetEmployeesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetEmployeesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetEmployeesAction.GET_EMPLOYEES_SUCCESS,
    (resolve) =>
      (response: TGetEmployeesResponse): TGetEmployeesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetEmployeesAction.GET_EMPLOYEES_FAILED,
    (resolve) =>
      (error: unknown): TGetEmployeesFailed =>
        resolve({ error }),
  ),
};
