import { createActionCreator } from 'deox';

import { TCancelSalePaymentMaterials, TCancelSalePaymentResponse } from '@/services/api/sale/cancel-sale-payment';

// CONSTANTS

export enum ECancelSalePaymentAction {
  CANCEL_SALE_PAYMENT = 'CANCEL_SALE_PAYMENT',
  CANCEL_SALE_PAYMENT_REQUEST = 'CANCEL_SALE_PAYMENT_REQUEST',
  CANCEL_SALE_PAYMENT_SUCCESS = 'CANCEL_SALE_PAYMENT_SUCCESS',
  CANCEL_SALE_PAYMENT_FAILED = 'CANCEL_SALE_PAYMENT_FAILED',
}

// TYPES

export type TCancelSalePaymentRequest = {
  type: ECancelSalePaymentAction.CANCEL_SALE_PAYMENT_REQUEST;
  payload: {
    materials: TCancelSalePaymentMaterials;
    successCallback?: (response: TCancelSalePaymentResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCancelSalePaymentSuccess = {
  type: ECancelSalePaymentAction.CANCEL_SALE_PAYMENT_SUCCESS;
  payload: { response: TCancelSalePaymentResponse };
};

export type TCancelSalePaymentFailed = { type: ECancelSalePaymentAction.CANCEL_SALE_PAYMENT_FAILED };

// FUNCTION

export const cancelSalePaymentAction = {
  request: createActionCreator(
    ECancelSalePaymentAction.CANCEL_SALE_PAYMENT_REQUEST,
    (resolve) =>
      (
        materials: TCancelSalePaymentMaterials,
        successCallback?: (response: TCancelSalePaymentResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCancelSalePaymentRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECancelSalePaymentAction.CANCEL_SALE_PAYMENT_SUCCESS,
    (resolve) =>
      (response: TCancelSalePaymentResponse): TCancelSalePaymentSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECancelSalePaymentAction.CANCEL_SALE_PAYMENT_FAILED,
    (resolve) =>
      (error: unknown): TCancelSalePaymentFailed =>
        resolve({ error }),
  ),
};
