import { all, takeLatest } from 'redux-saga/effects';

import {
  changeMonthlyTargetSalesAction,
  getStatisticsBranchAction,
  getStatisticsBranchesAction,
  getStatisticsCustomerAction,
  getStatisticsEmployeesAction,
  getStatisticsAction,
} from '@/redux/actions';

import { changeMonthlyTargetSalesSaga } from './change-monthly-target-sales';
import { getStatisticsBranchSaga } from './get-statistics-branch';
import { getStatisticsBranchesSaga } from './get-statistics-branches';
import { getStatisticsCustomerSaga } from './get-statistics-customer';
import { getStatisticsEmployeesSaga } from './get-statistics-employees';
import { getStatisticsSaga } from './get-statistics';

export default function* root(): Generator {
  yield all([
    takeLatest(changeMonthlyTargetSalesAction.request.type, changeMonthlyTargetSalesSaga),
    takeLatest(getStatisticsBranchAction.request.type, getStatisticsBranchSaga),
    takeLatest(getStatisticsBranchesAction.request.type, getStatisticsBranchesSaga),
    takeLatest(getStatisticsCustomerAction.request.type, getStatisticsCustomerSaga),
    takeLatest(getStatisticsEmployeesAction.request.type, getStatisticsEmployeesSaga),
    takeLatest(getStatisticsAction.request.type, getStatisticsSaga),
  ]);
}
