import { createActionCreator } from 'deox';

import { TDeleteCustomerMaterials, TDeleteCustomerResponse } from '@/services/api/customer/delete-customer';

// CONSTANTS

export enum EDeleteCustomerAction {
  DELETE_CUSTOMER = 'DELETE_CUSTOMER',
  DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST',
  DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS',
  DELETE_CUSTOMER_FAILED = 'DELETE_CUSTOMER_FAILED',
}

// TYPES

export type TDeleteCustomerRequest = {
  type: EDeleteCustomerAction.DELETE_CUSTOMER_REQUEST;
  payload: {
    materials: TDeleteCustomerMaterials;
    successCallback?: (response: TDeleteCustomerResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteCustomerSuccess = {
  type: EDeleteCustomerAction.DELETE_CUSTOMER_SUCCESS;
  payload: { response: TDeleteCustomerResponse };
};

export type TDeleteCustomerFailed = { type: EDeleteCustomerAction.DELETE_CUSTOMER_FAILED };

// FUNCTION

export const deleteCustomerAction = {
  request: createActionCreator(
    EDeleteCustomerAction.DELETE_CUSTOMER_REQUEST,
    (resolve) =>
      (
        materials: TDeleteCustomerMaterials,
        successCallback?: (response: TDeleteCustomerResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteCustomerRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteCustomerAction.DELETE_CUSTOMER_SUCCESS,
    (resolve) =>
      (response: TDeleteCustomerResponse): TDeleteCustomerSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteCustomerAction.DELETE_CUSTOMER_FAILED,
    (resolve) =>
      (error: unknown): TDeleteCustomerFailed =>
        resolve({ error }),
  ),
};
