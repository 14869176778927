import { TCustomerState } from '@/redux/reducers/customer';
import { TAddTicketCustomerSuccess } from '@/redux/actions/customer';

export const addTicketCustomerUpdateState = (
  state: TCustomerState,
  action: TAddTicketCustomerSuccess,
): TCustomerState => ({
  ...state,
  addTicketCustomerResponse: action.payload.response,
});
