import { TStatisticBranch } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetStatisticsBranchesParams = unknown;

export type TGetStatisticsBranchesMaterials = {
  params?: TGetStatisticsBranchesParams;
};

export type TGetStatisticsBranchesResponse = TStatisticBranch[];

// FUNCTION

export const getStatisticsBranches = async ({
  params,
}: TGetStatisticsBranchesMaterials): Promise<TGetStatisticsBranchesResponse> => {
  const response = await ApiService.get(`/management/statistics/branches`, { params });
  return response.data;
};
