import ApiService from '@/services/api';

// TYPES

export type TRefundTicketCustomerParams = unknown;
export type TRefundTicketCustomerBody = {
  customer: string;
  customerTicket: string;
  refundAmount: number;
  closeTicket: boolean;
};

export type TRefundTicketCustomerMaterials = {
  params?: TRefundTicketCustomerParams;
  body?: TRefundTicketCustomerBody;
};

export type TRefundTicketCustomerResponse = unknown;

// FUNCTION

export const refundTicketCustomer = async ({
  params,
  body,
}: TRefundTicketCustomerMaterials): Promise<TRefundTicketCustomerResponse> => {
  const response = await ApiService.post(`/admin/customers/refund-ticket`, body, { params });
  return response.data;
};
