import { createActionCreator } from 'deox';

import { TGetInquiriesMaterials, TGetInquiriesResponse } from '@/services/api/inquiry/get-inquiries';

// CONSTANTS

export enum EGetInquiriesAction {
  GET_INQUIRIES = 'GET_INQUIRIES',
  GET_INQUIRIES_REQUEST = 'GET_INQUIRIES_REQUEST',
  GET_INQUIRIES_SUCCESS = 'GET_INQUIRIES_SUCCESS',
  GET_INQUIRIES_FAILED = 'GET_INQUIRIES_FAILED',
}

// TYPES

export type TGetInquiriesRequest = {
  type: EGetInquiriesAction.GET_INQUIRIES_REQUEST;
  payload: {
    materials: TGetInquiriesMaterials;
    successCallback?: (response: TGetInquiriesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetInquiriesSuccess = {
  type: EGetInquiriesAction.GET_INQUIRIES_SUCCESS;
  payload: { response: TGetInquiriesResponse };
};

export type TGetInquiriesFailed = { type: EGetInquiriesAction.GET_INQUIRIES_FAILED };

// FUNCTION

export const getInquiriesAction = {
  request: createActionCreator(
    EGetInquiriesAction.GET_INQUIRIES_REQUEST,
    (resolve) =>
      (
        materials: TGetInquiriesMaterials,
        successCallback?: (response: TGetInquiriesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetInquiriesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetInquiriesAction.GET_INQUIRIES_SUCCESS,
    (resolve) =>
      (response: TGetInquiriesResponse): TGetInquiriesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetInquiriesAction.GET_INQUIRIES_FAILED,
    (resolve) =>
      (error: unknown): TGetInquiriesFailed =>
        resolve({ error }),
  ),
};
