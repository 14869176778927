import { createReducer } from 'deox';

import {
  TAddTicketCustomerResponse,
  TCreateCustomerResponse,
  TDeleteCustomerResponse,
  TGetCustomerDetailResponse,
  TGetCustomerUsageResponse,
  TGetCustomerResponse,
  TGetCustomersResponse,
  TRefundTicketCustomerResponse,
  TUpdateCustomerResponse,
} from '@/services/api/customer';
import {
  addTicketCustomerAction,
  createCustomerAction,
  deleteCustomerAction,
  getCustomerDetailAction,
  getCustomerUsageAction,
  getCustomerAction,
  getCustomersAction,
  refundTicketCustomerAction,
  updateCustomerAction,
} from '@/redux/actions';
import { addTicketCustomerUpdateState } from './add-ticket-customer';
import { createCustomerUpdateState } from './create-customer';
import { deleteCustomerUpdateState } from './delete-customer';
import { getCustomerDetailUpdateState } from './get-customer-detail';
import { getCustomerUsageUpdateState } from './get-customer-usage';
import { getCustomerUpdateState } from './get-customer';
import { getCustomersUpdateState } from './get-customers';
import { refundTicketCustomerUpdateState } from './refund-ticket-customer';
import { updateCustomerUpdateState } from './update-customer';

export type TCustomerState = {
  addTicketCustomerResponse?: TAddTicketCustomerResponse;
  createCustomerResponse?: TCreateCustomerResponse;
  deleteCustomerResponse?: TDeleteCustomerResponse;
  getCustomerDetailResponse?: TGetCustomerDetailResponse;
  getCustomerUsageResponse?: TGetCustomerUsageResponse;
  getCustomerResponse?: TGetCustomerResponse;
  getCustomersResponse?: TGetCustomersResponse;
  refundTicketCustomerResponse?: TRefundTicketCustomerResponse;
  updateCustomerResponse?: TUpdateCustomerResponse;
};

const initialState: TCustomerState = {
  addTicketCustomerResponse: undefined,
  createCustomerResponse: undefined,
  deleteCustomerResponse: undefined,
  getCustomerDetailResponse: undefined,
  getCustomerUsageResponse: undefined,
  getCustomerResponse: undefined,
  getCustomersResponse: undefined,
  refundTicketCustomerResponse: undefined,
  updateCustomerResponse: undefined,
};

const CustomerReducer = createReducer(initialState, (handleAction) => [
  handleAction(addTicketCustomerAction.success, addTicketCustomerUpdateState),
  handleAction(createCustomerAction.success, createCustomerUpdateState),
  handleAction(deleteCustomerAction.success, deleteCustomerUpdateState),
  handleAction(getCustomerDetailAction.success, getCustomerDetailUpdateState),
  handleAction(getCustomerUsageAction.success, getCustomerUsageUpdateState),
  handleAction(getCustomerAction.success, getCustomerUpdateState),
  handleAction(getCustomersAction.success, getCustomersUpdateState),
  handleAction(refundTicketCustomerAction.success, refundTicketCustomerUpdateState),
  handleAction(updateCustomerAction.success, updateCustomerUpdateState),
]);

export default CustomerReducer;
