import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { TAdminProps } from '@/layouts/Admin/Admin.types';
import { getBranchesAction, getProfileAction } from '@/redux/actions';
import Navbar from '@/containers/Navbar';
import { TRootState } from '@/redux/reducers';

import './Admin.scss';

const Admin: React.FC<TAdminProps> = ({ children }) => {
  const dispatch = useDispatch();
  const [visibleSidebar, setVisibleSidebar] = useState<boolean>(true);
  const profileState = useSelector((state: TRootState) => state.authReducer.getProfileResponse);

  const getProfile = useCallback(() => {
    dispatch(getProfileAction.request({}));
  }, [dispatch]);

  const getBranches = useCallback(() => {
    dispatch(getBranchesAction.request({}));
  }, [dispatch]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  useEffect(() => {
    getBranches();
  }, [getBranches]);

  return (
    <div className={classNames('Admin', { visible: visibleSidebar })}>
      {profileState && (
        <>
          <div className="Admin-navbar">
            <Navbar visible={visibleSidebar} onToggleVisible={(): void => setVisibleSidebar(!visibleSidebar)} />
          </div>

          <div className="Admin-body">{children}</div>
        </>
      )}
    </div>
  );
};

export default Admin;
