import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteInquiryAction } from '@/redux/actions';
import { deleteInquiry, TDeleteInquiryResponse } from '@/services/api';

// FUNCTION

export function* deleteInquirySaga(action: ActionType<typeof deleteInquiryAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteInquiry, materials);
    const deleteInquiryResponse: TDeleteInquiryResponse = response as TDeleteInquiryResponse;
    yield put(deleteInquiryAction.success(deleteInquiryResponse));
    successCallback?.(deleteInquiryResponse);
  } catch (err) {
    yield put(deleteInquiryAction.failure(err));
    failedCallback?.(err);
  }
}
