import ApiService from '@/services/api';

// TYPES

export type TCreateScheduleCustomerParams = unknown;
export type TCreateScheduleCustomerBody = {
  branch: string;
  customer: string;
  visitDate: string;
  procedures: string[];
  startTime: string;
  procedureTime: string;
  manager?: string;
  note: string;
  treatment: string;
};

export type TCreateScheduleCustomerMaterials = {
  params?: TCreateScheduleCustomerParams;
  body?: TCreateScheduleCustomerBody;
};

export type TCreateScheduleCustomerResponse = unknown;

// FUNCTION

export const createScheduleCustomer = async ({
  params,
  body,
}: TCreateScheduleCustomerMaterials): Promise<TCreateScheduleCustomerResponse> => {
  const response = await ApiService.post(`/admin/managements/schedule-customer`, body, { params });
  return response.data;
};
