import { createReducer } from 'deox';

import {
  TDeleteInquiryResponse,
  TGetInquiriesResponse,
  TGetInquiryResponse,
  TUpdateInquiryResponse,
} from '@/services/api/inquiry';
import { deleteInquiryAction, getInquiriesAction, getInquiryAction, updateInquiryAction } from '@/redux/actions';
import { deleteInquiryUpdateState } from './delete-inquiry';
import { getInquiriesUpdateState } from './get-inquiries';
import { getInquiryUpdateState } from './get-inquiry';
import { updateInquiryUpdateState } from './update-inquiry';

export type TInquiryState = {
  deleteInquiryResponse?: TDeleteInquiryResponse;
  getInquiriesResponse?: TGetInquiriesResponse;
  getInquiryResponse?: TGetInquiryResponse;
  updateInquiryResponse?: TUpdateInquiryResponse;
};

const initialState: TInquiryState = {
  deleteInquiryResponse: undefined,
  getInquiriesResponse: undefined,
  getInquiryResponse: undefined,
  updateInquiryResponse: undefined,
};

const InquiryReducer = createReducer(initialState, (handleAction) => [
  handleAction(deleteInquiryAction.success, deleteInquiryUpdateState),
  handleAction(getInquiriesAction.success, getInquiriesUpdateState),
  handleAction(getInquiryAction.success, getInquiryUpdateState),
  handleAction(updateInquiryAction.success, updateInquiryUpdateState),
]);

export default InquiryReducer;
