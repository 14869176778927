import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createSaleAction } from '@/redux/actions';
import { createSale, TCreateSaleResponse } from '@/services/api';

// FUNCTION

export function* createSaleSaga(action: ActionType<typeof createSaleAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createSale, materials);
    const createSaleResponse: TCreateSaleResponse = response as TCreateSaleResponse;
    yield put(createSaleAction.success(createSaleResponse));
    successCallback?.(createSaleResponse);
  } catch (err) {
    yield put(createSaleAction.failure(err));
    failedCallback?.(err);
  }
}
