import { createReducer } from 'deox';

import { TAdminLoginResponse, TGetProfileResponse } from '@/services/api/auth';
import { adminLoginAction, getProfileAction } from '@/redux/actions';
import { adminLoginUpdateState } from './admin-login';
import { getProfileUpdateState } from './get-profile';

export type TAuthState = {
  adminLoginResponse?: TAdminLoginResponse;
  getProfileResponse?: TGetProfileResponse;
  isSuperAdmin?: boolean;
};

const initialState: TAuthState = {
  adminLoginResponse: undefined,
  getProfileResponse: undefined,
  isSuperAdmin: false,
};

const AuthReducer = createReducer(initialState, (handleAction) => [
  handleAction(adminLoginAction.success, adminLoginUpdateState),
  handleAction(getProfileAction.success, getProfileUpdateState),
]);

export default AuthReducer;
