import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getManagerTalksSettingsAction } from '@/redux/actions';
import { getManagerTalksSettings, TGetManagerTalksSettingsResponse } from '@/services/api';

// FUNCTION

export function* getManagerTalksSettingsSaga(
  action: ActionType<typeof getManagerTalksSettingsAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getManagerTalksSettings, materials);
    const getManagerTalksSettingsResponse: TGetManagerTalksSettingsResponse =
      response as TGetManagerTalksSettingsResponse;
    yield put(getManagerTalksSettingsAction.success(getManagerTalksSettingsResponse));
    successCallback?.(getManagerTalksSettingsResponse);
  } catch (err) {
    yield put(getManagerTalksSettingsAction.failure(err));
    failedCallback?.(err);
  }
}
