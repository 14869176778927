import { createActionCreator } from 'deox';

import {
  TGetManagerTalksSentsMaterials,
  TGetManagerTalksSentsResponse,
} from '@/services/api/talks/get-manager-talks-sents';

// CONSTANTS

export enum EGetManagerTalksSentsAction {
  GET_MANAGER_TALKS_SENTS = 'GET_MANAGER_TALKS_SENTS',
  GET_MANAGER_TALKS_SENTS_REQUEST = 'GET_MANAGER_TALKS_SENTS_REQUEST',
  GET_MANAGER_TALKS_SENTS_SUCCESS = 'GET_MANAGER_TALKS_SENTS_SUCCESS',
  GET_MANAGER_TALKS_SENTS_FAILED = 'GET_MANAGER_TALKS_SENTS_FAILED',
}

// TYPES

export type TGetManagerTalksSentsRequest = {
  type: EGetManagerTalksSentsAction.GET_MANAGER_TALKS_SENTS_REQUEST;
  payload: {
    materials: TGetManagerTalksSentsMaterials;
    successCallback?: (response: TGetManagerTalksSentsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetManagerTalksSentsSuccess = {
  type: EGetManagerTalksSentsAction.GET_MANAGER_TALKS_SENTS_SUCCESS;
  payload: { response: TGetManagerTalksSentsResponse };
};

export type TGetManagerTalksSentsFailed = { type: EGetManagerTalksSentsAction.GET_MANAGER_TALKS_SENTS_FAILED };

// FUNCTION

export const getManagerTalksSentsAction = {
  request: createActionCreator(
    EGetManagerTalksSentsAction.GET_MANAGER_TALKS_SENTS_REQUEST,
    (resolve) =>
      (
        materials: TGetManagerTalksSentsMaterials,
        successCallback?: (response: TGetManagerTalksSentsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetManagerTalksSentsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetManagerTalksSentsAction.GET_MANAGER_TALKS_SENTS_SUCCESS,
    (resolve) =>
      (response: TGetManagerTalksSentsResponse): TGetManagerTalksSentsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetManagerTalksSentsAction.GET_MANAGER_TALKS_SENTS_FAILED,
    (resolve) =>
      (error: unknown): TGetManagerTalksSentsFailed =>
        resolve({ error }),
  ),
};
