/* eslint-disable no-useless-escape */
import React, { SyntheticEvent } from 'react';
import { notification } from 'antd';
import { Rule } from 'antd/lib/form';
import moment, { Moment } from 'moment';
import classNames from 'classnames';

import { EFormat, ETypeNotification } from '@/common/enums';
import { regex } from '@/common/constants';
import Icon, { EIconColor, EIconName } from '@/components/Icon';

export const removeAccents = (str: string): string => {
  let strConverted = str;
  if (strConverted) {
    strConverted = strConverted.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    strConverted = strConverted.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    strConverted = strConverted.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    strConverted = strConverted.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    strConverted = strConverted.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    strConverted = strConverted.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    strConverted = strConverted.replace(/đ/g, 'd');
    strConverted = strConverted.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    strConverted = strConverted.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    strConverted = strConverted.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    strConverted = strConverted.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    strConverted = strConverted.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    strConverted = strConverted.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    strConverted = strConverted.replace(/Đ/g, 'D');

    strConverted = strConverted.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '');
    strConverted = strConverted.replace(/\u02C6|\u0306|\u031B/g, '');
    // Remove extra spaces
    strConverted = strConverted.replace(/ + /g, ' ');
    strConverted = strConverted.trim();
    // Remove punctuations
    strConverted = strConverted.replace(
      /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
      ' ',
    );
    return strConverted;
  }

  return '';
};

export const showNotification = (type: ETypeNotification, description: string): void => {
  let iconName;

  switch (type) {
    case ETypeNotification.ERROR:
      iconName = EIconName.X;
      break;
    default:
      iconName = EIconName.CheckCircle;
      break;
  }

  const options: any = {
    message: '',
    description,
    duration: 4,
    placement: 'bottom',
    className: classNames('Notification', type),
    closeIcon: <></>,
    icon: <Icon name={iconName} color={EIconColor.WHITE} />,
  };

  switch (type) {
    case ETypeNotification.SUCCESS:
      notification.success(options);
      break;
    case ETypeNotification.WARNING:
      notification.warning(options);
      break;
    case ETypeNotification.ERROR:
      notification.error(options);
      break;
    case ETypeNotification.INFO:
      notification.info(options);
      break;
    default:
      notification.open(options);
  }
};

export const searchString = (target: string | string[], searchValue: string): boolean => {
  const searchKey = searchValue.toLowerCase();
  const searchTarget = target instanceof Array ? target.map((str) => str.toLowerCase()) : target.toLowerCase();
  const searchResult =
    searchTarget instanceof Array
      ? !!searchTarget.filter((str) => removeAccents(str).includes(removeAccents(searchKey))).length
      : removeAccents(searchTarget).includes(removeAccents(searchKey));
  return searchResult;
};

export const getTotalPage = (totalItem: number, pageSize: number): number => {
  return Math.ceil(totalItem / pageSize);
};

export const scrollToTop = (): void => {
  window.scrollTo(0, 0);
};

export const validationRules = {
  required: (message?: string): Rule => ({
    required: true,
    message: message || 'This field is required !',
  }),
  max: (max: number, message?: string): Rule => ({
    validator: (rule: any, value: any): Promise<void> => {
      if (!value || Number(value?.value || value) <= max) return Promise.resolve();
      return Promise.reject(message || `Enter value less than ${max} !`);
    },
  }),
  min: (min: number, message?: string): Rule => ({
    validator: (rule: any, value: any): Promise<void> => {
      if (!value || Number(value?.value || value) >= min) return Promise.resolve();
      return Promise.reject(message || `Enter value greater than ${min} !`);
    },
  }),
  minLength: (message?: string, length = 2): Rule => ({
    min: length,
    message: message || `Enter characters at least ${length} !`,
  }),
  maxLength: (message?: string, length = 10): Rule => ({
    max: length,
    message: message || `Enter characters at most ${length} !`,
  }),
  email: (message?: string): Rule => ({
    type: 'email',
    message: message || 'Please enter valid email !',
  }),
  noSpecialKey: (message?: string): Rule => ({
    validator: (rule: any, value: string): Promise<void> => {
      if (!value || !regex.onlySpecialKey.test(value)) return Promise.resolve();
      return Promise.reject(message || 'Cannot enter special characters !');
    },
  }),
  onlyAlphabetic: (message: string): Rule => ({
    validator: (rule: any, value: string): Promise<void> => {
      if (!value || regex.alphabetic.test(removeAccents(value))) return Promise.resolve();
      return Promise.reject(message || 'This is a field where only alphabetic characters are entered !');
    },
  }),
  onlyNumeric: (message: string): Rule => ({
    validator: (rule: any, value: string): Promise<void> => {
      if (!value || regex.numeric.test(value)) return Promise.resolve();
      return Promise.reject(message || 'This is a field where only numeric characters are entered !');
    },
  }),
  onlyAlphanumerial: (message: string): Rule => ({
    validator: (rule: any, value: string): Promise<void> => {
      if (!value || regex.alphanumerial.test(removeAccents(value))) return Promise.resolve();
      return Promise.reject(message || 'This is a field where only alphanumeric characters are entered !');
    },
  }),
  domain: (message: string): Rule => ({
    validator: (rule: any, value: string): Promise<void> => {
      if (!value || regex.domain.test(value)) return Promise.resolve();
      return Promise.reject(message || 'Invalid domain !');
    },
  }),
  url: (message: string): Rule => ({
    validator: (rule: any, value: string): Promise<void> => {
      if (!value || regex.url.test(value)) return Promise.resolve();
      return Promise.reject(message || 'Invalid URL !');
    },
  }),
  confirmPassword: (confirmPasswordValue: string, message?: string): Rule => ({
    validator: (rule: any, value: string): Promise<void> => {
      if (!value || value === confirmPasswordValue) return Promise.resolve();
      return Promise.reject(message || 'Confirm password not matched !');
    },
  }),
};

export const formatAbbreviationsName = (value: string): string => {
  const arrayString = value.trim().split(' ');
  const onlyOneWord = arrayString.length === 1;

  if (onlyOneWord) {
    const firstLetter = arrayString[0].trim().charAt(0);
    return `${firstLetter}`.toUpperCase();
  }

  const firstLastWordFirstLetter = arrayString[arrayString.length - 2].trim().charAt(0);
  const secondLastWordFirstLetter = arrayString[arrayString.length - 1].trim().charAt(0);

  return `${firstLastWordFirstLetter}${secondLastWordFirstLetter}`.toUpperCase();
};

export const formatISODateToDateTime = (time: string | number, format?: string): string => {
  return moment(time)
    .locale('ko')
    .format(format || EFormat.DATE_WITH_TEXT);
};

export const formatCurrencyKorea = (config: {
  amount: number | string;
  uppercaseUnit?: boolean;
  showSuffix?: boolean;
}): string => {
  const separateMoney = Intl.NumberFormat('ko-KR').format(Number(config.amount));
  const unit = '원';
  return `${separateMoney} ${config.showSuffix ? unit : ''}`;
};

export const copyText = (text: string): void => {
  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const handleErrorImageUrl = (e: SyntheticEvent<HTMLImageElement, Event>): void => {
  const { currentTarget } = e;
  currentTarget.onerror = null; // prevents looping
  currentTarget.src = 'YOUR URL IMAGE ERROR';
};

export const getQueryParam = (param: string): string | null => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
};

export const urlSafe = (text: string): string => {
  const url = text
    .replace(/[^a-zA-Z0-9- ]/g, '') // remove invalid characters
    .replace(/\s\s+/g, ' ') // trim whitespace
    .replace(/ /g, '-') // replace space with -
    .toLowerCase();
  return url;
};

export const truncateStringByCharaters = (content: string, maxLength: number, expandSplit = 0): string => {
  const contentLength = content.length;
  return `${content.slice(0, contentLength > maxLength ? maxLength - expandSplit : contentLength)}${
    contentLength > maxLength ? '...' : ''
  }`;
};

export const truncateStringByWords = (content: string, maxWords: number): string => {
  const contentSplited = content.split(' ');
  if (maxWords >= contentSplited.length) {
    return content;
  }
  const contentSplitedOptimized = contentSplited.filter((_, index) => index < maxWords);
  const contentTruncated = contentSplitedOptimized.join(' ');
  return `${contentTruncated}...`;
};

export const createImageByFileBlob = (file: File | Blob): string => {
  const imageBlob = new Blob([file]);
  return URL.createObjectURL(imageBlob);
};

export const parseObjectToFormData = (data: { [key: string]: any }): FormData => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === 'undefined') return;
    formData.append(key, data[key]);
  });
  return formData;
};

export const getArrayFrom0To = (numb: number): number[] =>
  Array(numb)
    .fill('')
    .map((_, i) => i);

export const formatPhoneNumber = (phoneNumberString?: string): string => {
  if (!phoneNumberString) return '';

  const formatValue = phoneNumberString.replace(/\D/g, '');
  const firstGroup = formatValue.slice(0, 3);
  const secondGroup = formatValue.slice(3, 7);
  const thirdGroup = formatValue.slice(7);

  if (!firstGroup) return '';

  return `${firstGroup}${secondGroup ? `-${secondGroup}` : ''}${thirdGroup ? `-${thirdGroup}` : ''}`;
};

export const getNumbericOnly = (phoneNumberString?: string): string => {
  if (!phoneNumberString) return '';

  const formatValue = phoneNumberString.replace(/\D/g, '');
  return formatValue;
};

export const isObject = (object: Record<string, unknown> | any): boolean => {
  return object != null && typeof object === 'object';
};

export const deepEqualObj = (
  object1: Record<string, unknown> | any,
  object2: Record<string, unknown> | any,
): boolean => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqualObj(val1 as Record<string, unknown>, val2 as Record<string, unknown>)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

export const range = (size: number, startAt = 0): number[] => {
  return getArrayFrom0To(size).map((i) => i + startAt);
};

export const getDateArray = (start: string, end: string): Moment[] => {
  const arr = [];
  let dt = moment(start);

  while (dt.isBefore(moment(end))) {
    arr.push(dt);
    dt = dt.clone().add(1, 'd');
  }

  return arr;
};

export const getTimeArray = (start: string, end: string, between = 30): Moment[] => {
  const arr = [];
  let dt = moment(start, EFormat.FULL_TIME);

  while (dt.isBefore(moment(end, EFormat.FULL_TIME).add(between, 'minute'))) {
    arr.push(dt);
    dt = dt.clone().add(between, 'minute');
  }

  return arr;
};

export const validateImageUpload = (file: File): boolean => {
  const allowedExtension = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg'];
  const maxSize = 1024 * 100; // 100MB
  let isValid = true;
  const { type, size } = file;

  if (!allowedExtension.includes(type)) isValid = false;
  if (Math.round(size / 1024) > maxSize) isValid = false;

  return isValid;
};

export const validateImagesUpload = (files: File[]): boolean => {
  let isValid = true;
  files.forEach((file) => {
    if (!validateImageUpload(file)) isValid = false;
  });

  return isValid;
};

export const formatDurationProcedure = (numberOfMinutes: number): string => {
  const duration = moment.duration(numberOfMinutes, 'minutes');
  const hh = duration.years() * (365 * 24) + duration.months() * (30 * 24) + duration.days() * 24 + duration.hours();
  const mm = duration.minutes();
  return `${hh ? `${hh}시간` : ''}${mm ? ` ${mm}분` : ''}`;
};

export const getRangeDate = (startDate: string, endDate: string, type: string | any): Moment[] => {
  const fromDate = moment(startDate);
  const toDate = moment(endDate);
  const diff = toDate.diff(fromDate, type);
  const rangeMoment = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < diff; i++) {
    rangeMoment.push(moment(startDate).add(i, type));
  }
  return rangeMoment;
};

export const getPages = (pages: number[], numberOfPages: number, value: number): number[] => {
  const firstPages = pages.filter((_, i) => i < numberOfPages);
  const lastPages = pages.filter((_, i) => i > pages.length - numberOfPages - 1);
  const numberOfPagesAroundASide = (numberOfPages - 1) / 2;
  const pagesAtHead = pages.filter((_, i) => i < numberOfPagesAroundASide);
  const pagesAtTail = pages.filter((_, i) => i > pages.length - numberOfPagesAroundASide - 1);
  if (pagesAtHead.includes(value)) return firstPages;
  if (pagesAtTail.includes(value)) return lastPages;

  const pagesAtLeftSide = Array(numberOfPagesAroundASide)
    .fill('')
    .map((_, i) => value - (i + 1))
    .reverse();
  const pagesAtRightSide = Array(numberOfPagesAroundASide)
    .fill('')
    .map((_, i) => value + (i + 1));
  return [...pagesAtLeftSide, value, ...pagesAtRightSide];
};

export const getIndexDescending = (total: number, index: number, page: number, pageSize: number): string => {
  return `${total - index - (page - 1) * pageSize}`;
};
