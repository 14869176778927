import { ERole } from '@/common/enums';
import { TEmployee } from '@/common/models';
import { TPaginationResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetEmployeesParams = {
  page: number;
  pageSize: number;
  search?: string;
  role?: ERole;
};

export type TGetEmployeesMaterials = {
  params?: TGetEmployeesParams;
};

export type TGetEmployeesResponse = {
  data: TEmployee[];
  pagination: TPaginationResponse;
};

// FUNCTION

export const getEmployees = async ({ params }: TGetEmployeesMaterials): Promise<TGetEmployeesResponse> => {
  const response = await ApiService.get(`/employees`, { params });
  return response.data;
};
