import { createActionCreator } from 'deox';

import { TGetCustomersMaterials, TGetCustomersResponse } from '@/services/api/customer/get-customers';

// CONSTANTS

export enum EGetCustomersAction {
  GET_CUSTOMERS = 'GET_CUSTOMERS',
  GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST',
  GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAILED = 'GET_CUSTOMERS_FAILED',
}

// TYPES

export type TGetCustomersRequest = {
  type: EGetCustomersAction.GET_CUSTOMERS_REQUEST;
  payload: {
    materials: TGetCustomersMaterials;
    successCallback?: (response: TGetCustomersResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetCustomersSuccess = {
  type: EGetCustomersAction.GET_CUSTOMERS_SUCCESS;
  payload: { response: TGetCustomersResponse };
};

export type TGetCustomersFailed = { type: EGetCustomersAction.GET_CUSTOMERS_FAILED };

// FUNCTION

export const getCustomersAction = {
  request: createActionCreator(
    EGetCustomersAction.GET_CUSTOMERS_REQUEST,
    (resolve) =>
      (
        materials: TGetCustomersMaterials,
        successCallback?: (response: TGetCustomersResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetCustomersRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetCustomersAction.GET_CUSTOMERS_SUCCESS,
    (resolve) =>
      (response: TGetCustomersResponse): TGetCustomersSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetCustomersAction.GET_CUSTOMERS_FAILED,
    (resolve) =>
      (error: unknown): TGetCustomersFailed =>
        resolve({ error }),
  ),
};
