import { createActionCreator } from 'deox';

import {
  TUpdateCustomerNoteMaterials,
  TUpdateCustomerNoteResponse,
} from '@/services/api/schedule/update-customer-note';

// CONSTANTS

export enum EUpdateCustomerNoteAction {
  UPDATE_CUSTOMER_NOTE = 'UPDATE_CUSTOMER_NOTE',
  UPDATE_CUSTOMER_NOTE_REQUEST = 'UPDATE_CUSTOMER_NOTE_REQUEST',
  UPDATE_CUSTOMER_NOTE_SUCCESS = 'UPDATE_CUSTOMER_NOTE_SUCCESS',
  UPDATE_CUSTOMER_NOTE_FAILED = 'UPDATE_CUSTOMER_NOTE_FAILED',
}

// TYPES

export type TUpdateCustomerNoteRequest = {
  type: EUpdateCustomerNoteAction.UPDATE_CUSTOMER_NOTE_REQUEST;
  payload: {
    materials: TUpdateCustomerNoteMaterials;
    successCallback?: (response: TUpdateCustomerNoteResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateCustomerNoteSuccess = {
  type: EUpdateCustomerNoteAction.UPDATE_CUSTOMER_NOTE_SUCCESS;
  payload: { response: TUpdateCustomerNoteResponse };
};

export type TUpdateCustomerNoteFailed = { type: EUpdateCustomerNoteAction.UPDATE_CUSTOMER_NOTE_FAILED };

// FUNCTION

export const updateCustomerNoteAction = {
  request: createActionCreator(
    EUpdateCustomerNoteAction.UPDATE_CUSTOMER_NOTE_REQUEST,
    (resolve) =>
      (
        materials: TUpdateCustomerNoteMaterials,
        successCallback?: (response: TUpdateCustomerNoteResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateCustomerNoteRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateCustomerNoteAction.UPDATE_CUSTOMER_NOTE_SUCCESS,
    (resolve) =>
      (response: TUpdateCustomerNoteResponse): TUpdateCustomerNoteSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateCustomerNoteAction.UPDATE_CUSTOMER_NOTE_FAILED,
    (resolve) =>
      (error: unknown): TUpdateCustomerNoteFailed =>
        resolve({ error }),
  ),
};
