import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import IconTrash from '@/assets/icons/icon-trash.svg';

const Svg: React.FC<TIconProps> = () => {
  return <img src={IconTrash} alt="" />;
};

export default Svg;
