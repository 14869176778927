import { createActionCreator } from 'deox';

import {
  TChangeMonthlyTargetSalesMaterials,
  TChangeMonthlyTargetSalesResponse,
} from '@/services/api/statistic/change-monthly-target-sales';

// CONSTANTS

export enum EChangeMonthlyTargetSalesAction {
  CHANGE_MONTHLY_TARGET_SALES = 'CHANGE_MONTHLY_TARGET_SALES',
  CHANGE_MONTHLY_TARGET_SALES_REQUEST = 'CHANGE_MONTHLY_TARGET_SALES_REQUEST',
  CHANGE_MONTHLY_TARGET_SALES_SUCCESS = 'CHANGE_MONTHLY_TARGET_SALES_SUCCESS',
  CHANGE_MONTHLY_TARGET_SALES_FAILED = 'CHANGE_MONTHLY_TARGET_SALES_FAILED',
}

// TYPES

export type TChangeMonthlyTargetSalesRequest = {
  type: EChangeMonthlyTargetSalesAction.CHANGE_MONTHLY_TARGET_SALES_REQUEST;
  payload: {
    materials: TChangeMonthlyTargetSalesMaterials;
    successCallback?: (response: TChangeMonthlyTargetSalesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TChangeMonthlyTargetSalesSuccess = {
  type: EChangeMonthlyTargetSalesAction.CHANGE_MONTHLY_TARGET_SALES_SUCCESS;
  payload: { response: TChangeMonthlyTargetSalesResponse };
};

export type TChangeMonthlyTargetSalesFailed = {
  type: EChangeMonthlyTargetSalesAction.CHANGE_MONTHLY_TARGET_SALES_FAILED;
};

// FUNCTION

export const changeMonthlyTargetSalesAction = {
  request: createActionCreator(
    EChangeMonthlyTargetSalesAction.CHANGE_MONTHLY_TARGET_SALES_REQUEST,
    (resolve) =>
      (
        materials: TChangeMonthlyTargetSalesMaterials,
        successCallback?: (response: TChangeMonthlyTargetSalesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TChangeMonthlyTargetSalesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EChangeMonthlyTargetSalesAction.CHANGE_MONTHLY_TARGET_SALES_SUCCESS,
    (resolve) =>
      (response: TChangeMonthlyTargetSalesResponse): TChangeMonthlyTargetSalesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EChangeMonthlyTargetSalesAction.CHANGE_MONTHLY_TARGET_SALES_FAILED,
    (resolve) =>
      (error: unknown): TChangeMonthlyTargetSalesFailed =>
        resolve({ error }),
  ),
};
