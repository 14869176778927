import { TNotice } from '@/common/models';
import { TPaginationResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetNoticesParams = {
  search?: string;
  page: number;
  pageSize: number;
};

export type TGetNoticesMaterials = {
  isSuperAdmin?: boolean;
  params?: TGetNoticesParams;
};

export type TGetNoticesResponse = {
  data: TNotice[];
  pagination: TPaginationResponse;
};

// FUNCTION

export const getNotices = async ({ params, isSuperAdmin }: TGetNoticesMaterials): Promise<TGetNoticesResponse> => {
  const response = await ApiService.get(`/${isSuperAdmin ? 'management' : 'admin'}/notices`, { params });
  return response.data;
};
