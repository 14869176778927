import ApiService from '@/services/api';

// TYPES

export type TChangeMonthlyTargetSalesBody = unknown;

export type TChangeMonthlyTargetSalesMaterials = {
  body?: TChangeMonthlyTargetSalesBody;
  isSuperAdmin?: boolean;
};

export type TChangeMonthlyTargetSalesResponse = unknown;

// FUNCTION

export const changeMonthlyTargetSales = async ({
  body,
  isSuperAdmin,
}: TChangeMonthlyTargetSalesMaterials): Promise<TChangeMonthlyTargetSalesResponse> => {
  const response = await ApiService.patch(`${isSuperAdmin ? '/management' : ''}/statistics/monthly-target-sales`, body);
  return response.data;
};
