import { TTalksState } from '@/redux/reducers/talks';
import { TGetManagerTalksSettingSuccess } from '@/redux/actions/talks';

export const getManagerTalksSettingUpdateState = (
  state: TTalksState,
  action: TGetManagerTalksSettingSuccess,
): TTalksState => ({
  ...state,
  getManagerTalksSettingResponse: action.payload.response,
});
