import ApiService from '@/services/api';

// TYPES

export type TCreateStaffParams = unknown;
export type TCreateStaffBody = FormData;

export type TCreateStaffMaterials = {
  params?: TCreateStaffParams;
  body?: TCreateStaffBody;
};

export type TCreateStaffResponse = unknown;

// FUNCTION

export const createStaff = async ({ params, body }: TCreateStaffMaterials): Promise<TCreateStaffResponse> => {
  const response = await ApiService.post(`/staffs`, body, { params });
  return response.data;
};
