import { TSale } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetSalePaths = {
  id: string | number;
};
export type TGetSaleParams = unknown;

export type TGetSaleMaterials = {
  paths?: TGetSalePaths;
  params?: TGetSaleParams;
};

export type TGetSaleResponse = TSale;

// FUNCTION

export const getSale = async ({ paths, params }: TGetSaleMaterials): Promise<TGetSaleResponse> => {
  const response = await ApiService.get(`/admin/sales/${paths?.id}`, { params });
  return response.data;
};
