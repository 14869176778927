import { TProcedureState } from '@/redux/reducers/procedure';
import { TCreateProcedureSuccess } from '@/redux/actions/procedure';

export const createProcedureUpdateState = (
  state: TProcedureState,
  action: TCreateProcedureSuccess,
): TProcedureState => ({
  ...state,
  createProcedureResponse: action.payload.response,
});
