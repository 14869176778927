import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateCustomerAction } from '@/redux/actions';
import { updateCustomer, TUpdateCustomerResponse } from '@/services/api';

// FUNCTION

export function* updateCustomerSaga(action: ActionType<typeof updateCustomerAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateCustomer, materials);
    const updateCustomerResponse: TUpdateCustomerResponse = response as TUpdateCustomerResponse;
    yield put(updateCustomerAction.success(updateCustomerResponse));
    successCallback?.(updateCustomerResponse);
  } catch (err) {
    yield put(updateCustomerAction.failure(err));
    failedCallback?.(err);
  }
}
