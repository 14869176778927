import { TStatisticState } from '@/redux/reducers/statistic';
import { TGetStatisticsBranchesSuccess } from '@/redux/actions/statistic';

export const getStatisticsBranchesUpdateState = (
  state: TStatisticState,
  action: TGetStatisticsBranchesSuccess,
): TStatisticState => ({
  ...state,
  getStatisticsBranchesResponse: action.payload.response,
});
