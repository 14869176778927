import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getManagerTalksNotificationAction } from '@/redux/actions';
import { getManagerTalksNotification, TGetManagerTalksNotificationResponse } from '@/services/api';

// FUNCTION

export function* getManagerTalksNotificationSaga(
  action: ActionType<typeof getManagerTalksNotificationAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getManagerTalksNotification, materials);
    const getManagerTalksNotificationResponse: TGetManagerTalksNotificationResponse =
      response as TGetManagerTalksNotificationResponse;
    yield put(getManagerTalksNotificationAction.success(getManagerTalksNotificationResponse));
    successCallback?.(getManagerTalksNotificationResponse);
  } catch (err) {
    yield put(getManagerTalksNotificationAction.failure(err));
    failedCallback?.(err);
  }
}
