import { TTalksState } from '@/redux/reducers/talks';
import { TSetManagerTalksKakaoChannelSuccess } from '@/redux/actions/talks';

export const setManagerTalksKakaoChannelUpdateState = (
  state: TTalksState,
  action: TSetManagerTalksKakaoChannelSuccess,
): TTalksState => ({
  ...state,
  setManagerTalksKakaoChannelResponse: action.payload.response,
});
