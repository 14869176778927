import { TSalesSettingState } from '@/redux/reducers/sales-setting';
import { TGetSalesSettingSuccess } from '@/redux/actions/sales-setting';

export const getSalesSettingUpdateState = (
  state: TSalesSettingState,
  action: TGetSalesSettingSuccess,
): TSalesSettingState => ({
  ...state,
  getSalesSettingResponse: action.payload.response,
});
