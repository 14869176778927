import { createActionCreator } from 'deox';

import {
  TGetManagerTalksSettingMaterials,
  TGetManagerTalksSettingResponse,
} from '@/services/api/talks/get-manager-talks-setting';

// CONSTANTS

export enum EGetManagerTalksSettingAction {
  GET_MANAGER_TALKS_SETTING = 'GET_MANAGER_TALKS_SETTING',
  GET_MANAGER_TALKS_SETTING_REQUEST = 'GET_MANAGER_TALKS_SETTING_REQUEST',
  GET_MANAGER_TALKS_SETTING_SUCCESS = 'GET_MANAGER_TALKS_SETTING_SUCCESS',
  GET_MANAGER_TALKS_SETTING_FAILED = 'GET_MANAGER_TALKS_SETTING_FAILED',
}

// TYPES

export type TGetManagerTalksSettingRequest = {
  type: EGetManagerTalksSettingAction.GET_MANAGER_TALKS_SETTING_REQUEST;
  payload: {
    materials: TGetManagerTalksSettingMaterials;
    successCallback?: (response: TGetManagerTalksSettingResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetManagerTalksSettingSuccess = {
  type: EGetManagerTalksSettingAction.GET_MANAGER_TALKS_SETTING_SUCCESS;
  payload: { response: TGetManagerTalksSettingResponse };
};

export type TGetManagerTalksSettingFailed = { type: EGetManagerTalksSettingAction.GET_MANAGER_TALKS_SETTING_FAILED };

// FUNCTION

export const getManagerTalksSettingAction = {
  request: createActionCreator(
    EGetManagerTalksSettingAction.GET_MANAGER_TALKS_SETTING_REQUEST,
    (resolve) =>
      (
        materials: TGetManagerTalksSettingMaterials,
        successCallback?: (response: TGetManagerTalksSettingResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetManagerTalksSettingRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetManagerTalksSettingAction.GET_MANAGER_TALKS_SETTING_SUCCESS,
    (resolve) =>
      (response: TGetManagerTalksSettingResponse): TGetManagerTalksSettingSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetManagerTalksSettingAction.GET_MANAGER_TALKS_SETTING_FAILED,
    (resolve) =>
      (error: unknown): TGetManagerTalksSettingFailed =>
        resolve({ error }),
  ),
};
