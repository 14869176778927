import { createActionCreator } from 'deox';

import {
  TGetManagerTalksNotificationMaterials,
  TGetManagerTalksNotificationResponse,
} from '@/services/api/talks/get-manager-talks-notification';

// CONSTANTS

export enum EGetManagerTalksNotificationAction {
  GET_MANAGER_TALKS_NOTIFICATION = 'GET_MANAGER_TALKS_NOTIFICATION',
  GET_MANAGER_TALKS_NOTIFICATION_REQUEST = 'GET_MANAGER_TALKS_NOTIFICATION_REQUEST',
  GET_MANAGER_TALKS_NOTIFICATION_SUCCESS = 'GET_MANAGER_TALKS_NOTIFICATION_SUCCESS',
  GET_MANAGER_TALKS_NOTIFICATION_FAILED = 'GET_MANAGER_TALKS_NOTIFICATION_FAILED',
}

// TYPES

export type TGetManagerTalksNotificationRequest = {
  type: EGetManagerTalksNotificationAction.GET_MANAGER_TALKS_NOTIFICATION_REQUEST;
  payload: {
    materials: TGetManagerTalksNotificationMaterials;
    successCallback?: (response: TGetManagerTalksNotificationResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetManagerTalksNotificationSuccess = {
  type: EGetManagerTalksNotificationAction.GET_MANAGER_TALKS_NOTIFICATION_SUCCESS;
  payload: { response: TGetManagerTalksNotificationResponse };
};

export type TGetManagerTalksNotificationFailed = {
  type: EGetManagerTalksNotificationAction.GET_MANAGER_TALKS_NOTIFICATION_FAILED;
};

// FUNCTION

export const getManagerTalksNotificationAction = {
  request: createActionCreator(
    EGetManagerTalksNotificationAction.GET_MANAGER_TALKS_NOTIFICATION_REQUEST,
    (resolve) =>
      (
        materials: TGetManagerTalksNotificationMaterials,
        successCallback?: (response: TGetManagerTalksNotificationResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetManagerTalksNotificationRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetManagerTalksNotificationAction.GET_MANAGER_TALKS_NOTIFICATION_SUCCESS,
    (resolve) =>
      (response: TGetManagerTalksNotificationResponse): TGetManagerTalksNotificationSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetManagerTalksNotificationAction.GET_MANAGER_TALKS_NOTIFICATION_FAILED,
    (resolve) =>
      (error: unknown): TGetManagerTalksNotificationFailed =>
        resolve({ error }),
  ),
};
