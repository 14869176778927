import { createReducer } from 'deox';

import {
  TDisconnectKakaoResponse,
  TGetManagerTalksHistoriesResponse,
  TGetManagerTalksNotificationResponse,
  TGetManagerTalksNotificationsResponse,
  TGetManagerTalksSentsResponse,
  TGetManagerTalksSettingResponse,
  TGetManagerTalksSettingsResponse,
  TLoginKakaoResponse,
  TSaveManagerTalkSettingResponse,
  TSetKakaoChannelResponse,
  TSetManagerTalksKakaoChannelResponse,
  TUpdateManagerTalksNotificationResponse,
} from '@/services/api/talks';
import {
  disconnectKakaoAction,
  getManagerTalksHistoriesAction,
  getManagerTalksNotificationAction,
  getManagerTalksNotificationsAction,
  getManagerTalksSentsAction,
  getManagerTalksSettingAction,
  getManagerTalksSettingsAction,
  loginKakaoAction,
  saveManagerTalkSettingAction,
  setKakaoChannelAction,
  setManagerTalksKakaoChannelAction,
  updateManagerTalksNotificationAction,
} from '@/redux/actions';
import { disconnectKakaoUpdateState } from './disconnect-kakao';
import { getManagerTalksHistoriesUpdateState } from './get-manager-talks-histories';
import { getManagerTalksNotificationUpdateState } from './get-manager-talks-notification';
import { getManagerTalksNotificationsUpdateState } from './get-manager-talks-notifications';
import { getManagerTalksSentsUpdateState } from './get-manager-talks-sents';
import { getManagerTalksSettingUpdateState } from './get-manager-talks-setting';
import { getManagerTalksSettingsUpdateState } from './get-manager-talks-settings';
import { loginKakaoUpdateState } from './login-kakao';
import { saveManagerTalkSettingUpdateState } from './save-manager-talk-setting';
import { setKakaoChannelUpdateState } from './set-kakao-channel';
import { setManagerTalksKakaoChannelUpdateState } from './set-manager-talks-kakao-channel';
import { updateManagerTalksNotificationUpdateState } from './update-manager-talks-notification';

export type TTalksState = {
  disconnectKakaoResponse?: TDisconnectKakaoResponse;
  getManagerTalksHistoriesResponse?: TGetManagerTalksHistoriesResponse;
  getManagerTalksNotificationResponse?: TGetManagerTalksNotificationResponse;
  getManagerTalksNotificationsResponse?: TGetManagerTalksNotificationsResponse;
  getManagerTalksSentsResponse?: TGetManagerTalksSentsResponse;
  getManagerTalksSettingResponse?: TGetManagerTalksSettingResponse;
  getManagerTalksSettingsResponse?: TGetManagerTalksSettingsResponse;
  loginKakaoResponse?: TLoginKakaoResponse;
  saveManagerTalkSettingResponse?: TSaveManagerTalkSettingResponse;
  setKakaoChannelResponse?: TSetKakaoChannelResponse;
  setManagerTalksKakaoChannelResponse?: TSetManagerTalksKakaoChannelResponse;
  updateManagerTalksNotificationResponse?: TUpdateManagerTalksNotificationResponse;
};

const initialState: TTalksState = {
  disconnectKakaoResponse: undefined,
  getManagerTalksHistoriesResponse: undefined,
  getManagerTalksNotificationResponse: undefined,
  getManagerTalksNotificationsResponse: undefined,
  getManagerTalksSentsResponse: undefined,
  getManagerTalksSettingResponse: undefined,
  getManagerTalksSettingsResponse: undefined,
  loginKakaoResponse: undefined,
  saveManagerTalkSettingResponse: undefined,
  setKakaoChannelResponse: undefined,
  setManagerTalksKakaoChannelResponse: undefined,
  updateManagerTalksNotificationResponse: undefined,
};

const TalksReducer = createReducer(initialState, (handleAction) => [
  handleAction(disconnectKakaoAction.success, disconnectKakaoUpdateState),
  handleAction(getManagerTalksHistoriesAction.success, getManagerTalksHistoriesUpdateState),
  handleAction(getManagerTalksNotificationAction.success, getManagerTalksNotificationUpdateState),
  handleAction(getManagerTalksNotificationsAction.success, getManagerTalksNotificationsUpdateState),
  handleAction(getManagerTalksSentsAction.success, getManagerTalksSentsUpdateState),
  handleAction(getManagerTalksSettingAction.success, getManagerTalksSettingUpdateState),
  handleAction(getManagerTalksSettingsAction.success, getManagerTalksSettingsUpdateState),
  handleAction(loginKakaoAction.success, loginKakaoUpdateState),
  handleAction(saveManagerTalkSettingAction.success, saveManagerTalkSettingUpdateState),
  handleAction(setKakaoChannelAction.success, setKakaoChannelUpdateState),
  handleAction(setManagerTalksKakaoChannelAction.success, setManagerTalksKakaoChannelUpdateState),
  handleAction(updateManagerTalksNotificationAction.success, updateManagerTalksNotificationUpdateState),
]);

export default TalksReducer;
