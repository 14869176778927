import ApiService from '@/services/api';

// TYPES

export type TSetManagerTalksKakaoChannelBody = {
  kakaoAccount?: string;
  kakaoChannelId: string;
  phoneNumber?: string;
  kakaoChannelName: string;
};

export type TSetManagerTalksKakaoChannelMaterials = {
  body?: TSetManagerTalksKakaoChannelBody;
};

export type TSetManagerTalksKakaoChannelResponse = unknown;

// FUNCTION

export const setManagerTalksKakaoChannel = async ({
  body,
}: TSetManagerTalksKakaoChannelMaterials): Promise<TSetManagerTalksKakaoChannelResponse> => {
  const response = await ApiService.patch(`/admin/talks/set-kakao-channel`, body);
  return response.data;
};
