import ApiService from '@/services/api';

// TYPES

export type TUpdateScheduleCustomerPaths = {
  id: string | number;
};
export type TUpdateScheduleCustomerBody = {
  customer?: string;
  visitDate?: string;
  procedures?: string[];
  startTime?: string;
  procedureTime?: string;
  manager?: string;
  treatment?: string;
  note?: string;
};

export type TUpdateScheduleCustomerMaterials = {
  paths?: TUpdateScheduleCustomerPaths;
  body?: TUpdateScheduleCustomerBody;
};

export type TUpdateScheduleCustomerResponse = unknown;

// FUNCTION

export const updateScheduleCustomer = async ({
  paths,
  body,
}: TUpdateScheduleCustomerMaterials): Promise<TUpdateScheduleCustomerResponse> => {
  const response = await ApiService.patch(`/admin/managements/schedules/${paths?.id}`, body);
  return response.data;
};
