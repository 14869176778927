import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getNoticesAction } from '@/redux/actions';
import { getNotices, TGetNoticesResponse } from '@/services/api';

// FUNCTION

export function* getNoticesSaga(action: ActionType<typeof getNoticesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getNotices, materials);
    const getNoticesResponse: TGetNoticesResponse = response as TGetNoticesResponse;
    yield put(getNoticesAction.success(getNoticesResponse));
    successCallback?.(getNoticesResponse);
  } catch (err) {
    yield put(getNoticesAction.failure(err));
    failedCallback?.(err);
  }
}
