import { TManagerTalkSetting } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetManagerTalksSettingsParams = unknown;

export type TGetManagerTalksSettingsMaterials = {
  params?: TGetManagerTalksSettingsParams;
};

export type TGetManagerTalksSettingsResponse = TManagerTalkSetting[];

// FUNCTION

export const getManagerTalksSettings = async ({
  params,
}: TGetManagerTalksSettingsMaterials): Promise<TGetManagerTalksSettingsResponse> => {
  const response = await ApiService.get(`/admin/talks/all`, { params });
  return response.data;
};
