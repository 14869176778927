import { all, takeLatest } from 'redux-saga/effects';

import {
  disconnectKakaoAction,
  getManagerTalksHistoriesAction,
  getManagerTalksNotificationAction,
  getManagerTalksNotificationsAction,
  getManagerTalksSentsAction,
  getManagerTalksSettingAction,
  getManagerTalksSettingsAction,
  loginKakaoAction,
  saveManagerTalkSettingAction,
  setKakaoChannelAction,
  setManagerTalksKakaoChannelAction,
  updateManagerTalksNotificationAction,
} from '@/redux/actions';

import { disconnectKakaoSaga } from './disconnect-kakao';
import { getManagerTalksHistoriesSaga } from './get-manager-talks-histories';
import { getManagerTalksNotificationSaga } from './get-manager-talks-notification';
import { getManagerTalksNotificationsSaga } from './get-manager-talks-notifications';
import { getManagerTalksSentsSaga } from './get-manager-talks-sents';
import { getManagerTalksSettingSaga } from './get-manager-talks-setting';
import { getManagerTalksSettingsSaga } from './get-manager-talks-settings';
import { loginKakaoSaga } from './login-kakao';
import { saveManagerTalkSettingSaga } from './save-manager-talk-setting';
import { setKakaoChannelSaga } from './set-kakao-channel';
import { setManagerTalksKakaoChannelSaga } from './set-manager-talks-kakao-channel';
import { updateManagerTalksNotificationSaga } from './update-manager-talks-notification';

export default function* root(): Generator {
  yield all([
    takeLatest(disconnectKakaoAction.request.type, disconnectKakaoSaga),
    takeLatest(getManagerTalksHistoriesAction.request.type, getManagerTalksHistoriesSaga),
    takeLatest(getManagerTalksNotificationAction.request.type, getManagerTalksNotificationSaga),
    takeLatest(getManagerTalksNotificationsAction.request.type, getManagerTalksNotificationsSaga),
    takeLatest(getManagerTalksSentsAction.request.type, getManagerTalksSentsSaga),
    takeLatest(getManagerTalksSettingAction.request.type, getManagerTalksSettingSaga),
    takeLatest(getManagerTalksSettingsAction.request.type, getManagerTalksSettingsSaga),
    takeLatest(loginKakaoAction.request.type, loginKakaoSaga),
    takeLatest(saveManagerTalkSettingAction.request.type, saveManagerTalkSettingSaga),
    takeLatest(setKakaoChannelAction.request.type, setKakaoChannelSaga),
    takeLatest(setManagerTalksKakaoChannelAction.request.type, setManagerTalksKakaoChannelSaga),
    takeLatest(updateManagerTalksNotificationAction.request.type, updateManagerTalksNotificationSaga),
  ]);
}
