import { createActionCreator } from 'deox';

import { TGetSalesMaterials, TGetSalesResponse } from '@/services/api/sale/get-sales';

// CONSTANTS

export enum EGetSalesAction {
  GET_SALES = 'GET_SALES',
  GET_SALES_REQUEST = 'GET_SALES_REQUEST',
  GET_SALES_SUCCESS = 'GET_SALES_SUCCESS',
  GET_SALES_FAILED = 'GET_SALES_FAILED',
}

// TYPES

export type TGetSalesRequest = {
  type: EGetSalesAction.GET_SALES_REQUEST;
  payload: {
    materials: TGetSalesMaterials;
    successCallback?: (response: TGetSalesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetSalesSuccess = {
  type: EGetSalesAction.GET_SALES_SUCCESS;
  payload: { response: TGetSalesResponse };
};

export type TGetSalesFailed = { type: EGetSalesAction.GET_SALES_FAILED };

// FUNCTION

export const getSalesAction = {
  request: createActionCreator(
    EGetSalesAction.GET_SALES_REQUEST,
    (resolve) =>
      (
        materials: TGetSalesMaterials,
        successCallback?: (response: TGetSalesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetSalesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetSalesAction.GET_SALES_SUCCESS,
    (resolve) =>
      (response: TGetSalesResponse): TGetSalesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetSalesAction.GET_SALES_FAILED,
    (resolve) =>
      (error: unknown): TGetSalesFailed =>
        resolve({ error }),
  ),
};
