import ApiService from '@/services/api';

// TYPES

export type TCancelSalePaymentPaths = {
  id: string | number;
};
export type TCancelSalePaymentBody = unknown;

export type TCancelSalePaymentMaterials = {
  paths?: TCancelSalePaymentPaths;
  body?: TCancelSalePaymentBody;
};

export type TCancelSalePaymentResponse = unknown;

// FUNCTION

export const cancelSalePayment = async ({
  paths,
  body,
}: TCancelSalePaymentMaterials): Promise<TCancelSalePaymentResponse> => {
  const response = await ApiService.patch(`/admin/sales/cancel-payment/${paths?.id}`, body);
  return response.data;
};
