import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getStatisticsBranchAction } from '@/redux/actions';
import { getStatisticsBranch, TGetStatisticsBranchResponse } from '@/services/api';

// FUNCTION

export function* getStatisticsBranchSaga(action: ActionType<typeof getStatisticsBranchAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getStatisticsBranch, materials);
    const getStatisticsBranchResponse: TGetStatisticsBranchResponse = response as TGetStatisticsBranchResponse;
    yield put(getStatisticsBranchAction.success(getStatisticsBranchResponse));
    successCallback?.(getStatisticsBranchResponse);
  } catch (err) {
    yield put(getStatisticsBranchAction.failure(err));
    failedCallback?.(err);
  }
}
