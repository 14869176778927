import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="fill" d="M2 5C2 4.44772 2.44772 4 3 4H21C21.5523 4 22 4.44772 22 5V8H2V5Z" fill={color} />
      <path
        className="fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 10H2V21C2 21.5523 2.44772 22 3 22H21C21.5523 22 22 21.5523 22 21V10ZM20 14H14V20H20V14Z"
        fill={color}
      />
      <rect className="fill" x="6" y="2" width="2" height="2" fill={color} />
      <rect className="fill" x="16" y="2" width="2" height="2" fill={color} />
    </svg>
  );
};

export default Svg;
