import { TNotice } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetNoticeStaffPaths = {
  id: string | number;
};
export type TGetNoticeStaffParams = unknown;

export type TGetNoticeStaffMaterials = {
  paths?: TGetNoticeStaffPaths;
  params?: TGetNoticeStaffParams;
};

export type TGetNoticeStaffResponse = {
  data: TNotice;
  nextNotice?: TNotice;
  previousNotice?: TNotice;
};

// FUNCTION

export const getNoticeStaff = async ({ paths, params }: TGetNoticeStaffMaterials): Promise<TGetNoticeStaffResponse> => {
  const response = await ApiService.get(`/admin/notices/staffs/${paths?.id}`, { params });
  return response.data;
};
