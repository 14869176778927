import { createActionCreator } from 'deox';

import { TGetNoticesStaffMaterials, TGetNoticesStaffResponse } from '@/services/api/notice/get-notices-staff';

// CONSTANTS

export enum EGetNoticesStaffAction {
  GET_NOTICES_STAFF = 'GET_NOTICES_STAFF',
  GET_NOTICES_STAFF_REQUEST = 'GET_NOTICES_STAFF_REQUEST',
  GET_NOTICES_STAFF_SUCCESS = 'GET_NOTICES_STAFF_SUCCESS',
  GET_NOTICES_STAFF_FAILED = 'GET_NOTICES_STAFF_FAILED',
}

// TYPES

export type TGetNoticesStaffRequest = {
  type: EGetNoticesStaffAction.GET_NOTICES_STAFF_REQUEST;
  payload: {
    materials: TGetNoticesStaffMaterials;
    successCallback?: (response: TGetNoticesStaffResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetNoticesStaffSuccess = {
  type: EGetNoticesStaffAction.GET_NOTICES_STAFF_SUCCESS;
  payload: { response: TGetNoticesStaffResponse };
};

export type TGetNoticesStaffFailed = { type: EGetNoticesStaffAction.GET_NOTICES_STAFF_FAILED };

// FUNCTION

export const getNoticesStaffAction = {
  request: createActionCreator(
    EGetNoticesStaffAction.GET_NOTICES_STAFF_REQUEST,
    (resolve) =>
      (
        materials: TGetNoticesStaffMaterials,
        successCallback?: (response: TGetNoticesStaffResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetNoticesStaffRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetNoticesStaffAction.GET_NOTICES_STAFF_SUCCESS,
    (resolve) =>
      (response: TGetNoticesStaffResponse): TGetNoticesStaffSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetNoticesStaffAction.GET_NOTICES_STAFF_FAILED,
    (resolve) =>
      (error: unknown): TGetNoticesStaffFailed =>
        resolve({ error }),
  ),
};
