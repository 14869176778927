import { TTalksState } from '@/redux/reducers/talks';
import { TGetManagerTalksSentsSuccess } from '@/redux/actions/talks';

export const getManagerTalksSentsUpdateState = (
  state: TTalksState,
  action: TGetManagerTalksSentsSuccess,
): TTalksState => ({
  ...state,
  getManagerTalksSentsResponse: action.payload.response,
});
