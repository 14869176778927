import { TTalksState } from '@/redux/reducers/talks';
import { TGetManagerTalksNotificationsSuccess } from '@/redux/actions/talks';

export const getManagerTalksNotificationsUpdateState = (
  state: TTalksState,
  action: TGetManagerTalksNotificationsSuccess,
): TTalksState => ({
  ...state,
  getManagerTalksNotificationsResponse: action.payload.response,
});
