import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getAdminBranchAction } from '@/redux/actions';
import { getAdminBranch, TGetAdminBranchResponse } from '@/services/api';

// FUNCTION

export function* getAdminBranchSaga(action: ActionType<typeof getAdminBranchAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getAdminBranch, materials);
    const getAdminBranchResponse: TGetAdminBranchResponse = response as TGetAdminBranchResponse;
    yield put(getAdminBranchAction.success(getAdminBranchResponse));
    successCallback?.(getAdminBranchResponse);
  } catch (err) {
    yield put(getAdminBranchAction.failure(err));
    failedCallback?.(err);
  }
}
