import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { adminLoginAction } from '@/redux/actions';
import { adminLogin, TAdminLoginResponse } from '@/services/api';
import Helpers from '@/services/helpers';

// FUNCTION

export function* adminLoginSaga(action: ActionType<typeof adminLoginAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(adminLogin, materials);
    const adminLoginResponse: TAdminLoginResponse = response as TAdminLoginResponse;

    Helpers.storeAccessToken(adminLoginResponse.jwtAccessToken);
    Helpers.storeRefreshToken('');

    yield put(adminLoginAction.success(adminLoginResponse));
    successCallback?.(adminLoginResponse);
  } catch (err) {
    yield put(adminLoginAction.failure(err));
    failedCallback?.(err);
  }
}
