import { createActionCreator } from 'deox';

import {
  TGetStatisticsBranchMaterials,
  TGetStatisticsBranchResponse,
} from '@/services/api/statistic/get-statistics-branch';

// CONSTANTS

export enum EGetStatisticsBranchAction {
  GET_STATISTICS_BRANCH = 'GET_STATISTICS_BRANCH',
  GET_STATISTICS_BRANCH_REQUEST = 'GET_STATISTICS_BRANCH_REQUEST',
  GET_STATISTICS_BRANCH_SUCCESS = 'GET_STATISTICS_BRANCH_SUCCESS',
  GET_STATISTICS_BRANCH_FAILED = 'GET_STATISTICS_BRANCH_FAILED',
}

// TYPES

export type TGetStatisticsBranchRequest = {
  type: EGetStatisticsBranchAction.GET_STATISTICS_BRANCH_REQUEST;
  payload: {
    materials: TGetStatisticsBranchMaterials;
    successCallback?: (response: TGetStatisticsBranchResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetStatisticsBranchSuccess = {
  type: EGetStatisticsBranchAction.GET_STATISTICS_BRANCH_SUCCESS;
  payload: { response: TGetStatisticsBranchResponse };
};

export type TGetStatisticsBranchFailed = { type: EGetStatisticsBranchAction.GET_STATISTICS_BRANCH_FAILED };

// FUNCTION

export const getStatisticsBranchAction = {
  request: createActionCreator(
    EGetStatisticsBranchAction.GET_STATISTICS_BRANCH_REQUEST,
    (resolve) =>
      (
        materials: TGetStatisticsBranchMaterials,
        successCallback?: (response: TGetStatisticsBranchResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetStatisticsBranchRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetStatisticsBranchAction.GET_STATISTICS_BRANCH_SUCCESS,
    (resolve) =>
      (response: TGetStatisticsBranchResponse): TGetStatisticsBranchSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetStatisticsBranchAction.GET_STATISTICS_BRANCH_FAILED,
    (resolve) =>
      (error: unknown): TGetStatisticsBranchFailed =>
        resolve({ error }),
  ),
};
