import { TCustomer } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetCustomerPaths = {
  id: string | number;
};
export type TGetCustomerParams = unknown;

export type TGetCustomerMaterials = {
  paths?: TGetCustomerPaths;
  params?: TGetCustomerParams;
};

export type TGetCustomerResponse = TCustomer;

// FUNCTION

export const getCustomer = async ({ paths, params }: TGetCustomerMaterials): Promise<TGetCustomerResponse> => {
  const response = await ApiService.get(`/admin/customers/${paths?.id}`, { params });
  return response.data;
};
