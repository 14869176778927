import { createReducer } from 'deox';

import { TGetSalesSettingResponse, TUpdateSalesSettingResponse } from '@/services/api/sales-setting';
import { getSalesSettingAction, updateSalesSettingAction } from '@/redux/actions';
import { getSalesSettingUpdateState } from './get-sales-setting';
import { updateSalesSettingUpdateState } from './update-sales-setting';

export type TSalesSettingState = {
  getSalesSettingResponse?: TGetSalesSettingResponse;
  updateSalesSettingResponse?: TUpdateSalesSettingResponse;
};

const initialState: TSalesSettingState = {
  getSalesSettingResponse: undefined,
  updateSalesSettingResponse: undefined,
};

const SalesSettingReducer = createReducer(initialState, (handleAction) => [
  handleAction(getSalesSettingAction.success, getSalesSettingUpdateState),
  handleAction(updateSalesSettingAction.success, updateSalesSettingUpdateState),
]);

export default SalesSettingReducer;
