import { createActionCreator } from 'deox';

import {
  TGetStatisticsEmployeesMaterials,
  TGetStatisticsEmployeesResponse,
} from '@/services/api/statistic/get-statistics-employees';

// CONSTANTS

export enum EGetStatisticsEmployeesAction {
  GET_STATISTICS_EMPLOYEES = 'GET_STATISTICS_EMPLOYEES',
  GET_STATISTICS_EMPLOYEES_REQUEST = 'GET_STATISTICS_EMPLOYEES_REQUEST',
  GET_STATISTICS_EMPLOYEES_SUCCESS = 'GET_STATISTICS_EMPLOYEES_SUCCESS',
  GET_STATISTICS_EMPLOYEES_FAILED = 'GET_STATISTICS_EMPLOYEES_FAILED',
}

// TYPES

export type TGetStatisticsEmployeesRequest = {
  type: EGetStatisticsEmployeesAction.GET_STATISTICS_EMPLOYEES_REQUEST;
  payload: {
    materials: TGetStatisticsEmployeesMaterials;
    successCallback?: (response: TGetStatisticsEmployeesResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetStatisticsEmployeesSuccess = {
  type: EGetStatisticsEmployeesAction.GET_STATISTICS_EMPLOYEES_SUCCESS;
  payload: { response: TGetStatisticsEmployeesResponse };
};

export type TGetStatisticsEmployeesFailed = { type: EGetStatisticsEmployeesAction.GET_STATISTICS_EMPLOYEES_FAILED };

// FUNCTION

export const getStatisticsEmployeesAction = {
  request: createActionCreator(
    EGetStatisticsEmployeesAction.GET_STATISTICS_EMPLOYEES_REQUEST,
    (resolve) =>
      (
        materials: TGetStatisticsEmployeesMaterials,
        successCallback?: (response: TGetStatisticsEmployeesResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetStatisticsEmployeesRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetStatisticsEmployeesAction.GET_STATISTICS_EMPLOYEES_SUCCESS,
    (resolve) =>
      (response: TGetStatisticsEmployeesResponse): TGetStatisticsEmployeesSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetStatisticsEmployeesAction.GET_STATISTICS_EMPLOYEES_FAILED,
    (resolve) =>
      (error: unknown): TGetStatisticsEmployeesFailed =>
        resolve({ error }),
  ),
};
