import { createActionCreator } from 'deox';

import {
  TGetManagerTalksNotificationsMaterials,
  TGetManagerTalksNotificationsResponse,
} from '@/services/api/talks/get-manager-talks-notifications';

// CONSTANTS

export enum EGetManagerTalksNotificationsAction {
  GET_MANAGER_TALKS_NOTIFICATIONS = 'GET_MANAGER_TALKS_NOTIFICATIONS',
  GET_MANAGER_TALKS_NOTIFICATIONS_REQUEST = 'GET_MANAGER_TALKS_NOTIFICATIONS_REQUEST',
  GET_MANAGER_TALKS_NOTIFICATIONS_SUCCESS = 'GET_MANAGER_TALKS_NOTIFICATIONS_SUCCESS',
  GET_MANAGER_TALKS_NOTIFICATIONS_FAILED = 'GET_MANAGER_TALKS_NOTIFICATIONS_FAILED',
}

// TYPES

export type TGetManagerTalksNotificationsRequest = {
  type: EGetManagerTalksNotificationsAction.GET_MANAGER_TALKS_NOTIFICATIONS_REQUEST;
  payload: {
    materials: TGetManagerTalksNotificationsMaterials;
    successCallback?: (response: TGetManagerTalksNotificationsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetManagerTalksNotificationsSuccess = {
  type: EGetManagerTalksNotificationsAction.GET_MANAGER_TALKS_NOTIFICATIONS_SUCCESS;
  payload: { response: TGetManagerTalksNotificationsResponse };
};

export type TGetManagerTalksNotificationsFailed = {
  type: EGetManagerTalksNotificationsAction.GET_MANAGER_TALKS_NOTIFICATIONS_FAILED;
};

// FUNCTION

export const getManagerTalksNotificationsAction = {
  request: createActionCreator(
    EGetManagerTalksNotificationsAction.GET_MANAGER_TALKS_NOTIFICATIONS_REQUEST,
    (resolve) =>
      (
        materials: TGetManagerTalksNotificationsMaterials,
        successCallback?: (response: TGetManagerTalksNotificationsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetManagerTalksNotificationsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetManagerTalksNotificationsAction.GET_MANAGER_TALKS_NOTIFICATIONS_SUCCESS,
    (resolve) =>
      (response: TGetManagerTalksNotificationsResponse): TGetManagerTalksNotificationsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetManagerTalksNotificationsAction.GET_MANAGER_TALKS_NOTIFICATIONS_FAILED,
    (resolve) =>
      (error: unknown): TGetManagerTalksNotificationsFailed =>
        resolve({ error }),
  ),
};
