import { createReducer } from 'deox';
import { uiActions } from '@/redux/actions';

export const BREAKPOINT = 991;

export enum EDeviceType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

const initialState: any = {
  device: {
    type: window.innerWidth > BREAKPOINT ? EDeviceType.DESKTOP : EDeviceType.MOBILE,
    width: window.innerWidth,
    isMobile: window.innerWidth <= BREAKPOINT,
  },
  scroll: { x: 0, y: 0 },
  visibleModalCustomerDetail: false,
  socket: undefined,
};

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(uiActions.setDevice, (state, { payload }) => ({
    ...state,
    device: {
      type: payload.deviceWidth > BREAKPOINT ? EDeviceType.DESKTOP : EDeviceType.MOBILE,
      width: payload.deviceWidth,
      isMobile: payload.deviceWidth <= BREAKPOINT,
    },
  })),
  handleAction(uiActions.setScroll, (state, { payload }) => ({
    ...state,
    scroll: {
      x: payload.data.x,
      y: payload.data.y,
    },
  })),
  handleAction(uiActions.setVisibleModalCustomerDetail, (state, { payload }) => ({
    ...state,
    visibleModalCustomerDetail: payload.visible,
  })),
  handleAction(uiActions.initialSocket, (state, { payload }) => {
    return {
      ...state,
      socket: payload?.socket,
    };
  }),
]);

export default reducer;
