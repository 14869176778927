import { createActionCreator } from 'deox';

import { TDeleteProcedureMaterials, TDeleteProcedureResponse } from '@/services/api/procedure/delete-procedure';

// CONSTANTS

export enum EDeleteProcedureAction {
  DELETE_PROCEDURE = 'DELETE_PROCEDURE',
  DELETE_PROCEDURE_REQUEST = 'DELETE_PROCEDURE_REQUEST',
  DELETE_PROCEDURE_SUCCESS = 'DELETE_PROCEDURE_SUCCESS',
  DELETE_PROCEDURE_FAILED = 'DELETE_PROCEDURE_FAILED',
}

// TYPES

export type TDeleteProcedureRequest = {
  type: EDeleteProcedureAction.DELETE_PROCEDURE_REQUEST;
  payload: {
    materials: TDeleteProcedureMaterials;
    successCallback?: (response: TDeleteProcedureResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteProcedureSuccess = {
  type: EDeleteProcedureAction.DELETE_PROCEDURE_SUCCESS;
  payload: { response: TDeleteProcedureResponse };
};

export type TDeleteProcedureFailed = { type: EDeleteProcedureAction.DELETE_PROCEDURE_FAILED };

// FUNCTION

export const deleteProcedureAction = {
  request: createActionCreator(
    EDeleteProcedureAction.DELETE_PROCEDURE_REQUEST,
    (resolve) =>
      (
        materials: TDeleteProcedureMaterials,
        successCallback?: (response: TDeleteProcedureResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteProcedureRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteProcedureAction.DELETE_PROCEDURE_SUCCESS,
    (resolve) =>
      (response: TDeleteProcedureResponse): TDeleteProcedureSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteProcedureAction.DELETE_PROCEDURE_FAILED,
    (resolve) =>
      (error: unknown): TDeleteProcedureFailed =>
        resolve({ error }),
  ),
};
