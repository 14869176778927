import { TCustomerState } from '@/redux/reducers/customer';
import { TRefundTicketCustomerSuccess } from '@/redux/actions/customer';

export const refundTicketCustomerUpdateState = (
  state: TCustomerState,
  action: TRefundTicketCustomerSuccess,
): TCustomerState => ({
  ...state,
  refundTicketCustomerResponse: action.payload.response,
});
