import { TBranch } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetAdminBranchParams = unknown;

export type TGetAdminBranchMaterials = {
  params?: TGetAdminBranchParams;
};

export type TGetAdminBranchResponse = TBranch[];

// FUNCTION

export const getAdminBranch = async ({ params }: TGetAdminBranchMaterials): Promise<TGetAdminBranchResponse> => {
  const response = await ApiService.get(`/admin/branch`, { params });
  return response.data;
};
