import ApiService from '@/services/api';

// TYPES

export type TCreateTicketParams = unknown;

export type TCreateTicketBody = {
  name: string;
  memo: string;
  dateUse?: number;
  price: number;
  chargeBalance: number;
};

export type TCreateTicketMaterials = {
  params?: TCreateTicketParams;
  body?: TCreateTicketBody;
};

export type TCreateTicketResponse = unknown;

// FUNCTION

export const createTicket = async ({ params, body }: TCreateTicketMaterials): Promise<TCreateTicketResponse> => {
  const response = await ApiService.post(`/admin/tickets`, body, { params });
  return response.data;
};
