import { TStatisticState } from '@/redux/reducers/statistic';
import { TChangeMonthlyTargetSalesSuccess } from '@/redux/actions/statistic';

export const changeMonthlyTargetSalesUpdateState = (
  state: TStatisticState,
  action: TChangeMonthlyTargetSalesSuccess,
): TStatisticState => ({
  ...state,
  changeMonthlyTargetSalesResponse: action.payload.response,
});
