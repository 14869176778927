import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getStaffsAction } from '@/redux/actions';
import { getStaffs, TGetStaffsResponse } from '@/services/api';

// FUNCTION

export function* getStaffsSaga(action: ActionType<typeof getStaffsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getStaffs, materials);
    const getStaffsResponse: TGetStaffsResponse = response as TGetStaffsResponse;
    yield put(getStaffsAction.success(getStaffsResponse));
    successCallback?.(getStaffsResponse);
  } catch (err) {
    yield put(getStaffsAction.failure(err));
    failedCallback?.(err);
  }
}
