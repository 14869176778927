import ApiService from '@/services/api';

// TYPES

export type TUpdateSchedulePersonalPaths = {
  id: string | number;
};
export type TUpdateSchedulePersonalBody = unknown;

export type TUpdateSchedulePersonalMaterials = {
  paths?: TUpdateSchedulePersonalPaths;
  body?: TUpdateSchedulePersonalBody;
};

export type TUpdateSchedulePersonalResponse = unknown;

// FUNCTION

export const updateSchedulePersonal = async ({
  paths,
  body,
}: TUpdateSchedulePersonalMaterials): Promise<TUpdateSchedulePersonalResponse> => {
  const response = await ApiService.patch(`/admin/managements/schedules/personal/${paths?.id}`, body);
  return response.data;
};
