import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getCustomersAction } from '@/redux/actions';
import { getCustomers, TGetCustomersResponse } from '@/services/api';

// FUNCTION

export function* getCustomersSaga(action: ActionType<typeof getCustomersAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getCustomers, materials);
    const getCustomersResponse: TGetCustomersResponse = response as TGetCustomersResponse;
    yield put(getCustomersAction.success(getCustomersResponse));
    successCallback?.(getCustomersResponse);
  } catch (err) {
    yield put(getCustomersAction.failure(err));
    failedCallback?.(err);
  }
}
