import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteProcedureAction } from '@/redux/actions';
import { deleteProcedure, TDeleteProcedureResponse } from '@/services/api';

// FUNCTION

export function* deleteProcedureSaga(action: ActionType<typeof deleteProcedureAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteProcedure, materials);
    const deleteProcedureResponse: TDeleteProcedureResponse = response as TDeleteProcedureResponse;
    yield put(deleteProcedureAction.success(deleteProcedureResponse));
    successCallback?.(deleteProcedureResponse);
  } catch (err) {
    yield put(deleteProcedureAction.failure(err));
    failedCallback?.(err);
  }
}
