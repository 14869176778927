import { createActionCreator } from 'deox';

import {
  TUpdateSchedulePersonalMaterials,
  TUpdateSchedulePersonalResponse,
} from '@/services/api/schedule/update-schedule-personal';

// CONSTANTS

export enum EUpdateSchedulePersonalAction {
  UPDATE_SCHEDULE_PERSONAL = 'UPDATE_SCHEDULE_PERSONAL',
  UPDATE_SCHEDULE_PERSONAL_REQUEST = 'UPDATE_SCHEDULE_PERSONAL_REQUEST',
  UPDATE_SCHEDULE_PERSONAL_SUCCESS = 'UPDATE_SCHEDULE_PERSONAL_SUCCESS',
  UPDATE_SCHEDULE_PERSONAL_FAILED = 'UPDATE_SCHEDULE_PERSONAL_FAILED',
}

// TYPES

export type TUpdateSchedulePersonalRequest = {
  type: EUpdateSchedulePersonalAction.UPDATE_SCHEDULE_PERSONAL_REQUEST;
  payload: {
    materials: TUpdateSchedulePersonalMaterials;
    successCallback?: (response: TUpdateSchedulePersonalResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateSchedulePersonalSuccess = {
  type: EUpdateSchedulePersonalAction.UPDATE_SCHEDULE_PERSONAL_SUCCESS;
  payload: { response: TUpdateSchedulePersonalResponse };
};

export type TUpdateSchedulePersonalFailed = { type: EUpdateSchedulePersonalAction.UPDATE_SCHEDULE_PERSONAL_FAILED };

// FUNCTION

export const updateSchedulePersonalAction = {
  request: createActionCreator(
    EUpdateSchedulePersonalAction.UPDATE_SCHEDULE_PERSONAL_REQUEST,
    (resolve) =>
      (
        materials: TUpdateSchedulePersonalMaterials,
        successCallback?: (response: TUpdateSchedulePersonalResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateSchedulePersonalRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateSchedulePersonalAction.UPDATE_SCHEDULE_PERSONAL_SUCCESS,
    (resolve) =>
      (response: TUpdateSchedulePersonalResponse): TUpdateSchedulePersonalSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateSchedulePersonalAction.UPDATE_SCHEDULE_PERSONAL_FAILED,
    (resolve) =>
      (error: unknown): TUpdateSchedulePersonalFailed =>
        resolve({ error }),
  ),
};
