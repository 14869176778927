import { createReducer } from 'deox';

import {
  TCreateNoticeResponse,
  TDeleteNoticeResponse,
  TGetNoticeStaffResponse,
  TGetNoticeResponse,
  TGetNoticesStaffResponse,
  TGetNoticesResponse,
  TUpdateNoticeResponse,
} from '@/services/api/notice';
import {
  createNoticeAction,
  deleteNoticeAction,
  getNoticeStaffAction,
  getNoticeAction,
  getNoticesStaffAction,
  getNoticesAction,
  updateNoticeAction,
} from '@/redux/actions';
import { createNoticeUpdateState } from './create-notice';
import { deleteNoticeUpdateState } from './delete-notice';
import { getNoticeStaffUpdateState } from './get-notice-staff';
import { getNoticeUpdateState } from './get-notice';
import { getNoticesStaffUpdateState } from './get-notices-staff';
import { getNoticesUpdateState } from './get-notices';
import { updateNoticeUpdateState } from './update-notice';

export type TNoticeState = {
  createNoticeResponse?: TCreateNoticeResponse;
  deleteNoticeResponse?: TDeleteNoticeResponse;
  getNoticeStaffResponse?: TGetNoticeStaffResponse;
  getNoticeResponse?: TGetNoticeResponse;
  getNoticesStaffResponse?: TGetNoticesStaffResponse;
  getNoticesResponse?: TGetNoticesResponse;
  updateNoticeResponse?: TUpdateNoticeResponse;
};

const initialState: TNoticeState = {
  createNoticeResponse: undefined,
  deleteNoticeResponse: undefined,
  getNoticeStaffResponse: undefined,
  getNoticeResponse: undefined,
  getNoticesStaffResponse: undefined,
  getNoticesResponse: undefined,
  updateNoticeResponse: undefined,
};

const NoticeReducer = createReducer(initialState, (handleAction) => [
  handleAction(createNoticeAction.success, createNoticeUpdateState),
  handleAction(deleteNoticeAction.success, deleteNoticeUpdateState),
  handleAction(getNoticeStaffAction.success, getNoticeStaffUpdateState),
  handleAction(getNoticeAction.success, getNoticeUpdateState),
  handleAction(getNoticesStaffAction.success, getNoticesStaffUpdateState),
  handleAction(getNoticesAction.success, getNoticesUpdateState),
  handleAction(updateNoticeAction.success, updateNoticeUpdateState),
]);

export default NoticeReducer;
