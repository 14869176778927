import { createActionCreator } from 'deox';

import {
  TUpdateManagerTalksNotificationMaterials,
  TUpdateManagerTalksNotificationResponse,
} from '@/services/api/talks/update-manager-talks-notification';

// CONSTANTS

export enum EUpdateManagerTalksNotificationAction {
  UPDATE_MANAGER_TALKS_NOTIFICATION = 'UPDATE_MANAGER_TALKS_NOTIFICATION',
  UPDATE_MANAGER_TALKS_NOTIFICATION_REQUEST = 'UPDATE_MANAGER_TALKS_NOTIFICATION_REQUEST',
  UPDATE_MANAGER_TALKS_NOTIFICATION_SUCCESS = 'UPDATE_MANAGER_TALKS_NOTIFICATION_SUCCESS',
  UPDATE_MANAGER_TALKS_NOTIFICATION_FAILED = 'UPDATE_MANAGER_TALKS_NOTIFICATION_FAILED',
}

// TYPES

export type TUpdateManagerTalksNotificationRequest = {
  type: EUpdateManagerTalksNotificationAction.UPDATE_MANAGER_TALKS_NOTIFICATION_REQUEST;
  payload: {
    materials: TUpdateManagerTalksNotificationMaterials;
    successCallback?: (response: TUpdateManagerTalksNotificationResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateManagerTalksNotificationSuccess = {
  type: EUpdateManagerTalksNotificationAction.UPDATE_MANAGER_TALKS_NOTIFICATION_SUCCESS;
  payload: { response: TUpdateManagerTalksNotificationResponse };
};

export type TUpdateManagerTalksNotificationFailed = {
  type: EUpdateManagerTalksNotificationAction.UPDATE_MANAGER_TALKS_NOTIFICATION_FAILED;
};

// FUNCTION

export const updateManagerTalksNotificationAction = {
  request: createActionCreator(
    EUpdateManagerTalksNotificationAction.UPDATE_MANAGER_TALKS_NOTIFICATION_REQUEST,
    (resolve) =>
      (
        materials: TUpdateManagerTalksNotificationMaterials,
        successCallback?: (response: TUpdateManagerTalksNotificationResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateManagerTalksNotificationRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateManagerTalksNotificationAction.UPDATE_MANAGER_TALKS_NOTIFICATION_SUCCESS,
    (resolve) =>
      (response: TUpdateManagerTalksNotificationResponse): TUpdateManagerTalksNotificationSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateManagerTalksNotificationAction.UPDATE_MANAGER_TALKS_NOTIFICATION_FAILED,
    (resolve) =>
      (error: unknown): TUpdateManagerTalksNotificationFailed =>
        resolve({ error }),
  ),
};
