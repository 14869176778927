import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteNoticeAction } from '@/redux/actions';
import { deleteNotice, TDeleteNoticeResponse } from '@/services/api';

// FUNCTION

export function* deleteNoticeSaga(action: ActionType<typeof deleteNoticeAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteNotice, materials);
    const deleteNoticeResponse: TDeleteNoticeResponse = response as TDeleteNoticeResponse;
    yield put(deleteNoticeAction.success(deleteNoticeResponse));
    successCallback?.(deleteNoticeResponse);
  } catch (err) {
    yield put(deleteNoticeAction.failure(err));
    failedCallback?.(err);
  }
}
