import { TSale } from '@/common/models';
import { TPaginationResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetSalesParams = {
  page: number;
  pageSize: number;
  search?: string;
};

export type TGetSalesMaterials = {
  params?: TGetSalesParams;
};

export type TGetSalesResponse = {
  pagination: TPaginationResponse;
  data: TSale[];
};

// FUNCTION

export const getSales = async ({ params }: TGetSalesMaterials): Promise<TGetSalesResponse> => {
  const response = await ApiService.get(`/admin/sales`, { params });
  return response.data;
};
