import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { addTicketCustomerAction } from '@/redux/actions';
import { addTicketCustomer, TAddTicketCustomerResponse } from '@/services/api';

// FUNCTION

export function* addTicketCustomerSaga(action: ActionType<typeof addTicketCustomerAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(addTicketCustomer, materials);
    const addTicketCustomerResponse: TAddTicketCustomerResponse = response as TAddTicketCustomerResponse;
    yield put(addTicketCustomerAction.success(addTicketCustomerResponse));
    successCallback?.(addTicketCustomerResponse);
  } catch (err) {
    yield put(addTicketCustomerAction.failure(err));
    failedCallback?.(err);
  }
}
