import React, { useEffect } from 'react';
import { Redirect, Router, globalHistory } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import 'moment/locale/ko';
import { io } from 'socket.io-client';

import { AuthRoute, LayoutPaths, Pages, Paths, ProtectedRoute } from '@/pages/routers';
import Auth from '@/layouts/Auth';
import Admin from '@/layouts/Admin';
import { uiActions } from '@/redux/actions';
import { scrollToTop } from '@/utils/functions';
import { TRootState } from '@/redux/reducers';
import env from '@/env';

import './App.scss';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const socketState = useSelector((state: TRootState) => state.uiReducer.socket);

  globalHistory.listen(() => {
    scrollToTop();
  });

  useEffect(() => {
    const updateSize = (): void => {
      dispatch(uiActions.setDevice(window.innerWidth));
    };

    const updateScroll = (): void => {
      dispatch(uiActions.setScroll({ x: window.scrollX, y: window.scrollY }));
    };

    window.addEventListener('resize', updateSize);
    window.addEventListener('scroll', updateScroll);
    return (): void => {
      window.removeEventListener('resize', updateSize);
      window.removeEventListener('scroll', updateScroll);
    };
  }, [dispatch]);

  useEffect(() => {
    const socket = io(env.api.baseUrl.socket);
    dispatch(uiActions.initialSocket(socket));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return (): void => {
      socketState?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketState]);

  return (
    <div className="App">
      <Router primary={false}>
        <Auth path={LayoutPaths.Auth}>
          <AuthRoute path={Paths.Login} component={Pages.Login} />
          <Redirect noThrow from={Paths.Rest} to={`${LayoutPaths.Auth}${Paths.Login}`} />
        </Auth>

        <Admin path={LayoutPaths.Admin}>
          <ProtectedRoute path={Paths.Reservation()} component={Pages.Reservation} />
          <ProtectedRoute path={Paths.Procedures} component={Pages.Procedures} />
          <ProtectedRoute path={Paths.Sales} component={Pages.Sales} />
          <ProtectedRoute path={Paths.Customers} component={Pages.Customers} />
          <ProtectedRoute path={Paths.Notice} component={Pages.Notice} />
          <ProtectedRoute path={Paths.NoticeDetail()} component={Pages.NoticeDetail} />
          <ProtectedRoute path={Paths.NoticeManagement} component={Pages.NoticeManagement} />
          <ProtectedRoute path={Paths.Statistics} component={Pages.Statistics} />
          <ProtectedRoute path={Paths.StatisticsBranch} component={Pages.Statistics} branch />
          <ProtectedRoute path={Paths.Tickets} component={Pages.Tickets} />
          <ProtectedRoute path={Paths.Others} component={Pages.Others} />
          <ProtectedRoute path={Paths.ManagerTalk} component={Pages.ManagerTalk} />
          <ProtectedRoute path={Paths.RedirectReservationBranch} component={Pages.RedirectReservationBranch} />
          <ProtectedRoute path={Paths.KakaoCallbackLogin} component={Pages.KakaoCallbackLogin} />

          <Redirect noThrow from={Paths.Rest} to={`${LayoutPaths.Admin}${Paths.RedirectReservationBranch}`} />
        </Admin>
      </Router>
    </div>
  );
};

export default App;
