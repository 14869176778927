import { TTalksNotification } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetManagerTalksHistoriesPaths = {
  id: string | number;
};
export type TGetManagerTalksHistoriesParams = unknown;

export type TGetManagerTalksHistoriesMaterials = {
  paths?: TGetManagerTalksHistoriesPaths;
  params?: TGetManagerTalksHistoriesParams;
};

export type TGetManagerTalksHistoriesResponse = TTalksNotification[];

// FUNCTION

export const getManagerTalksHistories = async ({
  paths,
  params,
}: TGetManagerTalksHistoriesMaterials): Promise<TGetManagerTalksHistoriesResponse> => {
  const response = await ApiService.get(`/admin/talks/schedules/noti/histories/${paths?.id}`, { params });
  return response.data;
};
