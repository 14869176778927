import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { changeMonthlyTargetSalesAction } from '@/redux/actions';
import { changeMonthlyTargetSales, TChangeMonthlyTargetSalesResponse } from '@/services/api';

// FUNCTION

export function* changeMonthlyTargetSalesSaga(
  action: ActionType<typeof changeMonthlyTargetSalesAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(changeMonthlyTargetSales, materials);
    const changeMonthlyTargetSalesResponse: TChangeMonthlyTargetSalesResponse =
      response as TChangeMonthlyTargetSalesResponse;
    yield put(changeMonthlyTargetSalesAction.success(changeMonthlyTargetSalesResponse));
    successCallback?.(changeMonthlyTargetSalesResponse);
  } catch (err) {
    yield put(changeMonthlyTargetSalesAction.failure(err));
    failedCallback?.(err);
  }
}
