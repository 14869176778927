import { createActionCreator } from 'deox';

import { TGetScheduleMaterials, TGetScheduleResponse } from '@/services/api/schedule/get-schedule';

// CONSTANTS

export enum EGetScheduleAction {
  GET_SCHEDULE = 'GET_SCHEDULE',
  GET_SCHEDULE_REQUEST = 'GET_SCHEDULE_REQUEST',
  GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS',
  GET_SCHEDULE_FAILED = 'GET_SCHEDULE_FAILED',
}

// TYPES

export type TGetScheduleRequest = {
  type: EGetScheduleAction.GET_SCHEDULE_REQUEST;
  payload: {
    materials: TGetScheduleMaterials;
    successCallback?: (response: TGetScheduleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetScheduleSuccess = {
  type: EGetScheduleAction.GET_SCHEDULE_SUCCESS;
  payload: { response: TGetScheduleResponse };
};

export type TGetScheduleFailed = { type: EGetScheduleAction.GET_SCHEDULE_FAILED };

// FUNCTION

export const getScheduleAction = {
  request: createActionCreator(
    EGetScheduleAction.GET_SCHEDULE_REQUEST,
    (resolve) =>
      (
        materials: TGetScheduleMaterials,
        successCallback?: (response: TGetScheduleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetScheduleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetScheduleAction.GET_SCHEDULE_SUCCESS,
    (resolve) =>
      (response: TGetScheduleResponse): TGetScheduleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetScheduleAction.GET_SCHEDULE_FAILED,
    (resolve) =>
      (error: unknown): TGetScheduleFailed =>
        resolve({ error }),
  ),
};
