import { TTalksNotification } from '@/common/models';
import { TPaginationResponse } from '@/common/types';
import ApiService from '@/services/api';

// ENUMS
export enum EGetManagerTalksNotificationsType {
  DAY_VISIT = 'DAY_VISIT',
  NEXT_VISIT = 'NEXT_VISIT',
  EXPIRED_TICKET = 'EXPIRED_TICKET',
}

// TYPES

export type TGetManagerTalksNotificationsParams = {
  page: number;
  pageSize: number;
  fromDate?: string;
  toDate?: string;
  filterType?: string;
};

export type TGetManagerTalksNotificationsMaterials = {
  params?: TGetManagerTalksNotificationsParams;
};

export type TGetManagerTalksNotificationsResponse = {
  pagination: TPaginationResponse;
  data: TTalksNotification[];
  totalNext2day: number;
};

// FUNCTION

export const getManagerTalksNotifications = async ({
  params,
}: TGetManagerTalksNotificationsMaterials): Promise<TGetManagerTalksNotificationsResponse> => {
  const response = await ApiService.get(`/admin/talks/schedules/noti`, { params });
  return response.data;
};
