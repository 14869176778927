import { createActionCreator } from 'deox';

import { TAddTicketCustomerMaterials, TAddTicketCustomerResponse } from '@/services/api/customer/add-ticket-customer';

// CONSTANTS

export enum EAddTicketCustomerAction {
  ADD_TICKET_CUSTOMER = 'ADD_TICKET_CUSTOMER',
  ADD_TICKET_CUSTOMER_REQUEST = 'ADD_TICKET_CUSTOMER_REQUEST',
  ADD_TICKET_CUSTOMER_SUCCESS = 'ADD_TICKET_CUSTOMER_SUCCESS',
  ADD_TICKET_CUSTOMER_FAILED = 'ADD_TICKET_CUSTOMER_FAILED',
}

// TYPES

export type TAddTicketCustomerRequest = {
  type: EAddTicketCustomerAction.ADD_TICKET_CUSTOMER_REQUEST;
  payload: {
    materials: TAddTicketCustomerMaterials;
    successCallback?: (response: TAddTicketCustomerResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TAddTicketCustomerSuccess = {
  type: EAddTicketCustomerAction.ADD_TICKET_CUSTOMER_SUCCESS;
  payload: { response: TAddTicketCustomerResponse };
};

export type TAddTicketCustomerFailed = { type: EAddTicketCustomerAction.ADD_TICKET_CUSTOMER_FAILED };

// FUNCTION

export const addTicketCustomerAction = {
  request: createActionCreator(
    EAddTicketCustomerAction.ADD_TICKET_CUSTOMER_REQUEST,
    (resolve) =>
      (
        materials: TAddTicketCustomerMaterials,
        successCallback?: (response: TAddTicketCustomerResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TAddTicketCustomerRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EAddTicketCustomerAction.ADD_TICKET_CUSTOMER_SUCCESS,
    (resolve) =>
      (response: TAddTicketCustomerResponse): TAddTicketCustomerSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EAddTicketCustomerAction.ADD_TICKET_CUSTOMER_FAILED,
    (resolve) =>
      (error: unknown): TAddTicketCustomerFailed =>
        resolve({ error }),
  ),
};
