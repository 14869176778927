import { createActionCreator } from 'deox';

import {
  TRefundTicketCustomerMaterials,
  TRefundTicketCustomerResponse,
} from '@/services/api/customer/refund-ticket-customer';

// CONSTANTS

export enum ERefundTicketCustomerAction {
  REFUND_TICKET_CUSTOMER = 'REFUND_TICKET_CUSTOMER',
  REFUND_TICKET_CUSTOMER_REQUEST = 'REFUND_TICKET_CUSTOMER_REQUEST',
  REFUND_TICKET_CUSTOMER_SUCCESS = 'REFUND_TICKET_CUSTOMER_SUCCESS',
  REFUND_TICKET_CUSTOMER_FAILED = 'REFUND_TICKET_CUSTOMER_FAILED',
}

// TYPES

export type TRefundTicketCustomerRequest = {
  type: ERefundTicketCustomerAction.REFUND_TICKET_CUSTOMER_REQUEST;
  payload: {
    materials: TRefundTicketCustomerMaterials;
    successCallback?: (response: TRefundTicketCustomerResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TRefundTicketCustomerSuccess = {
  type: ERefundTicketCustomerAction.REFUND_TICKET_CUSTOMER_SUCCESS;
  payload: { response: TRefundTicketCustomerResponse };
};

export type TRefundTicketCustomerFailed = { type: ERefundTicketCustomerAction.REFUND_TICKET_CUSTOMER_FAILED };

// FUNCTION

export const refundTicketCustomerAction = {
  request: createActionCreator(
    ERefundTicketCustomerAction.REFUND_TICKET_CUSTOMER_REQUEST,
    (resolve) =>
      (
        materials: TRefundTicketCustomerMaterials,
        successCallback?: (response: TRefundTicketCustomerResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TRefundTicketCustomerRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ERefundTicketCustomerAction.REFUND_TICKET_CUSTOMER_SUCCESS,
    (resolve) =>
      (response: TRefundTicketCustomerResponse): TRefundTicketCustomerSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ERefundTicketCustomerAction.REFUND_TICKET_CUSTOMER_FAILED,
    (resolve) =>
      (error: unknown): TRefundTicketCustomerFailed =>
        resolve({ error }),
  ),
};
