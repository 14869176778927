import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getEmployeesAction } from '@/redux/actions';
import { getEmployees, TGetEmployeesResponse } from '@/services/api';

// FUNCTION

export function* getEmployeesSaga(action: ActionType<typeof getEmployeesAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getEmployees, materials);
    const getEmployeesResponse: TGetEmployeesResponse = response as TGetEmployeesResponse;
    yield put(getEmployeesAction.success(getEmployeesResponse));
    successCallback?.(getEmployeesResponse);
  } catch (err) {
    yield put(getEmployeesAction.failure(err));
    failedCallback?.(err);
  }
}
