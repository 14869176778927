import { createActionCreator } from 'deox';

import { TGetInquiryMaterials, TGetInquiryResponse } from '@/services/api/inquiry/get-inquiry';

// CONSTANTS

export enum EGetInquiryAction {
  GET_INQUIRY = 'GET_INQUIRY',
  GET_INQUIRY_REQUEST = 'GET_INQUIRY_REQUEST',
  GET_INQUIRY_SUCCESS = 'GET_INQUIRY_SUCCESS',
  GET_INQUIRY_FAILED = 'GET_INQUIRY_FAILED',
}

// TYPES

export type TGetInquiryRequest = {
  type: EGetInquiryAction.GET_INQUIRY_REQUEST;
  payload: {
    materials: TGetInquiryMaterials;
    successCallback?: (response: TGetInquiryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetInquirySuccess = {
  type: EGetInquiryAction.GET_INQUIRY_SUCCESS;
  payload: { response?: TGetInquiryResponse };
};

export type TGetInquiryFailed = { type: EGetInquiryAction.GET_INQUIRY_FAILED };

// FUNCTION

export const getInquiryAction = {
  request: createActionCreator(
    EGetInquiryAction.GET_INQUIRY_REQUEST,
    (resolve) =>
      (
        materials: TGetInquiryMaterials,
        successCallback?: (response: TGetInquiryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetInquiryRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetInquiryAction.GET_INQUIRY_SUCCESS,
    (resolve) =>
      (response?: TGetInquiryResponse): TGetInquirySuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetInquiryAction.GET_INQUIRY_FAILED,
    (resolve) =>
      (error: unknown): TGetInquiryFailed =>
        resolve({ error }),
  ),
};
