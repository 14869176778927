import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getManagerTalksSentsAction } from '@/redux/actions';
import { getManagerTalksSents, TGetManagerTalksSentsResponse } from '@/services/api';

// FUNCTION

export function* getManagerTalksSentsSaga(action: ActionType<typeof getManagerTalksSentsAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getManagerTalksSents, materials);
    const getManagerTalksSentsResponse: TGetManagerTalksSentsResponse = response as TGetManagerTalksSentsResponse;
    yield put(getManagerTalksSentsAction.success(getManagerTalksSentsResponse));
    successCallback?.(getManagerTalksSentsResponse);
  } catch (err) {
    yield put(getManagerTalksSentsAction.failure(err));
    failedCallback?.(err);
  }
}
