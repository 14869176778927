import ApiService from '@/services/api';

// TYPES

export type TUpdateManagerTalksNotificationPaths = {
  id: string | number;
};
export type TUpdateManagerTalksNotificationBody = unknown;

export type TUpdateManagerTalksNotificationMaterials = {
  paths?: TUpdateManagerTalksNotificationPaths;
  body?: TUpdateManagerTalksNotificationBody;
};

export type TUpdateManagerTalksNotificationResponse = unknown;

// FUNCTION

export const updateManagerTalksNotification = async ({
  paths,
  body,
}: TUpdateManagerTalksNotificationMaterials): Promise<TUpdateManagerTalksNotificationResponse> => {
  const response = await ApiService.patch(`/admin/talks/schedules/noti/${paths?.id}/notification`, body);
  return response.data;
};
