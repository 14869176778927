import { createActionCreator } from 'deox';

import { TAddPointsCustomerMaterials, TAddPointsCustomerResponse } from '@/services/api/point/add-points-customer';

// CONSTANTS

export enum EAddPointsCustomerAction {
  ADD_POINTS_CUSTOMER = 'ADD_POINTS_CUSTOMER',
  ADD_POINTS_CUSTOMER_REQUEST = 'ADD_POINTS_CUSTOMER_REQUEST',
  ADD_POINTS_CUSTOMER_SUCCESS = 'ADD_POINTS_CUSTOMER_SUCCESS',
  ADD_POINTS_CUSTOMER_FAILED = 'ADD_POINTS_CUSTOMER_FAILED',
}

// TYPES

export type TAddPointsCustomerRequest = {
  type: EAddPointsCustomerAction.ADD_POINTS_CUSTOMER_REQUEST;
  payload: {
    materials: TAddPointsCustomerMaterials;
    successCallback?: (response: TAddPointsCustomerResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TAddPointsCustomerSuccess = {
  type: EAddPointsCustomerAction.ADD_POINTS_CUSTOMER_SUCCESS;
  payload: { response: TAddPointsCustomerResponse };
};

export type TAddPointsCustomerFailed = { type: EAddPointsCustomerAction.ADD_POINTS_CUSTOMER_FAILED };

// FUNCTION

export const addPointsCustomerAction = {
  request: createActionCreator(
    EAddPointsCustomerAction.ADD_POINTS_CUSTOMER_REQUEST,
    (resolve) =>
      (
        materials: TAddPointsCustomerMaterials,
        successCallback?: (response: TAddPointsCustomerResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TAddPointsCustomerRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EAddPointsCustomerAction.ADD_POINTS_CUSTOMER_SUCCESS,
    (resolve) =>
      (response: TAddPointsCustomerResponse): TAddPointsCustomerSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EAddPointsCustomerAction.ADD_POINTS_CUSTOMER_FAILED,
    (resolve) =>
      (error: unknown): TAddPointsCustomerFailed =>
        resolve({ error }),
  ),
};
