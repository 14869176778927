import { TProcedureState } from '@/redux/reducers/procedure';
import { TUpdateProcedureSuccess } from '@/redux/actions/procedure';

export const updateProcedureUpdateState = (
  state: TProcedureState,
  action: TUpdateProcedureSuccess,
): TProcedureState => ({
  ...state,
  updateProcedureResponse: action.payload.response,
});
