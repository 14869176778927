import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { addPointsCustomerAction } from '@/redux/actions';
import { addPointsCustomer, TAddPointsCustomerResponse } from '@/services/api';

// FUNCTION

export function* addPointsCustomerSaga(action: ActionType<typeof addPointsCustomerAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(addPointsCustomer, materials);
    const addPointsCustomerResponse: TAddPointsCustomerResponse = response as TAddPointsCustomerResponse;
    yield put(addPointsCustomerAction.success(addPointsCustomerResponse));
    successCallback?.(addPointsCustomerResponse);
  } catch (err) {
    yield put(addPointsCustomerAction.failure(err));
    failedCallback?.(err);
  }
}
