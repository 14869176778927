import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getStatisticsCustomerAction } from '@/redux/actions';
import { getStatisticsCustomer, TGetStatisticsCustomerResponse } from '@/services/api';

// FUNCTION

export function* getStatisticsCustomerSaga(action: ActionType<typeof getStatisticsCustomerAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getStatisticsCustomer, materials);
    const getStatisticsCustomerResponse: TGetStatisticsCustomerResponse = response as TGetStatisticsCustomerResponse;
    yield put(getStatisticsCustomerAction.success(getStatisticsCustomerResponse));
    successCallback?.(getStatisticsCustomerResponse);
  } catch (err) {
    yield put(getStatisticsCustomerAction.failure(err));
    failedCallback?.(err);
  }
}
