import { TSchedule } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TUpdateSalesSettingPaths = {
  branchid: string | number;
};

export type TUpdateSalesSettingBody = {
  openTime?: {
    dayOfWeek: string;
    business: boolean;
    openTime: string;
    closeTime: string;
  }[];
  defaultTime?: number;
  settingTime?: TSettingTime[];
};

export type TSettingTime = {
  branch?: string;
  id?: string;
  piorityNewCustomer?: boolean;
  timeline: string;
  totalCanBooking?: number;
  totalEmployee?: number;
  totalPiorityNewCustomer?: number;
  schedulesInfo?: TSchedule[];
};

export type TUpdateSalesSettingMaterials = {
  paths?: TUpdateSalesSettingPaths;
  body?: TUpdateSalesSettingBody;
};

export type TUpdateSalesSettingResponse = unknown;

// FUNCTION

export const updateSalesSetting = async ({
  paths,
  body,
}: TUpdateSalesSettingMaterials): Promise<TUpdateSalesSettingResponse> => {
  const response = await ApiService.patch(`/admin/sales/setting/${paths?.branchid}`, body);
  return response.data;
};
