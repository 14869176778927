import { all, takeLatest } from 'redux-saga/effects';

import {
  createProcedureAction,
  deleteProcedureAction,
  getProceduresAction,
  updateProcedureAction,
} from '@/redux/actions';

import { createProcedureSaga } from './create-procedure';
import { deleteProcedureSaga } from './delete-procedure';
import { getProceduresSaga } from './get-procedures';
import { updateProcedureSaga } from './update-procedure';

export default function* root(): Generator {
  yield all([
    takeLatest(createProcedureAction.request.type, createProcedureSaga),
    takeLatest(deleteProcedureAction.request.type, deleteProcedureSaga),
    takeLatest(getProceduresAction.request.type, getProceduresSaga),
    takeLatest(updateProcedureAction.request.type, updateProcedureSaga),
  ]);
}
