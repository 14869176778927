import { createActionCreator } from 'deox';

import {
  TCreateSchedulePersonalMaterials,
  TCreateSchedulePersonalResponse,
} from '@/services/api/schedule/create-schedule-personal';

// CONSTANTS

export enum ECreateSchedulePersonalAction {
  CREATE_SCHEDULE_PERSONAL = 'CREATE_SCHEDULE_PERSONAL',
  CREATE_SCHEDULE_PERSONAL_REQUEST = 'CREATE_SCHEDULE_PERSONAL_REQUEST',
  CREATE_SCHEDULE_PERSONAL_SUCCESS = 'CREATE_SCHEDULE_PERSONAL_SUCCESS',
  CREATE_SCHEDULE_PERSONAL_FAILED = 'CREATE_SCHEDULE_PERSONAL_FAILED',
}

// TYPES

export type TCreateSchedulePersonalRequest = {
  type: ECreateSchedulePersonalAction.CREATE_SCHEDULE_PERSONAL_REQUEST;
  payload: {
    materials: TCreateSchedulePersonalMaterials;
    successCallback?: (response: TCreateSchedulePersonalResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateSchedulePersonalSuccess = {
  type: ECreateSchedulePersonalAction.CREATE_SCHEDULE_PERSONAL_SUCCESS;
  payload: { response: TCreateSchedulePersonalResponse };
};

export type TCreateSchedulePersonalFailed = { type: ECreateSchedulePersonalAction.CREATE_SCHEDULE_PERSONAL_FAILED };

// FUNCTION

export const createSchedulePersonalAction = {
  request: createActionCreator(
    ECreateSchedulePersonalAction.CREATE_SCHEDULE_PERSONAL_REQUEST,
    (resolve) =>
      (
        materials: TCreateSchedulePersonalMaterials,
        successCallback?: (response: TCreateSchedulePersonalResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateSchedulePersonalRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateSchedulePersonalAction.CREATE_SCHEDULE_PERSONAL_SUCCESS,
    (resolve) =>
      (response: TCreateSchedulePersonalResponse): TCreateSchedulePersonalSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateSchedulePersonalAction.CREATE_SCHEDULE_PERSONAL_FAILED,
    (resolve) =>
      (error: unknown): TCreateSchedulePersonalFailed =>
        resolve({ error }),
  ),
};
