import ApiService from '@/services/api';

// TYPES

export type TDeleteNoticePaths = {
  id: string | number;
};
export type TDeleteNoticeParams = unknown;

export type TDeleteNoticeMaterials = {
  isSuperAdmin?: boolean;
  paths?: TDeleteNoticePaths;
  params?: TDeleteNoticeParams;
};

export type TDeleteNoticeResponse = unknown;

// FUNCTION

export const deleteNotice = async ({
  paths,
  params,
  isSuperAdmin,
}: TDeleteNoticeMaterials): Promise<TDeleteNoticeResponse> => {
  const response = await ApiService.delete(`/${isSuperAdmin ? 'management' : 'admin'}/notices/${paths?.id}`, {
    params,
  });
  return response.data;
};
