import { createActionCreator } from 'deox';

import { TCreateSaleMaterials, TCreateSaleResponse } from '@/services/api/sale/create-sale';

// CONSTANTS

export enum ECreateSaleAction {
  CREATE_SALE = 'CREATE_SALE',
  CREATE_SALE_REQUEST = 'CREATE_SALE_REQUEST',
  CREATE_SALE_SUCCESS = 'CREATE_SALE_SUCCESS',
  CREATE_SALE_FAILED = 'CREATE_SALE_FAILED',
}

// TYPES

export type TCreateSaleRequest = {
  type: ECreateSaleAction.CREATE_SALE_REQUEST;
  payload: {
    materials: TCreateSaleMaterials;
    successCallback?: (response: TCreateSaleResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateSaleSuccess = {
  type: ECreateSaleAction.CREATE_SALE_SUCCESS;
  payload: { response: TCreateSaleResponse };
};

export type TCreateSaleFailed = { type: ECreateSaleAction.CREATE_SALE_FAILED };

// FUNCTION

export const createSaleAction = {
  request: createActionCreator(
    ECreateSaleAction.CREATE_SALE_REQUEST,
    (resolve) =>
      (
        materials: TCreateSaleMaterials,
        successCallback?: (response: TCreateSaleResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateSaleRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateSaleAction.CREATE_SALE_SUCCESS,
    (resolve) =>
      (response: TCreateSaleResponse): TCreateSaleSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateSaleAction.CREATE_SALE_FAILED,
    (resolve) =>
      (error: unknown): TCreateSaleFailed =>
        resolve({ error }),
  ),
};
