import { TSchedule } from '@/common/models';
import { EScheduleView } from '@/pages/Reservation/ReservationSchedule/ReservationScheduleTable/ReservationScheduleTable.enums';
import ApiService from '@/services/api';

// TYPES

export type TGetSchedulesParams = {
  fromDate?: string;
  toDate: string;
  typeFilter: EScheduleView;
  branch: string;
};

export type TGetSchedulesMaterials = {
  params?: TGetSchedulesParams;
};

export type TGetSchedulesResponse = {
  schedules: TSchedule[];
  totalSchedulesNoShow: number;
  totalSchedulesPersonal: number;
  totalSchedulesReservation: number;
  totalSchedulesReservationCancel: number;
};

// FUNCTION

export const getSchedules = async ({ params }: TGetSchedulesMaterials): Promise<TGetSchedulesResponse> => {
  const response = await ApiService.get(`/admin/managements/schedules`, { params });
  return response.data;
};
