import { createActionCreator } from 'deox';

import {
  TCreateScheduleCustomerMaterials,
  TCreateScheduleCustomerResponse,
} from '@/services/api/schedule/create-schedule-customer';

// CONSTANTS

export enum ECreateScheduleCustomerAction {
  CREATE_SCHEDULE_CUSTOMER = 'CREATE_SCHEDULE_CUSTOMER',
  CREATE_SCHEDULE_CUSTOMER_REQUEST = 'CREATE_SCHEDULE_CUSTOMER_REQUEST',
  CREATE_SCHEDULE_CUSTOMER_SUCCESS = 'CREATE_SCHEDULE_CUSTOMER_SUCCESS',
  CREATE_SCHEDULE_CUSTOMER_FAILED = 'CREATE_SCHEDULE_CUSTOMER_FAILED',
}

// TYPES

export type TCreateScheduleCustomerRequest = {
  type: ECreateScheduleCustomerAction.CREATE_SCHEDULE_CUSTOMER_REQUEST;
  payload: {
    materials: TCreateScheduleCustomerMaterials;
    successCallback?: (response: TCreateScheduleCustomerResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateScheduleCustomerSuccess = {
  type: ECreateScheduleCustomerAction.CREATE_SCHEDULE_CUSTOMER_SUCCESS;
  payload: { response: TCreateScheduleCustomerResponse };
};

export type TCreateScheduleCustomerFailed = { type: ECreateScheduleCustomerAction.CREATE_SCHEDULE_CUSTOMER_FAILED };

// FUNCTION

export const createScheduleCustomerAction = {
  request: createActionCreator(
    ECreateScheduleCustomerAction.CREATE_SCHEDULE_CUSTOMER_REQUEST,
    (resolve) =>
      (
        materials: TCreateScheduleCustomerMaterials,
        successCallback?: (response: TCreateScheduleCustomerResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateScheduleCustomerRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateScheduleCustomerAction.CREATE_SCHEDULE_CUSTOMER_SUCCESS,
    (resolve) =>
      (response: TCreateScheduleCustomerResponse): TCreateScheduleCustomerSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateScheduleCustomerAction.CREATE_SCHEDULE_CUSTOMER_FAILED,
    (resolve) =>
      (error: unknown): TCreateScheduleCustomerFailed =>
        resolve({ error }),
  ),
};
