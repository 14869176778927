import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getStatisticsEmployeesAction } from '@/redux/actions';
import { getStatisticsEmployees, TGetStatisticsEmployeesResponse } from '@/services/api';

// FUNCTION

export function* getStatisticsEmployeesSaga(
  action: ActionType<typeof getStatisticsEmployeesAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getStatisticsEmployees, materials);
    const getStatisticsEmployeesResponse: TGetStatisticsEmployeesResponse = response as TGetStatisticsEmployeesResponse;
    yield put(getStatisticsEmployeesAction.success(getStatisticsEmployeesResponse));
    successCallback?.(getStatisticsEmployeesResponse);
  } catch (err) {
    yield put(getStatisticsEmployeesAction.failure(err));
    failedCallback?.(err);
  }
}
