import { createActionCreator } from 'deox';

import { TGetCustomerMaterials, TGetCustomerResponse } from '@/services/api/customer/get-customer';

// CONSTANTS

export enum EGetCustomerAction {
  GET_CUSTOMER = 'GET_CUSTOMER',
  GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST',
  GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS',
  GET_CUSTOMER_FAILED = 'GET_CUSTOMER_FAILED',
}

// TYPES

export type TGetCustomerRequest = {
  type: EGetCustomerAction.GET_CUSTOMER_REQUEST;
  payload: {
    materials: TGetCustomerMaterials;
    successCallback?: (response: TGetCustomerResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetCustomerSuccess = {
  type: EGetCustomerAction.GET_CUSTOMER_SUCCESS;
  payload: { response?: TGetCustomerResponse };
};

export type TGetCustomerFailed = { type: EGetCustomerAction.GET_CUSTOMER_FAILED };

// FUNCTION

export const getCustomerAction = {
  request: createActionCreator(
    EGetCustomerAction.GET_CUSTOMER_REQUEST,
    (resolve) =>
      (
        materials: TGetCustomerMaterials,
        successCallback?: (response: TGetCustomerResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetCustomerRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetCustomerAction.GET_CUSTOMER_SUCCESS,
    (resolve) =>
      (response?: TGetCustomerResponse): TGetCustomerSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetCustomerAction.GET_CUSTOMER_FAILED,
    (resolve) =>
      (error: unknown): TGetCustomerFailed =>
        resolve({ error }),
  ),
};
