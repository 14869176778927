import { TTalksState } from '@/redux/reducers/talks';
import { TGetManagerTalksHistoriesSuccess } from '@/redux/actions/talks';

export const getManagerTalksHistoriesUpdateState = (
  state: TTalksState,
  action: TGetManagerTalksHistoriesSuccess,
): TTalksState => ({
  ...state,
  getManagerTalksHistoriesResponse: action.payload.response,
});
