import { createReducer } from 'deox';

import { TGetEmployeesResponse } from '@/services/api/employee';
import { getEmployeesAction } from '@/redux/actions';
import { getEmployeesUpdateState } from './get-employees';

export type TEmployeeState = {
  getEmployeesResponse?: TGetEmployeesResponse;
};

const initialState: TEmployeeState = {
  getEmployeesResponse: undefined,
};

const EmployeeReducer = createReducer(initialState, (handleAction) => [
  handleAction(getEmployeesAction.success, getEmployeesUpdateState),
]);

export default EmployeeReducer;
