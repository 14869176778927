import { createActionCreator } from 'deox';

import {
  TGetStatisticsCustomerMaterials,
  TGetStatisticsCustomerResponse,
} from '@/services/api/statistic/get-statistics-customer';

// CONSTANTS

export enum EGetStatisticsCustomerAction {
  GET_STATISTICS_CUSTOMER = 'GET_STATISTICS_CUSTOMER',
  GET_STATISTICS_CUSTOMER_REQUEST = 'GET_STATISTICS_CUSTOMER_REQUEST',
  GET_STATISTICS_CUSTOMER_SUCCESS = 'GET_STATISTICS_CUSTOMER_SUCCESS',
  GET_STATISTICS_CUSTOMER_FAILED = 'GET_STATISTICS_CUSTOMER_FAILED',
}

// TYPES

export type TGetStatisticsCustomerRequest = {
  type: EGetStatisticsCustomerAction.GET_STATISTICS_CUSTOMER_REQUEST;
  payload: {
    materials: TGetStatisticsCustomerMaterials;
    successCallback?: (response: TGetStatisticsCustomerResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetStatisticsCustomerSuccess = {
  type: EGetStatisticsCustomerAction.GET_STATISTICS_CUSTOMER_SUCCESS;
  payload: { response: TGetStatisticsCustomerResponse };
};

export type TGetStatisticsCustomerFailed = { type: EGetStatisticsCustomerAction.GET_STATISTICS_CUSTOMER_FAILED };

// FUNCTION

export const getStatisticsCustomerAction = {
  request: createActionCreator(
    EGetStatisticsCustomerAction.GET_STATISTICS_CUSTOMER_REQUEST,
    (resolve) =>
      (
        materials: TGetStatisticsCustomerMaterials,
        successCallback?: (response: TGetStatisticsCustomerResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetStatisticsCustomerRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetStatisticsCustomerAction.GET_STATISTICS_CUSTOMER_SUCCESS,
    (resolve) =>
      (response: TGetStatisticsCustomerResponse): TGetStatisticsCustomerSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetStatisticsCustomerAction.GET_STATISTICS_CUSTOMER_FAILED,
    (resolve) =>
      (error: unknown): TGetStatisticsCustomerFailed =>
        resolve({ error }),
  ),
};
