import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getCustomerUsageAction } from '@/redux/actions';
import { getCustomerUsage, TGetCustomerUsageResponse } from '@/services/api';

// FUNCTION

export function* getCustomerUsageSaga(action: ActionType<typeof getCustomerUsageAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getCustomerUsage, materials);
    const getCustomerUsageResponse: TGetCustomerUsageResponse = response as TGetCustomerUsageResponse;
    yield put(getCustomerUsageAction.success(getCustomerUsageResponse));
    successCallback?.(getCustomerUsageResponse);
  } catch (err) {
    yield put(getCustomerUsageAction.failure(err));
    failedCallback?.(err);
  }
}
