import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import IconEdit from '@/assets/icons/icon-edit.svg';

const Svg: React.FC<TIconProps> = () => {
  return <img src={IconEdit} alt="" />;
};

export default Svg;
