import { createActionCreator } from 'deox';

import { TUpdateSaleNoteMaterials, TUpdateSaleNoteResponse } from '@/services/api/sale/update-sale-note';

// CONSTANTS

export enum EUpdateSaleNoteAction {
  UPDATE_SALE_NOTE = 'UPDATE_SALE_NOTE',
  UPDATE_SALE_NOTE_REQUEST = 'UPDATE_SALE_NOTE_REQUEST',
  UPDATE_SALE_NOTE_SUCCESS = 'UPDATE_SALE_NOTE_SUCCESS',
  UPDATE_SALE_NOTE_FAILED = 'UPDATE_SALE_NOTE_FAILED',
}

// TYPES

export type TUpdateSaleNoteRequest = {
  type: EUpdateSaleNoteAction.UPDATE_SALE_NOTE_REQUEST;
  payload: {
    materials: TUpdateSaleNoteMaterials;
    successCallback?: (response: TUpdateSaleNoteResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateSaleNoteSuccess = {
  type: EUpdateSaleNoteAction.UPDATE_SALE_NOTE_SUCCESS;
  payload: { response: TUpdateSaleNoteResponse };
};

export type TUpdateSaleNoteFailed = { type: EUpdateSaleNoteAction.UPDATE_SALE_NOTE_FAILED };

// FUNCTION

export const updateSaleNoteAction = {
  request: createActionCreator(
    EUpdateSaleNoteAction.UPDATE_SALE_NOTE_REQUEST,
    (resolve) =>
      (
        materials: TUpdateSaleNoteMaterials,
        successCallback?: (response: TUpdateSaleNoteResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateSaleNoteRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpdateSaleNoteAction.UPDATE_SALE_NOTE_SUCCESS,
    (resolve) =>
      (response: TUpdateSaleNoteResponse): TUpdateSaleNoteSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpdateSaleNoteAction.UPDATE_SALE_NOTE_FAILED,
    (resolve) =>
      (error: unknown): TUpdateSaleNoteFailed =>
        resolve({ error }),
  ),
};
