import ApiService from '@/services/api';

// TYPES

export type TUpdateCustomerNotePaths = {
  id: string | number;
};
export type TUpdateCustomerNoteBody = unknown;

export type TUpdateCustomerNoteMaterials = {
  paths?: TUpdateCustomerNotePaths;
  body?: TUpdateCustomerNoteBody;
};

export type TUpdateCustomerNoteResponse = unknown;

// FUNCTION

export const updateCustomerNote = async ({
  paths,
  body,
}: TUpdateCustomerNoteMaterials): Promise<TUpdateCustomerNoteResponse> => {
  const response = await ApiService.patch(`/admin/managements/schedules/${paths?.id}/note-request`, body);
  return response.data;
};
