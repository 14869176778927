import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteTicketAction } from '@/redux/actions';
import { deleteTicket, TDeleteTicketResponse } from '@/services/api';

// FUNCTION

export function* deleteTicketSaga(action: ActionType<typeof deleteTicketAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteTicket, materials);
    const deleteTicketResponse: TDeleteTicketResponse = response as TDeleteTicketResponse;
    yield put(deleteTicketAction.success(deleteTicketResponse));
    successCallback?.(deleteTicketResponse);
  } catch (err) {
    yield put(deleteTicketAction.failure(err));
    failedCallback?.(err);
  }
}
