import { all, takeLatest } from 'redux-saga/effects';

import { getSalesSettingAction, updateSalesSettingAction } from '@/redux/actions';

import { getSalesSettingSaga } from './get-sales-setting';
import { updateSalesSettingSaga } from './update-sales-setting';

export default function* root(): Generator {
  yield all([
    takeLatest(getSalesSettingAction.request.type, getSalesSettingSaga),
    takeLatest(updateSalesSettingAction.request.type, updateSalesSettingSaga),
  ]);
}
