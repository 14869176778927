import ApiService from '@/services/api';

// TYPES

export type TCreateSaleParams = unknown;
export type TCreateSaleBody = {
  branch?: string;
  customer?: string;
  procedures?: {
    quantity?: number;
    procedure?: string;
  }[];
  staffs?: string[];
  tickets?: string[];
  totalAmount?: number;
  discountPrice?: number;
  total?: number;
  amountPoint?: number;
  amountCard?: number;
  amountCash?: number;
  type?: string;
  createdAt?: string;
};

export type TCreateSaleMaterials = {
  params?: TCreateSaleParams;
  body?: TCreateSaleBody;
};

export type TCreateSaleResponse = unknown;

// FUNCTION

export const createSale = async ({ params, body }: TCreateSaleMaterials): Promise<TCreateSaleResponse> => {
  const response = await ApiService.post(`/admin/sales`, body, { params });
  return response.data;
};
