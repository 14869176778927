import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createSchedulePersonalAction } from '@/redux/actions';
import { createSchedulePersonal, TCreateSchedulePersonalResponse } from '@/services/api';

// FUNCTION

export function* createSchedulePersonalSaga(
  action: ActionType<typeof createSchedulePersonalAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createSchedulePersonal, materials);
    const createSchedulePersonalResponse: TCreateSchedulePersonalResponse = response as TCreateSchedulePersonalResponse;
    yield put(createSchedulePersonalAction.success(createSchedulePersonalResponse));
    successCallback?.(createSchedulePersonalResponse);
  } catch (err) {
    yield put(createSchedulePersonalAction.failure(err));
    failedCallback?.(err);
  }
}
