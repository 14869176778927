import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createNoticeAction } from '@/redux/actions';
import { createNotice, TCreateNoticeResponse } from '@/services/api';

// FUNCTION

export function* createNoticeSaga(action: ActionType<typeof createNoticeAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createNotice, materials);
    const createNoticeResponse: TCreateNoticeResponse = response as TCreateNoticeResponse;
    yield put(createNoticeAction.success(createNoticeResponse));
    successCallback?.(createNoticeResponse);
  } catch (err) {
    yield put(createNoticeAction.failure(err));
    failedCallback?.(err);
  }
}
