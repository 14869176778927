import ApiService from '@/services/api';

// TYPES

export type TUpdateNoticePaths = {
  id: string | number;
};
export type TUpdateNoticeBody = FormData;

export type TUpdateNoticeMaterials = {
  isSuperAdmin?: boolean;
  paths?: TUpdateNoticePaths;
  body?: TUpdateNoticeBody;
};

export type TUpdateNoticeResponse = unknown;

// FUNCTION

export const updateNotice = async ({
  paths,
  body,
  isSuperAdmin,
}: TUpdateNoticeMaterials): Promise<TUpdateNoticeResponse> => {
  const response = await ApiService.patch(`/${isSuperAdmin ? 'management' : 'admin'}/notices/${paths?.id}`, body);
  return response.data;
};
