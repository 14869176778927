import ApiService from '@/services/api';

// TYPES

export type TUpdateStaffPaths = {
  id: string | number;
};
export type TUpdateStaffBody = FormData;

export type TUpdateStaffMaterials = {
  paths?: TUpdateStaffPaths;
  body?: TUpdateStaffBody;
};

export type TUpdateStaffResponse = unknown;

// FUNCTION

export const updateStaff = async ({ paths, body }: TUpdateStaffMaterials): Promise<TUpdateStaffResponse> => {
  const response = await ApiService.put(`/staffs/${paths?.id}`, body);
  return response.data;
};
