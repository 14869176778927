import { TTalksState } from '@/redux/reducers/talks';
import { TUpdateManagerTalksNotificationSuccess } from '@/redux/actions/talks';

export const updateManagerTalksNotificationUpdateState = (
  state: TTalksState,
  action: TUpdateManagerTalksNotificationSuccess,
): TTalksState => ({
  ...state,
  updateManagerTalksNotificationResponse: action.payload.response,
});
