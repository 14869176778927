import React from 'react';

import { TIconProps } from '@/components/Icon/Icon.types';
import { EIconColor } from '@/components/Icon/Icon.enums';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle className="fill" cx="20.4854" cy="12.0001" r="2" transform="rotate(45 20.4854 12.0001)" fill={color} />
      <circle className="fill" cx="12" cy="3.51471" r="2" transform="rotate(45 12 3.51471)" fill={color} />
      <circle className="fill" cx="3.51465" cy="12.0001" r="2" transform="rotate(45 3.51465 12.0001)" fill={color} />
      <circle className="fill" cx="12" cy="20.4852" r="2" transform="rotate(45 12 20.4852)" fill={color} />
      <rect
        className="fill"
        x="5.63574"
        y="4.22168"
        width="20"
        height="2"
        rx="0.6"
        transform="rotate(45 5.63574 4.22168)"
        fill={color}
      />
      <rect
        className="fill"
        x="4.22168"
        y="18.364"
        width="20"
        height="2"
        rx="0.6"
        transform="rotate(-45 4.22168 18.364)"
        fill={color}
      />
    </svg>
  );
};

export default Svg;
