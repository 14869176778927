import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { disconnectKakaoAction } from '@/redux/actions';
import { disconnectKakao, TDisconnectKakaoResponse } from '@/services/api';

// FUNCTION

export function* disconnectKakaoSaga(action: ActionType<typeof disconnectKakaoAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(disconnectKakao, materials);
    const disconnectKakaoResponse: TDisconnectKakaoResponse = response as TDisconnectKakaoResponse;
    yield put(disconnectKakaoAction.success(disconnectKakaoResponse));
    successCallback?.(disconnectKakaoResponse);
  } catch (err) {
    yield put(disconnectKakaoAction.failure(err));
    failedCallback?.(err);
  }
}
