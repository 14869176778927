import { TCustomerState } from '@/redux/reducers/customer';
import { TGetCustomerDetailSuccess } from '@/redux/actions/customer';

export const getCustomerDetailUpdateState = (
  state: TCustomerState,
  action: TGetCustomerDetailSuccess,
): TCustomerState => ({
  ...state,
  getCustomerDetailResponse: action.payload.response,
});
