import { createActionCreator } from 'deox';

import { TGetStatisticsMaterials, TGetStatisticsResponse } from '@/services/api/statistic/get-statistics';

// CONSTANTS

export enum EGetStatisticsAction {
  GET_STATISTICS = 'GET_STATISTICS',
  GET_STATISTICS_REQUEST = 'GET_STATISTICS_REQUEST',
  GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS',
  GET_STATISTICS_FAILED = 'GET_STATISTICS_FAILED',
}

// TYPES

export type TGetStatisticsRequest = {
  type: EGetStatisticsAction.GET_STATISTICS_REQUEST;
  payload: {
    materials: TGetStatisticsMaterials;
    successCallback?: (response: TGetStatisticsResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetStatisticsSuccess = {
  type: EGetStatisticsAction.GET_STATISTICS_SUCCESS;
  payload: { response: TGetStatisticsResponse };
};

export type TGetStatisticsFailed = { type: EGetStatisticsAction.GET_STATISTICS_FAILED };

// FUNCTION

export const getStatisticsAction = {
  request: createActionCreator(
    EGetStatisticsAction.GET_STATISTICS_REQUEST,
    (resolve) =>
      (
        materials: TGetStatisticsMaterials,
        successCallback?: (response: TGetStatisticsResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetStatisticsRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetStatisticsAction.GET_STATISTICS_SUCCESS,
    (resolve) =>
      (response: TGetStatisticsResponse): TGetStatisticsSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetStatisticsAction.GET_STATISTICS_FAILED,
    (resolve) =>
      (error: unknown): TGetStatisticsFailed =>
        resolve({ error }),
  ),
};
