import { all, takeLatest } from 'redux-saga/effects';

import { deleteInquiryAction, getInquiriesAction, getInquiryAction, updateInquiryAction } from '@/redux/actions';

import { deleteInquirySaga } from './delete-inquiry';
import { getInquiriesSaga } from './get-inquiries';
import { getInquirySaga } from './get-inquiry';
import { updateInquirySaga } from './update-inquiry';

export default function* root(): Generator {
  yield all([
    takeLatest(deleteInquiryAction.request.type, deleteInquirySaga),
    takeLatest(getInquiriesAction.request.type, getInquiriesSaga),
    takeLatest(getInquiryAction.request.type, getInquirySaga),
    takeLatest(updateInquiryAction.request.type, updateInquirySaga),
  ]);
}
