import { all, takeLatest } from 'redux-saga/effects';

import { createTicketAction, deleteTicketAction, getTicketsAction, updateTicketAction } from '@/redux/actions';

import { createTicketSaga } from './create-ticket';
import { deleteTicketSaga } from './delete-ticket';
import { getTicketsSaga } from './get-tickets';
import { updateTicketSaga } from './update-ticket';

export default function* root(): Generator {
  yield all([
    takeLatest(createTicketAction.request.type, createTicketSaga),
    takeLatest(deleteTicketAction.request.type, deleteTicketSaga),
    takeLatest(getTicketsAction.request.type, getTicketsSaga),
    takeLatest(updateTicketAction.request.type, updateTicketSaga),
  ]);
}
