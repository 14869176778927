import { createActionCreator } from 'deox';

import { TGetAdminBranchMaterials, TGetAdminBranchResponse } from '@/services/api/branch/get-admin-branch';

// CONSTANTS

export enum EGetAdminBranchAction {
  GET_ADMIN_BRANCH = 'GET_ADMIN_BRANCH',
  GET_ADMIN_BRANCH_REQUEST = 'GET_ADMIN_BRANCH_REQUEST',
  GET_ADMIN_BRANCH_SUCCESS = 'GET_ADMIN_BRANCH_SUCCESS',
  GET_ADMIN_BRANCH_FAILED = 'GET_ADMIN_BRANCH_FAILED',
}

// TYPES

export type TGetAdminBranchRequest = {
  type: EGetAdminBranchAction.GET_ADMIN_BRANCH_REQUEST;
  payload: {
    materials: TGetAdminBranchMaterials;
    successCallback?: (response: TGetAdminBranchResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetAdminBranchSuccess = {
  type: EGetAdminBranchAction.GET_ADMIN_BRANCH_SUCCESS;
  payload: { response: TGetAdminBranchResponse };
};

export type TGetAdminBranchFailed = { type: EGetAdminBranchAction.GET_ADMIN_BRANCH_FAILED };

// FUNCTION

export const getAdminBranchAction = {
  request: createActionCreator(
    EGetAdminBranchAction.GET_ADMIN_BRANCH_REQUEST,
    (resolve) =>
      (
        materials: TGetAdminBranchMaterials,
        successCallback?: (response: TGetAdminBranchResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetAdminBranchRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetAdminBranchAction.GET_ADMIN_BRANCH_SUCCESS,
    (resolve) =>
      (response: TGetAdminBranchResponse): TGetAdminBranchSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetAdminBranchAction.GET_ADMIN_BRANCH_FAILED,
    (resolve) =>
      (error: unknown): TGetAdminBranchFailed =>
        resolve({ error }),
  ),
};
