import ApiService from '@/services/api';

// TYPES

export type TAddTicketCustomerParams = unknown;
export type TAddTicketCustomerBody = {
  customer: string;
  ticket: string;
};

export type TAddTicketCustomerMaterials = {
  params?: TAddTicketCustomerParams;
  body?: TAddTicketCustomerBody;
};

export type TAddTicketCustomerResponse = unknown;

// FUNCTION

export const addTicketCustomer = async ({
  params,
  body,
}: TAddTicketCustomerMaterials): Promise<TAddTicketCustomerResponse> => {
  const response = await ApiService.post(`/admin/customers/add-ticket`, body, { params });
  return response.data;
};
