import { TTalksNotification } from '@/common/models';
import { TPaginationResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TGetManagerTalksSentsParams = {
  page: number;
  pageSize: number;
  fromDate?: string;
  toDate?: string;
};

export type TGetManagerTalksSentsMaterials = {
  params?: TGetManagerTalksSentsParams;
};

export type TGetManagerTalksSentsResponse = {
  pagination: TPaginationResponse;
  data: TTalksNotification[];
};

// FUNCTION

export const getManagerTalksSents = async ({
  params,
}: TGetManagerTalksSentsMaterials): Promise<TGetManagerTalksSentsResponse> => {
  const response = await ApiService.get(`/admin/talks/schedules/sent`, { params });
  return response.data;
};
