import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getCustomerDetailAction } from '@/redux/actions';
import { getCustomerDetail, TGetCustomerDetailResponse } from '@/services/api';

// FUNCTION

export function* getCustomerDetailSaga(action: ActionType<typeof getCustomerDetailAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getCustomerDetail, materials);
    const getCustomerDetailResponse: TGetCustomerDetailResponse = response as TGetCustomerDetailResponse;
    yield put(getCustomerDetailAction.success(getCustomerDetailResponse));
    successCallback?.(getCustomerDetailResponse);
  } catch (err) {
    yield put(getCustomerDetailAction.failure(err));
    failedCallback?.(err);
  }
}
