import React, { useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { navigate, useLocation } from '@reach/router';
import Icon, { EIconColor, EIconName } from '@/components/Icon';
import { TRootState } from '@/redux/reducers';
import { Paths } from '@/pages/routers';

import { dataNavbarMenu, dataSuperAdminNavbarMenu } from './Navbar.data';
import { TNavbarItem, TNavbarProps } from './Navbar.types.d';
import './Navbar.scss';

const Navbar: React.FC<TNavbarProps> = ({ visible, onToggleVisible }) => {
  const { pathname } = useLocation();
  const id = useLocation().pathname.split('/').pop();

  const [visibleBranchSubMenu, setVisibleBranchSubMenu] = useState<boolean>(false);

  const branchesState = useSelector((state: TRootState) => state.branchReducer.getBranchesResponse);
  const branchesOptions = branchesState?.map((item) => ({
    activePaths: [Paths.Reservation(item.id)],
    link: Paths.Reservation(item.id),
    title: item.branchName,
  }));

  const profileState = useSelector((state: TRootState) => state.authReducer.getProfileResponse);
  const isSuperAdmin = useSelector((state: TRootState) => state.authReducer.isSuperAdmin);
  const dataMenu = isSuperAdmin ? dataSuperAdminNavbarMenu() : dataNavbarMenu(id, branchesOptions);

  const handleClickNavbarItem = (data: TNavbarItem): void => {
    if (!data.disabled && (data?.children?.length || 0) === 0) {
      if (data.link) navigate(data.link);
    } else {
      setVisibleBranchSubMenu(!visibleBranchSubMenu);
    }
  };

  return (
    <div className={classNames('Navbar flex flex-col', { hide: !visible })}>
      <div className="Navbar-header flex items-center">
        <div className="Navbar-header-icon">
          <Icon name={EIconName.Home} color={EIconColor.WHITE} />
        </div>
        <div className="Navbar-header-info">
          <div className="Navbar-header-info-name body-2">에이블메디스킨</div>
          <div className="Navbar-header-info-branch title-3">
            {isSuperAdmin ? '총괄관리자' : profileState?.branch.branchName}
          </div>
        </div>
      </div>

      <div className="Navbar-list">
        {dataMenu.map((item, index) => {
          const isChildren = (item.children?.length || 0) > 0;
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="Navbar-list-item-wrapper"
            >
              <div
                className={classNames('Navbar-list-item flex items-center', {
                  active: (item.activePaths as string[]).includes(pathname),
                  disabled: item.disabled,
                })}
                onClick={(): void => handleClickNavbarItem(item)}
              >
                <div className="Navbar-list-item-icon">
                  {item.icon && <Icon name={item.icon} color={EIconColor.GRAY_03} />}
                </div>

                <div className="Navbar-list-item-title title-3">{item.title}</div>

                {isChildren && (
                  <div className={classNames('Navbar-list-item-arrow', { active: visibleBranchSubMenu })}>
                    <Icon name={EIconName.AngleDown} color={EIconColor.GRAY_03} />
                  </div>
                )}
              </div>
              {isChildren && (
                <div className={classNames('Navbar-list-item-children', { active: visibleBranchSubMenu })}>
                  {item.children?.map((children) => (
                    <div
                      className={classNames('Navbar-list-item child flex items-center', {
                        active: (children.activePaths as string[]).includes(pathname),
                        disabled: children.disabled,
                      })}
                      onClick={(): void => handleClickNavbarItem(children)}
                    >
                      <div className="Navbar-list-item-icon" />

                      <div className="Navbar-list-item-title title-3">{children.title}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className="Navbar-footer flex justify-end">
        <Icon name={EIconName.DoubleAngleLeft} color={EIconColor.GRAY_03} onClick={onToggleVisible} />
      </div>
    </div>
  );
};

export default Navbar;
