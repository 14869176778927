import { all, takeLatest } from 'redux-saga/effects';

import {
  addTicketCustomerAction,
  createCustomerAction,
  deleteCustomerAction,
  getCustomerDetailAction,
  getCustomerUsageAction,
  getCustomerAction,
  getCustomersAction,
  refundTicketCustomerAction,
  updateCustomerAction,
} from '@/redux/actions';

import { addTicketCustomerSaga } from './add-ticket-customer';
import { createCustomerSaga } from './create-customer';
import { deleteCustomerSaga } from './delete-customer';
import { getCustomerDetailSaga } from './get-customer-detail';
import { getCustomerUsageSaga } from './get-customer-usage';
import { getCustomerSaga } from './get-customer';
import { getCustomersSaga } from './get-customers';
import { refundTicketCustomerSaga } from './refund-ticket-customer';
import { updateCustomerSaga } from './update-customer';

export default function* root(): Generator {
  yield all([
    takeLatest(addTicketCustomerAction.request.type, addTicketCustomerSaga),
    takeLatest(createCustomerAction.request.type, createCustomerSaga),
    takeLatest(deleteCustomerAction.request.type, deleteCustomerSaga),
    takeLatest(getCustomerDetailAction.request.type, getCustomerDetailSaga),
    takeLatest(getCustomerUsageAction.request.type, getCustomerUsageSaga),
    takeLatest(getCustomerAction.request.type, getCustomerSaga),
    takeLatest(getCustomersAction.request.type, getCustomersSaga),
    takeLatest(refundTicketCustomerAction.request.type, refundTicketCustomerSaga),
    takeLatest(updateCustomerAction.request.type, updateCustomerSaga),
  ]);
}
