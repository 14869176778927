import ApiService from '@/services/api';

// TYPES

export type TGetManagerTalksSettingPaths = {
  type: string | number;
};
export type TGetManagerTalksSettingParams = unknown;

export type TGetManagerTalksSettingMaterials = {
  paths?: TGetManagerTalksSettingPaths;
  params?: TGetManagerTalksSettingParams;
};

export type TGetManagerTalksSettingResponse = {
  sendNotifications?: boolean;
  procedureMenu?: boolean;
  timeItTakes?: boolean;
  showAddress?: boolean;
  parkingGuidance?: boolean;
  parkingGuidanceDetail?: string;
  scheduleChange?: boolean;
  changeReservationMenu?: boolean;
  shippingDate?: number;
  sendTime?: string;
};

// FUNCTION

export const getManagerTalksSetting = async ({
  paths,
  params,
}: TGetManagerTalksSettingMaterials): Promise<TGetManagerTalksSettingResponse> => {
  const response = await ApiService.get(`/admin/talks/${paths?.type}`, { params });
  return response.data;
};
