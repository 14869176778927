import React from 'react';
import classNames from 'classnames';

import { TIconProps } from './Icon.types';
import { EIconName } from './Icon.enums';
import './Icon.scss';

import Reservation from './Reservation';
import User from './User';
import Blink from './Blink';
import Caculator from './Caculator';
import Chart from './Chart';
import Chat from './Chat';
import Document from './Document';
import Setting from './Setting';
import TicketDollar from './TicketDollar';
import Home from './Home';
import DoubleAngleLeft from './DoubleAngleLeft';
import Filter from './Filter';
import Search from './Search';
import Plus from './Plus';
import X from './X';
import Reload from './Reload';
import AngleDown from './AngleDown';
import AngleLeft from './AngleLeft';
import AngleRight from './AngleRight';
import ThreeDots from './ThreeDots';
import Trash from './Trash';
import Edit from './Edit';
import Phone from './Phone';
import Note from './Note';
import AddCircle from './AddCircle';
import CheckCircle from './CheckCircle';
import CircleX from './CircleX';
import Info from './Info';
import Minus from './Minus';

const Icon: React.FC<TIconProps> = ({ name, className, color, onClick }) => {
  const renderIcon = (): React.ReactElement => {
    switch (name) {
      case EIconName.Reservation:
        return <Reservation color={color} />;
      case EIconName.User:
        return <User color={color} />;
      case EIconName.Blink:
        return <Blink color={color} />;
      case EIconName.Caculator:
        return <Caculator color={color} />;
      case EIconName.Chart:
        return <Chart color={color} />;
      case EIconName.Chat:
        return <Chat color={color} />;
      case EIconName.Document:
        return <Document color={color} />;
      case EIconName.Setting:
        return <Setting color={color} />;
      case EIconName.TicketDollar:
        return <TicketDollar color={color} />;
      case EIconName.Home:
        return <Home color={color} />;
      case EIconName.DoubleAngleLeft:
        return <DoubleAngleLeft color={color} />;
      case EIconName.Filter:
        return <Filter color={color} />;
      case EIconName.Search:
        return <Search color={color} />;
      case EIconName.Plus:
        return <Plus color={color} />;
      case EIconName.X:
        return <X color={color} />;
      case EIconName.Reload:
        return <Reload color={color} />;
      case EIconName.AngleDown:
        return <AngleDown color={color} />;
      case EIconName.AngleLeft:
        return <AngleLeft color={color} />;
      case EIconName.AngleRight:
        return <AngleRight color={color} />;
      case EIconName.ThreeDots:
        return <ThreeDots color={color} />;
      case EIconName.Trash:
        return <Trash color={color} />;
      case EIconName.Edit:
        return <Edit color={color} />;
      case EIconName.Phone:
        return <Phone color={color} />;
      case EIconName.Note:
        return <Note color={color} />;
      case EIconName.AddCircle:
        return <AddCircle color={color} />;
      case EIconName.CheckCircle:
        return <CheckCircle color={color} />;
      case EIconName.CircleX:
        return <CircleX color={color} />;
      case EIconName.Info:
        return <Info color={color} />;
      case EIconName.Minus:
        return <Minus color={color} />;

      default:
        return <></>;
    }
  };

  return (
    <div className={classNames('Icon', 'flex', 'justify-center', 'items-center', className)} onClick={onClick}>
      {renderIcon()}
    </div>
  );
};

export default Icon;
