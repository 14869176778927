import { EScheduleType } from '@/common/enums';
import ApiService from '@/services/api';

// TYPES

export type TCreateSchedulePersonalParams = unknown;
export type TCreateSchedulePersonalBody = {
  branch: string;
  title: string;
  isAllDayOff: boolean;
  type: EScheduleType;
  startAt?: string;
  endAt?: string;
  dateEnd?: string;
};

export type TCreateSchedulePersonalMaterials = {
  params?: TCreateSchedulePersonalParams;
  body?: TCreateSchedulePersonalBody;
};

export type TCreateSchedulePersonalResponse = unknown;

// FUNCTION

export const createSchedulePersonal = async ({
  params,
  body,
}: TCreateSchedulePersonalMaterials): Promise<TCreateSchedulePersonalResponse> => {
  const response = await ApiService.post(`/admin/managements/schedule-personal`, body, { params });
  return response.data;
};
