import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { createTicketAction } from '@/redux/actions';
import { createTicket, TCreateTicketResponse } from '@/services/api';

// FUNCTION

export function* createTicketSaga(action: ActionType<typeof createTicketAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(createTicket, materials);
    const createTicketResponse: TCreateTicketResponse = response as TCreateTicketResponse;
    yield put(createTicketAction.success(createTicketResponse));
    successCallback?.(createTicketResponse);
  } catch (err) {
    yield put(createTicketAction.failure(err));
    failedCallback?.(err);
  }
}
